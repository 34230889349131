import {
  getJSON,
  postJSON,
  putJSON,
  deleteAction,
} from '@/services/httpService';

export const getConfigs = () => getJSON(`/configurations/`);
export const getConfig = (configurationName) =>
  getJSON(`/configurations/${configurationName}`);

export const searchConfigs = (filters) =>
  getJSON(`/configurations/search?${filters.join('&')}`);

export const saveConfig = (config, isNew) =>
  isNew
    ? postJSON(`/configurations`, config)
    : putJSON(`/configurations/${config.name}`, config);

export const deleteConfig = (configuration_name) =>
  deleteAction(`/configurations/${configuration_name}`);
