<template>
  <div
    :class="`fixed top-0 bottom-0
     ${uiStore.tabletOrSmaller ? 'z-[500]' : ''}`"
    @click="() => uiStore.navDrawerOpen && uiStore.setNavDrawerOpen(false)"
    @keyup.esc="uiStore.setNavDrawerOpen(false)"
  >
    <nav
      v-if="navLinks.length > 0"
      :class="`h-full overflow-y-auto flex flex-col ${
        uiStore.navDrawerOpen ? 'translate-x-0' : ' -translate-x-full'
      } fixed lg:relative opacity-100 transition transform lg:translate-x-0 bg-white ${
        uiStore.tabletOrSmaller ? 'w-full' : full ? 'w-[17rem]' : 'w-20'
      }`"
    >
      <div v-if="uiStore.tabletOrSmaller" class="mx-auto p-4">
        <button
          class="absolute right-2 top-2 p-2"
          :aria-label="$t('navDrawer.closeNavigation.message')"
          @click.prevent.stop="uiStore.setNavDrawerOpen(false)"
        >
          <fa-icon icon="fa-regular fa-xmark-large" class="text-vgocean" />
        </button>
      </div>
      <router-link
        v-else
        :to="homeLink"
        :aria-current="$route.path === homeLink"
        class="ml-4 mt-0 py-2"
      >
        <img
          :src="`${
            full
              ? '/img/Virtual-Gurus-Assistants-Color-Logo-p-500.png'
              : '/img/Virtual-Gurus-Assistants-Color-Logo-mobile.png'
          }`"
          class="p-3"
          :alt="$t('talentplace.home.title')"
      /></router-link>
      <div role="list" class="mb-8 flex flex-auto flex-col px-4 pt-8">
        <div
          v-for="(item, key) in navLinks"
          :key="key"
          role="listitem"
          :class="
            item === currentLink || item.route === openSection
              ? 'bg-vgstone-100 rounded-2xl'
              : ''
          "
          class="my-2 py-1"
        >
          <router-link
            v-if="full || !item.subNavLinks"
            v-slot="{ navigate, href }"
            :to="item.route"
            custom
          >
            <a
              :ref="`menu_item_${key}`"
              :href="href"
              :class="`flex ${
                item === currentLink
                  ? 'font-bold text-vgnavy-500'
                  : 'font-normal text-vgstone-800'
              }`"
              :aria-label="item.title"
              @click.stop="handleMainLinkClick($event, item, navigate)"
            >
              <v-tooltip location="right" :disabled="full && !initialLoad">
                <template #activator="{ props }">
                  <fa-icon
                    aria-hidden="true"
                    :class="`mx-3 text-center w-8 text-2xl ${
                      item !== currentLink && !full ? 'hover:opacity-50' : ''
                    } ${
                      item === currentLink ? 'text-vgnavy' : 'text-vgstone-800'
                    }`"
                    :icon="item.icon"
                    v-bind="props"
                  />
                </template>
                <span>{{ item.title }}</span>
              </v-tooltip>
              <template v-if="full">
                <div class="hover:text-vgorange grow self-center">
                  {{ item.title }}
                  <fa-icon
                    v-if="item.highlight"
                    icon="fa-regular fa-sparkles"
                    class="text-vgorange"
                  />
                </div>
                <button
                  :aria-label="
                    $t('navDrawer.menu.expandable.label', { title: item.title })
                  "
                  @click.prevent.stop="handleLinkToggle(item)"
                >
                  <fa-icon
                    v-if="item.subNavLinks"
                    aria-hidden="true"
                    :icon="
                      (item === currentLink && !collapseCurrent) ||
                      item.route === openSection
                        ? 'fa-regular fa-chevron-down'
                        : 'fa-regular fa-chevron-right'
                    "
                    class="text-vgstone px-2"
                  />
                </button>
              </template>
            </a>
          </router-link>

          <v-menu
            v-else
            min-width="180"
            content-class="inline bg-vgstone-50 rounded-lg"
            location="top"
          >
            <template #activator="{ props }">
              <div class="flex justify-self-end">
                <button
                  class="flex text-left"
                  :aria-label="item.title"
                  v-bind="props"
                >
                  <v-tooltip location="right" :disabled="full && !initialLoad">
                    <template #activator="tooltip">
                      <fa-icon
                        aria-hidden="true"
                        class="text-vgstone-800 mx-3 w-8 text-center text-2xl"
                        :icon="item.icon"
                        v-bind="tooltip.props"
                      />
                    </template>
                    <span>{{ item.title }}</span>
                  </v-tooltip>
                </button>
              </div>
            </template>
            <div class="bg-vgstone-100 flex pr-4">
              <fa-icon
                :class="`mx-3 py-2 text-2xl text-center w-8 ${
                  item !== currentLink && !full ? 'hover:opacity-50' : ''
                } ${item === currentLink ? 'text-vgnavy' : 'text-vgstone-800'}`"
                :icon="item.icon"
              />
              <span class="self-center font-bold">{{ item.title }}</span>
            </div>
            <router-link
              v-for="subLink in item.subNavLinks"
              :key="subLink.title"
              :to="subLink.route"
              :class="`flex px-2 py-1.5 pl-12 text-[14px] hover:text-vgorange ${
                subLink === currentSubLink
                  ? 'font-bold text-vgnavy-500'
                  : 'font-normal text-vgstone-800'
              }`"
            >
              <fa-icon
                v-if="subLink === currentSubLink"
                class="text-vgnavy -ml-4 mr-1 mt-1"
                icon="fa-regular fa-arrow-right"
              />
              {{ subLink.title }}
            </router-link>
          </v-menu>
          <template
            v-if="
              full &&
              ((item === currentLink && !collapseCurrent) ||
                item.route === openSection) &&
              item.subNavLinks
            "
          >
            <router-link
              v-for="subLink in item.subNavLinks"
              :key="subLink.title"
              :to="subLink.route"
              :class="`flex py-1.5 pl-16 text-[14px] hover:text-vgorange ${
                subLink === currentSubLink
                  ? 'font-bold text-vgnavy-500'
                  : 'font-normal text-vgstone-800'
              }`"
            >
              <fa-icon
                v-if="subLink === currentSubLink"
                class="text-vgnavy -ml-4 mr-1 mt-1"
                icon="fa-regular fa-arrow-right"
              />
              {{ subLink.title }}
            </router-link>
          </template>
        </div>
      </div>
      <div
        role="navigation"
        :aria-label="$t('navDrawer.profileNavigation.label')"
        :class="`mx-4 my-2 ${
          openSection === 'account' ? 'bg-vgstone-100 rounded-2xl' : ''
        }`"
      >
        <div v-if="full" class="flex grow-0">
          <button
            class="flex h-12 flex-auto py-2 text-left"
            :aria-label="$t('navDrawer.profile.label')"
            @click.prevent.stop="handleAccountToggle"
          >
            <v-avatar v-if="avatarLink" size="32" class="mx-3">
              <img :src="avatarLink" :alt="`${displayName} avatar`" />
            </v-avatar>
            <fa-icon
              v-else
              class="text-vgstone-800 mx-3 text-2xl"
              icon="fa-regular fa-circle-user"
            />
            <div
              class="text-vgstone-800 hover:text-vgorange grow self-center"
              :class="`${openSection === 'account' ? 'font-bold' : ''}`"
            >
              <div class="max-w-[15rem] truncate md:max-w-[10rem]">
                {{ (user && user.known_as) || displayName }}
              </div>
            </div>
            <fa-icon
              class="text-vgstone self-center px-2"
              :icon="
                openSection === 'account'
                  ? 'fa-regular fa-chevron-down'
                  : 'fa-regular fa-chevron-right'
              "
            />
          </button>
        </div>
        <v-menu
          v-else
          min-width="180"
          content-class="inline bg-vgstone-50 rounded-lg shadow-[0_5px_5px_-3px_rgba(0,0,0,.2),_0_8px_10px_1px_rgba(0,0,0,.14),_0_3px_14px_2px_rgba(0,0,0,.12)]"
          location="top"
        >
          <template #activator="{ props }">
            <div class="flex grow-0">
              <button class="flex h-12 flex-auto py-2 text-left" v-bind="props">
                <v-tooltip location="right" :disabled="full && !initialLoad">
                  <template #activator="tooltip">
                    <v-avatar
                      v-if="avatarLink"
                      size="32"
                      class="mx-3"
                      v-bind="tooltip.props"
                    >
                      <img :src="avatarLink" :alt="`${displayName} avatar`" />
                    </v-avatar>
                    <fa-icon
                      v-else
                      v-bind="tooltip.props"
                      :aria-label="$t('navDrawer.profile.label')"
                      class="text-vgstone-800 mx-3 text-2xl"
                      icon="fa-regular fa-circle-user"
                    />
                  </template>
                  <span>{{ displayName }}</span>
                </v-tooltip>
              </button>
            </div>
          </template>
          <div class="bg-vgstone-100 flex rounded-lg pr-4">
            <v-avatar v-if="avatarLink" size="32" class="mx-3">
              <img :src="avatarLink" :alt="`${displayName} avatar`" />
            </v-avatar>
            <fa-icon
              v-else
              class="text-vgstone-800 mx-3 text-2xl"
              icon="fa-regular fa-circle-user"
            />
            <span class="self-center pr-2 font-bold">{{ displayName }}</span>
          </div>

          <router-link
            v-for="link in profileLinks"
            :key="link.title"
            :to="link.route"
            :class="`flex py-1.5 pl-14 text-[14px] hover:text-vgorange ${
              $route.path === '/profile/password'
                ? 'font-bold text-vgnavy-500'
                : 'font-normal text-vgstone-800'
            }`"
          >
            <fa-icon
              v-if="$route.path === link.route"
              class="text-vgnavy -ml-4 mr-1 mt-1"
              icon="fa-regular fa-arrow-right"
            />
            {{ link.title }}
          </router-link>
          <a
            href="#"
            class="hover:text-vgorange text-vgstone-800 flex py-1.5 pl-14 text-[14px] font-normal"
            @click.prevent="logout"
          >
            {{ $t('logout.link') }}
          </a>
        </v-menu>
        <template v-if="full && openSection === 'account'">
          <router-link
            v-for="link in profileLinks"
            :key="link.title"
            :to="link.route"
            :class="`flex py-1.5 pl-16 text-[14px] hover:text-vgorange ${
              $route.path === link.route
                ? 'font-bold text-vgnavy-500'
                : 'font-normal text-vgstone-800'
            }`"
          >
            <fa-icon
              v-if="$route.path === link.route"
              class="text-vgnavy -ml-4 mr-1 mt-1"
              icon="fa-regular fa-arrow-right"
            />
            {{ link.title }}
          </router-link>
          <a
            href="#"
            class="hover:text-vgorange text-vgstone-800 flex py-1.5 pl-16 text-[14px] font-normal"
            @click.prevent="logout"
          >
            {{ $t('logout.link') }}
          </a>
        </template>
      </div>
      <button
        v-show="!uiStore.navDrawerOpen"
        tabindex="-1"
        class="text-vgstone-800 ml-4 flex h-12 py-2 text-left"
        :aria-label="$t('navDrawer.toggleSidebar.label')"
        @click="
          uiStore.setNavDrawerExpanded(!uiStore.navDrawerExpanded);
          openSection = '';
        "
      >
        <v-tooltip location="right" :disabled="full && !initialLoad">
          <template #activator="{ props }">
            <fa-icon
              class="text-vgstone-800 mx-3 -mt-1 text-2xl"
              :icon="
                uiStore.navDrawerExpanded
                  ? 'fa-light fa-toggle-large-on'
                  : 'fa-light fa-toggle-large-off'
              "
              v-bind="props"
            />
          </template>
          <span> {{ $t('navDrawer.toggleSidebar.label') }}</span>
        </v-tooltip>
        <div v-if="uiStore.navDrawerExpanded" class="mx-2">
          {{ $t('navDrawer.toggleSidebar.label') }}
        </div>
      </button>
    </nav>
    <div ref="srAnnouncement" role="alert" class="sr-only" aria-live="polite" />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { sessionUserHasPermission } from '@/services/clientSessionService';
import { API_URL } from '@/config';
import { mapStores, storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';

export default {
  name: 'NavDrawer',
  data() {
    const userStore = useUserStore();
    const { homeLink, virtualassistant, user } = storeToRefs(userStore);
    const { checkFeatureFlag } = storeToRefs(useConfigStore());

    return {
      userStore,
      homeLink,
      virtualassistant,
      user,
      checkFeatureFlag,
      initialLoad: true,
      openSection: '',
      collapseCurrent: false,
    };
  },
  computed: {
    ...mapStores(useUiStore),
    navLinks: function () {
      if (!this.user) {
        return [];
      }
      if (this.user.permissions.includes('admin')) {
        return [
          {
            icon: 'fa-regular fa-house-blank',
            title: this.$t('navDrawer.menu.home.link'),
            route: '/admin/home',
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.myTasks.link'),
                route: `/admin/tasks/todo?user_uuid=${this.user.uuid}`,
                permissionSome: ['admin_tasks.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.myDiscussions.link'),
                route: `/admin/discussions?participant_uuid=${this.user.uuid}`,
              },
            ].filter(
              (link) =>
                !link.permissionSome ||
                link.permissionSome.find((permission) =>
                  sessionUserHasPermission(permission)
                )
            ),
          },
          {
            icon: 'fa-regular fa-folder-user',
            title: this.$t('navDrawer.menu.virtualAssistants.link'),
            route: '/admin/virtualassistants',
            permissionSome: ['admin_virtualassistant.view'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.active.link'),
                route: '/admin/virtualassistants',
              },
              {
                title: this.$t('navDrawer.subMenu.probationary.link'),
                route: '/admin/virtualassistants/probationary',
              },
              {
                title: this.$t('navDrawer.subMenu.ineligible.link'),
                route: '/admin/virtualassistants/ineligible',
              },

              {
                title: this.$t('navDrawer.subMenu.applicants.link'),
                route: '/admin/virtualassistants/applicants',
              },
              {
                title: this.$t('navDrawer.subMenu.paused.link'),
                route: '/admin/virtualassistants/inactive',
              },
              {
                title: this.$t('navDrawer.subMenu.lost.link'),
                route: '/admin/virtualassistants/loss-retention-tracking',
              },
              {
                title: this.$t('navDrawer.subMenu.missingBankingInfo.link'),
                route: '/admin/virtualassistants/missingbankinginfo',
              },
              {
                title: this.$t('navDrawer.subMenu.pendingReview.link'),
                route: '/admin/virtualassistants/pendingchangereview',
              },
              {
                title: this.$t('navDrawer.subMenu.waitlist.link'),
                route: '/admin/virtualassistants/waitlist',
              },
            ],
          },
          {
            icon: 'fa-regular fa-building-user',
            title: this.$t('clients.label'),
            route: '/admin/clients',
            permissionSome: ['admin_client.view'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.active.link'),
                route: '/admin/clients',
              },
              {
                title: this.$t('navDrawer.subMenu.inactive.link'),
                route: '/admin/clients?searchType=Inactive',
              },
              {
                title: this.$t('navDrawer.subMenu.archived.link'),
                route: '/admin/clients?searchType=Archived',
              },
            ],
          },
          {
            icon: 'fa-regular fa-briefcase-blank',
            title: this.$t('navDrawer.menu.projects.link'),
            route: '/admin/projects',
            permissionSome: ['admin_project.view'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.active.link'),
                route: '/admin/projects',
              },
              {
                title: this.$t('navDrawer.subMenu.inactive.link'),
                route: '/admin/projects?searchType=Inactive',
              },
              {
                title: this.$t('navDrawer.subMenu.draft.link'),
                route: '/admin/projects?searchType=Draft',
              },
              {
                title: this.$t('navDrawer.subMenu.matching.link'),
                route: '/admin/projects/matching?searchType=Matching',
              },
              {
                title: this.$t('navDrawer.subMenu.inRetention.link'),
                route: '/admin/projects?searchType=In%20Retention',
              },
              {
                title: this.$t('navDrawer.subMenu.inFirst30.link'),
                route: '/admin/projects?searchType=In%20First%2030',
              },
              {
                title: this.$t('navDrawer.subMenu.requiringReview.link'),
                route: '/admin/projects?searchType=Requiring%20Review',
              },
              {
                title: this.$t('navDrawer.subMenu.ratePlanHealthCheck.link'),
                route: '/admin/projects?searchType=Health%20Check',
              },
            ],
          },
          {
            icon: 'fa-regular fa-clock',
            title: this.$t('navDrawer.menu.time.link'),
            route: '/admin/time',
            permissionSome: ['admin_time.view'],
          },
          {
            icon: 'fa-regular fa-ballot-check',
            title: this.$t('navDrawer.menu.tasks.link'),
            route: '/admin/tasks',
            permissionSome: ['admin_tasks.view', 'admin_tasks.edit'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.assignedToVGAdmin.link'),
                route: '/admin/tasks/todo',
              },
              {
                title: this.$t('navDrawer.subMenu.taskLists.link'),
                route: '/admin/tasks',
              },
            ],
          },
          {
            icon: 'fa-regular fa-comment-question',
            title: this.$t('navDrawer.menu.discussions.link'),
            route: '/admin/discussions',
            permissionSome: ['admin_project.view'],
          },
          {
            icon: 'fa-regular fa-file-chart-pie',
            title: this.$t('navDrawer.menu.reports.link'),
            route: '/admin/eow-reports',
            featureFlag: ['eow-report'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.endOfWeek.link'),
                route: '/admin/eow-reports',
              },
            ],
          },
          {
            icon: 'fa-regular fa-bell-on',
            title: this.$t('navDrawer.menu.notifications.link'),
            route: '/admin/manage_notifications',
            permissionSome: ['admin_notification.view'],
          },
          {
            icon: 'fa-regular fa-triangle-exclamation',
            title: this.$t('navDrawer.menu.alerts.link'),
            route: '/admin/alerts',
            permissionSome: ['admin_alert.view'],
          },
          {
            icon: 'fa-regular fa-magnifying-glass-dollar',
            title: this.$t('navDrawer.menu.finance.link'),
            route: '/admin/invoices',
            permissionSome: ['admin_finance.view'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.invoices.link'),
                route: '/admin/invoices',
              },
              {
                title: this.$t('navDrawer.subMenu.invoiceAdjustments.link'),
                route: '/admin/invoice-adjustments',
              },
              {
                title: this.$t('navDrawer.subMenu.rolloverHoursPolicies.link'),
                route: '/admin/rollover-hours-policies',
              },
              {
                title: this.$t('navDrawer.subMenu.bills.link'),
                route: '/admin/bills',
              },
              {
                title: this.$t('navDrawer.subMenu.billAdjustments.link'),
                route: '/admin/bill-adjustments',
              },
              {
                title: this.$t('navDrawer.subMenu.promotions.link'),
                route: '/admin/promotions',
              },
              {
                title: this.$t('navDrawer.subMenu.referrals.link'),
                route: '/admin/referrals',
                featureFlag: ['referral-links'],
              },
              {
                title: this.$t('navDrawer.subMenu.referralRedemptions.link'),
                route: '/admin/referrals/redemptions',
                featureFlag: ['referral-links'],
              },
              {
                title: this.$t('navDrawer.subMenu.referralTransactions.link'),
                route: '/admin/referrals/transactions',
                featureFlag: ['referral-links'],
              },
              {
                title: this.$t('navDrawer.subMenu.netIncome.link'),
                route: '/admin/net-income',
                permissionSome: ['admin_finance_report.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.invoiceProcessing.link'),
                route: '/admin/invoice-pipeline',
              },
              {
                title: this.$t('navDrawer.subMenu.integrationReadiness.link'),
                route: '/admin/integration-readiness',
              },
            ].filter(
              (link) =>
                (!link.featureFlag ||
                  this.checkFeatureFlag(link.featureFlag)) &&
                (!link.permissionSome ||
                  link.permissionSome.find((permission) =>
                    sessionUserHasPermission(permission)
                  ))
            ),
          },
          {
            icon: 'fa-regular fa-chart-line',
            title: this.$t('navDrawer.menu.analytics.link'),
            route: '/admin/external/all/catalog.html',
            permissionSome: ['admin'],
            subNavLinks: [
              {
                title: this.$t('all.label'),
                route: '/admin/external/all/catalog.html',
              },
              {
                title: this.$t('navDrawer.menu.finance.link'),
                route: '/admin/external/finance/catalog.html',
                permissionSome: ['admin_finance_report.view'],
              },
            ].filter(
              (link) =>
                !link.permissionSome ||
                link.permissionSome.find((permission) =>
                  sessionUserHasPermission(permission)
                )
            ),
          },
          {
            icon: 'fa-regular fa-screwdriver-wrench',
            title: this.$t('navDrawer.menu.admin.link'),
            route: sessionUserHasPermission('admin_matching.view')
              ? '/admin/criteria/roles'
              : '/admin/administration',
            permissionSome: [
              'super_admin',
              'admin_user.view',
              'admin_configuration.view',
            ],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.roles.link'),
                route: '/admin/criteria/roles',
                permissionSome: ['admin_matching.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.skills.link'),
                route: '/admin/criteria/skills',
                permissionSome: ['admin_matching.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.tools.link'),
                route: '/admin/criteria/tools',
                permissionSome: ['admin_matching.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.users.link'),
                route: '/admin/users',
                permissionSome: ['admin_user.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.tags.link'),
                route: '/admin/tags',
                permissionSome: ['super_admin'],
              },
              {
                title: this.$t('navDrawer.subMenu.configurations.link'),
                route: '/admin/configurations',
                permissionSome: ['admin_configuration.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.utmCampaigns.link'),
                route: '/admin/utms',
                permissionSome: ['admin_utm.view'],
              },
              {
                title: this.$t('navDrawer.subMenu.webSockets.link'),
                route: '/admin/sockets',
                permissionSome: ['super_admin'],
              },
              {
                title: this.$t('navDrawer.subMenu.sessions.link'),
                route: '/admin/sessions',
                permissionSome: ['super_admin'],
              },
              {
                title: this.$t('navDrawer.subMenu.jobs.link'),
                route: '/admin/jobs',
                permissionSome: ['super_admin'],
              },
              {
                title: this.$t('navDrawer.subMenu.cronJobs.link'),
                route: '/admin/cronjobs',
                permissionSome: ['super_admin'],
              },
            ].filter(
              (link) =>
                !link.permissionSome ||
                link.permissionSome.find((permission) =>
                  sessionUserHasPermission(permission)
                )
            ),
          },
          {
            icon: 'fa-regular fa-money-bill-trend-up',
            title: this.$t('navDrawer.subMenu.referralCode.link'),
            route: '/admin/referral',
            featureFlag: 'referral-links',
            highlight: true,
          },
        ].filter(
          (link) =>
            (!link.featureFlag || this.checkFeatureFlag(link.featureFlag)) &&
            (!link.permissionSome ||
              link.permissionSome.find((permission) =>
                this.user.permissions.includes(permission)
              ))
        );
      }
      if (this.user.permissions.includes('virtualassistant')) {
        return [
          {
            icon: 'fa-regular fa-house-blank',
            title: this.$t('navDrawer.menu.home.link'),
            route: '/va/home',
          },
          {
            icon: 'fa-regular fa-briefcase-blank',
            title: this.$t('navDrawer.menu.projects.link'),
            route: '/va/projects',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.current.link'),
                route: '/va/projects',
              },
              {
                title: this.$t('navDrawer.subMenu.prospective.link'),
                route: '/va/projects_candidate',
              },
              {
                title: this.$t('navDrawer.subMenu.past.link'),
                route: '/va/projects_past',
              },
            ],
          },
          {
            icon: 'fa-regular fa-clock',
            title: this.$t('navDrawer.menu.time.link'),
            route: '/va/time',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.logged.link'),
                route: '/va/time',
              },
            ],
          },
          {
            icon: 'fa-regular fa-ballot-check',
            title: this.$t('navDrawer.menu.tasks.link'),
            route: '/va/tasks/todo',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.assignedToMe.link'),
                route: '/va/tasks/todo',
              },
              {
                title: this.$t('navDrawer.subMenu.current.link'),
                route: '/va/tasks',
              },
              {
                title: this.$t('navDrawer.subMenu.completed.link'),
                route: '/va/tasks/completed',
              },
            ],
          },
          {
            icon: 'fa-regular fa-file-chart-pie',
            title: this.$t('navDrawer.menu.reports.link'),
            route: '/va/eow-reports',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
            featureFlag: ['eow-report'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.endOfWeek.link'),
                route: '/va/eow-reports',
              },
            ],
          },
          {
            icon: 'fa-regular fa-file-invoice-dollar',
            title: this.$t('navDrawer.menu.billing.link'),
            route: '/va/billing',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
          },
          {
            icon: 'fa-regular fa-building-magnifying-glass',
            title: this.$t('navDrawer.menu.opportunities.link'),
            featureFlag: ['ml-opportunity'],
            route: '/va/opportunities',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.menu.opportunities.active.link'),
                route: '/va/opportunities',
              },
              {
                title: this.$t('navDrawer.menu.opportunities.past.link'),
                route: '/va/opportunities/past',
              },
            ],
          },
          {
            icon: 'fa-regular fa-money-bill-trend-up',
            title: this.$t('navDrawer.subMenu.referralCode.link'),
            route: '/va/referral',
            featureFlag: 'referral-links',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
            highlight: true,
          },
        ].filter(
          (link) =>
            (!link.state ||
              link.state.includes(this.virtualassistant?.state)) &&
            ((!link.featureFlag && !link.featurePermission) ||
              (link.featureFlag && this.checkFeatureFlag(link.featureFlag)) ||
              (link.featurePermission &&
                this.sessionUserHasPermission(link.featurePermission))) &&
            (!link.permission || this.sessionUserHasPermission(link.permission))
        );
      }
      if (this.user.permissions.includes('client')) {
        return [
          {
            icon: 'fa-regular fa-house-blank',
            title: this.$t('navDrawer.menu.home.link'),
            route: '/client/home',
          },
          {
            icon: 'fa-regular fa-briefcase-blank',
            title: this.$t('navDrawer.menu.subscriptions.link'),
            route: '/client/projects',
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.current.link'),
                route: '/client/projects',
              },
              {
                title: this.$t('navDrawer.subMenu.upcoming.link'),
                route: '/client/projects_upcoming',
              },
              {
                title: this.$t('navDrawer.subMenu.past.link'),
                route: '/client/projects_past',
              },
              {
                title: this.$t('navDrawer.subMenu.draft.link'),
                route: '/client/projects_draft',
              },
            ],
          },
          {
            icon: 'fa-regular fa-clock',
            title: this.$t('navDrawer.menu.time.link'),
            route: '/client/time',
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.logged.link'),
                route: '/client/time',
              },
            ],
          },
          {
            icon: 'fa-regular fa-ballot-check',
            title: this.$t('navDrawer.menu.tasks.link'),
            route: '/client/tasks',
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.current.link'),
                route: '/client/tasks',
              },
              {
                title: this.$t('navDrawer.subMenu.completed.link'),
                route: '/client/tasks/completed',
              },
            ],
          },
          {
            icon: 'fa-regular fa-file-chart-pie',
            title: this.$t('navDrawer.menu.reports.link'),
            route: '/client/eow-reports',
            featureFlag: ['eow-report'],
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.endOfWeek.link'),
                route: '/client/eow-reports',
              },
            ],
          },
          {
            icon: 'fa-regular fa-file-invoice-dollar',
            title: this.$t('navDrawer.menu.billing.link'),
            route: '/client/invoices',
            primaryContact: true,
            subNavLinks: [
              {
                title: this.$t('navDrawer.subMenu.invoices.link'),
                route: '/client/invoices',
              },
              {
                title: this.$t('navDrawer.subMenu.paymentMethod.link'),
                route: '/client/billing',
              },
            ].filter(
              (link) =>
                (!link.featureFlag && !link.featurePermission) ||
                (link.featureFlag && this.checkFeatureFlag(link.featureFlag)) ||
                (link.featurePermission &&
                  this.sessionUserHasPermission(link.featurePermission))
            ),
          },
          {
            icon: 'fa-regular fa-money-bill-trend-up',
            title: this.$t('navDrawer.subMenu.referralCode.link'),
            route: '/client/referral',
            featureFlag: 'referral-links',
            highlight: true,
          },
        ].filter(
          (link) =>
            ((!link.featureFlag && !link.featurePermission) ||
              (link.featureFlag && this.checkFeatureFlag(link.featureFlag)) ||
              (link.featurePermission &&
                this.sessionUserHasPermission(link.featurePermission))) &&
            (!link.permission ||
              this.sessionUserHasPermission(link.permission)) &&
            (!link.primaryContact ||
              this.user.is_primary_contact === 'true' ||
              this.user.contact_roles?.includes('billing'))
        );
      }
      return [];
    },
    profileLinks: function () {
      if (!this.user) {
        return [];
      }
      if (this.user.permissions.includes('admin')) {
        return [
          {
            title: this.$t('navDrawer.subMenu.preferences.link'),
            route: '/preferences',
          },
          {
            title: this.$t('navDrawer.subMenu.password.link'),
            route: '/profile/password',
          },
        ];
      }
      if (this.user.permissions.includes('virtualassistant')) {
        return [
          {
            title: this.$t('navDrawer.profile.label'),
            route: '/va/profile',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
          },
          {
            title: this.$t('navDrawer.subMenu.bio.link'),
            route: '/va/bio',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
          },
          {
            title: this.$t('navDrawer.subMenu.availability.link'),
            route: '/va/availability',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
          },
          {
            title: this.$t('navDrawer.subMenu.contactInfo.link'),
            route: '/va/contact_info',
            state: ['active', 'probationary', 'inactive', 'ineligible', 'lost'],
          },
          {
            title: this.$t('navDrawer.subMenu.preferences.link'),
            route: '/preferences',
            state: ['active', 'inactive', 'ineligible', 'lost'],
          },
          {
            title: this.$t('navDrawer.subMenu.password.link'),
            route: '/va/profile/password',
          },
        ].filter(
          (link) =>
            (!link.state ||
              link.state.includes(this.virtualassistant?.state)) &&
            (!link.permission ||
              this.sessionUserHasPermission(link.permission)) &&
            (!link.featureFlag || this.checkFeatureFlag(link.featureFlag))
        );
      }
      if (this.user.permissions.includes('client')) {
        return [
          {
            title: this.$t('navDrawer.subMenu.contactInfo.link'),
            route: '/client/contact_info',
          },
          {
            title: this.$t('navDrawer.subMenu.companyInfo.link'),
            route: '/client/company_info',
          },
          {
            title: this.$t('navDrawer.subMenu.preferences.link'),
            route: '/preferences',
          },
          {
            title: this.$t('navDrawer.subMenu.password.link'),
            route: '/client/password',
          },
        ].filter(
          (link) =>
            (!link.permission ||
              this.sessionUserHasPermission(link.permission)) &&
            (!link.featureFlag || this.checkFeatureFlag(link.featureFlag))
        );
      }
      return [];
    },
    currentLink: function () {
      return this.navLinks.find(
        (link) =>
          link.route === this.$route.path ||
          link.route === this.$route.meta.navRelated ||
          link.subNavLinks?.find(
            (subLink) =>
              subLink.route === this.$route.path ||
              subLink.route === this.$route.fullPath ||
              subLink.route ===
                this.correctSubNavRelated(this.$route.meta.subNavRelated)
          )
      );
    },
    currentSubLink: function () {
      for (let link of this.navLinks) {
        const match = link.subNavLinks?.find(
          (subLink) =>
            subLink.route === this.$route.fullPath ||
            subLink.route ===
              this.correctSubNavRelated(this.$route.meta.subNavRelated)
        );
        if (match) {
          return match;
        }
      }

      if (this.navLinks.find((link) => link.route === this.$route.path)) {
        return null;
      }

      for (let link of this.navLinks) {
        const match = link.subNavLinks?.find(
          (subLink) =>
            subLink.route === this.$route.meta.fromFullPath ||
            subLink.route ===
              this.correctSubNavRelated(this.$route.meta.subNavRelated)
        );
        if (match) {
          return match;
        }
      }

      return null;
    },
    full: function () {
      return this.uiStore.navDrawerExpanded || this.uiStore.navDrawerOpen;
    },
    displayName: function () {
      return this.user?.full_name ?? '';
    },
    avatarLink: function () {
      return this.user?.avatar_id
        ? `${API_URL}/file/avatar/${this.user?.avatar_id}`
        : '';
    },
  },
  watch: {
    $route() {
      if (this.openSection !== 'account') {
        this.openSection = '';
      }
      this.collapseCurrent = false;
    },
    'uiStore.navDrawerOpen': {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          nextTick(() => {
            this.$refs.menu_item_0[0].focus?.();
            this.announceToScreenReader(
              this.$t('navDrawer.navigationOpen.message')
            );
          });
        } else if (newVal === false && oldVal === true) {
          this.announceToScreenReader(
            this.$t('navDrawer.navigationClosed.message')
          );
        }
      },
    },
    'uiStore.navDrawerExpanded': {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          nextTick(() => {
            this.announceToScreenReader(
              this.$t('navDrawer.navigationExpanded.message')
            );
          });
        } else if (newVal === false && oldVal === true) {
          this.announceToScreenReader(
            this.$t('navDrawer.navigationCollapsed.message')
          );
        }
      },
    },
  },
  created() {
    nextTick(() => {
      this.initialLoad = false;
    });
  },
  methods: {
    sessionUserHasPermission,
    correctSubNavRelated(url) {
      if (!url || url.indexOf(':') < 0) {
        return url;
      }

      const matches = url.match(/:[^/]*/g);
      matches.forEach((match) => {
        url = url.replaceAll(match, this.$route.params[match.slice(1)]);
      });
      return url;
    },
    async logout() {
      await this.userStore.endSession();
      location.href = '/';
    },
    handleLinkToggle(item) {
      if (item === this.currentLink) {
        this.collapseCurrent = !this.collapseCurrent;
      } else {
        this.openSection = this.openSection === item.route ? '' : item.route;
      }
    },
    handleAccountToggle() {
      this.openSection = this.openSection === 'account' ? '' : 'account';
    },
    handleMainLinkClick(event, item, navigate) {
      this.openSection = '';

      if (this.uiStore.navDrawerOpen && item.subNavLinks) {
        event.preventDefault();
        return this.handleLinkToggle(item);
      }

      this.uiStore.setNavDrawerOpen(false);
      navigate(event);
    },
    announceToScreenReader(text) {
      nextTick(() => {
        const announcement = this.$refs.srAnnouncement;
        if (announcement) {
          announcement.textContent = text;
        }

        setTimeout(() => {
          announcement.textContent = '';
        }, 1000);
      });
    },
  },
};
</script>
