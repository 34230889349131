<template>
  <div v-if="!loading" class="pb-5">
    <div
      v-for="criteria in ['roles', 'skills', 'tools', 'industries'].filter(
        (criteria) => assistant[criteria] && assistant[criteria].length > 0
      )"
      :key="'wrapper' + criteria"
      class="divide-vgsilver-200 flex flex-col divide-y-2 divide-y-reverse"
    >
      <h4 class="title mt-4 text-xl font-bold">
        {{ criteriaTitle(criteria) }}
      </h4>
      <div class="pb-4">
        <v-chip
          v-for="(value, index) in assistantCriteria(criteria)"
          :key="criteria + index"
          class="m-1 text-ellipsis"
          :class="computedColorPerCriteria(criteria)"
        >
          <span class="truncate">{{ getTranslatedValue(value) }} </span>
          <fa-icon
            v-if="
              criteriaIsInMatchingCriteria(
                mappingData.find((data) => data.value === criteria)?.key,
                value
              )
            "
            icon="fa-regular fa-circle-check"
            class="ml-3 text-xl text-white"
          />
        </v-chip>
        <button
          v-if="assistant[criteria].length > 5"
          class="text-vgmedturq w-full text-right font-semibold"
          @click="toggleShowAll(criteria)"
        >
          <span v-if="showAll[criteria]">{{
            $t('portal:expertise.see.less.button')
          }}</span>
          <span v-else>{{ $t('portal:expertise.see.all.button') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { getProjectRequirements } from '@/services/projectService';
import { useTranslation } from 'i18next-vue';

export default {
  name: 'Expertise',
  props: {
    assistant: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  data() {
    const { i18next } = useTranslation();

    return {
      i18next,
      showAll: {
        tools: false,
        skills: false,
        roles: false,
      },
      opportunities: [],
      mappingData: [
        { key: 'role_map', value: 'roles', query: 'rids' },
        { key: 'skill_map', value: 'skills', query: 'sids' },
        { key: 'tool_map', value: 'tools', query: 'tids' },
      ],
      loading: false,
    };
  },
  async created() {
    this.loading = true;

    if (this.$route.query.opportunities) {
      this.opportunities = await getProjectRequirements(
        this.$route.query.project_uuid
      ).then((result) => result.opportunities);

      if (this.opportunities) {
        this.opportunities = this.opportunities.filter((opportunity) =>
          this.$route.query.opportunities.includes(opportunity.uuid)
        );
      }
    }

    this.loading = false;
  },
  methods: {
    getTranslatedValue(value) {
      return this.i18next.language === 'fr' && value.value_fr
        ? value.value_fr
        : value.value || value;
    },

    computedColorPerCriteria(criteria) {
      switch (criteria) {
        case 'roles':
          return 'bg-vgsoftblue';
        case 'skills':
          return 'bg-vgsoftpurple';
        case 'tools':
          return 'bg-vgsoftcyan';
        default:
          return 'bg-vgmedturq text-white';
      }
    },
    criteriaTitle(criteria) {
      switch (criteria) {
        case 'industries':
          return this.$t('portal:expertise.top.industries.title');
        case 'roles':
          return this.$t('portal:role.label', { count: 2 });
        case 'skills':
          return this.$t('portal:skill.label', { count: 2 });
        case 'tools':
          return this.$t('portal:tool.label', { count: 2 });
        default:
          return '';
      }
    },
    assistantCriteria(criteria) {
      const key =
        this.mappingData.find((data) => data.value === criteria)?.key ||
        criteria;

      let itemsToShow = this.assistant[key]
        ? [
            ...this.assistant[key].filter((item) =>
              this.criteriaIsInMatchingCriteria(key, item)
            ),
            ...this.assistant[key].filter(
              (item) => !this.criteriaIsInMatchingCriteria(key, item)
            ),
          ]
        : [];

      switch (criteria) {
        case 'roles':
        case 'skills':
        case 'tools':
          return this.showAll[criteria] ? itemsToShow : itemsToShow.slice(0, 5);
        default:
          return this.assistant[key];
      }
    },

    criteriaIsInMatchingCriteria(criteria, item) {
      const key = this.mappingData.find((data) => data.key === criteria)?.query;

      if (!key) return false;

      return this.opportunities.some((opportunity) => {
        return opportunity[key].includes(item.id);
      });
    },
    toggleShowAll(criteria) {
      this.showAll[criteria] = !this.showAll[criteria];
    },
  },
};
</script>
<style scoped>
.title:first-letter {
  text-transform: uppercase;
}
</style>
