<template>
  <div v-if="testimonials.length" class="flex grow-0 flex-col">
    <h1 class="text-2xl font-semibold">
      {{ $t('portal:va.feedback.heading') }}
    </h1>
    <div class="flex flex-col px-2">
      <div
        v-for="(testimonial, index) in testimonialsForPagination"
        :key="index"
      >
        <div class="flex">
          <div class="self-center">
            <fa-icon
              icon="fa-regular fa-message-quote"
              class="bg-vgsilver-50 text-vgmedturq rounded-2xl p-2"
            />
          </div>
          <div class="ml-7">
            <div v-if="testimonial.rating" class="flex items-end">
              <v-rating
                v-model="testimonial.rating"
                density="compact"
                readonly
                size="small"
                :color="vgNavy"
                :active-color="vgOrange"
              ></v-rating>
              <p class="text-vgorange mb-0 ml-3 text-xs font-bold">
                {{ testimonial.rating }}
              </p>
            </div>

            <i v-if="testimonial.message" class="text-sm font-normal">{{
              testimonial.message
            }}</i>

            <p class="text-sm font-normal">
              {{
                testimonial.display_name ? `- ${testimonial.display_name}` : ''
              }}
            </p>
          </div>
        </div>
        <hr class="my-3" />
      </div>
    </div>
    <div v-if="testimonialsPageLength > 1" class="self-end">
      <div class="flex self-end">
        <p class="pt-2 text-sm" :color="vgOcean">Testimonials</p>
        <v-pagination
          v-model="testimonialsPage"
          :length="testimonialsPageLength"
          :active-color="vgOcean"
          :total-visible="5"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import tailwind from 'tailwind.config';

export default {
  name: 'Testimonials',
  props: {
    testimonials: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      require: true,
      default: () => {
        return 3;
      },
    },
  },
  data() {
    return {
      testimonialsPage: 1,
      vgOrange: tailwind.theme.extend.colors.vgorange[500],
      vgOcean: tailwind.theme.extend.colors.vgocean[500],
      vgNavy: tailwind.theme.extend.colors.vgnavy[500],
    };
  },
  computed: {
    testimonialsPageLength() {
      return Math.ceil(this.testimonials.length / this.perPage);
    },
    testimonialsForPagination: function () {
      return this.testimonials.slice(
        (this.testimonialsPage - 1) * this.perPage,
        this.testimonialsPage * this.perPage
      );
    },
  },
};
</script>
