import {
  hasSession,
  getUser,
  saveSession,
  loadSession,
  endSession,
} from '@/services/clientSessionService';
import {
  getVASelfById,
  getVAConfig,
} from '@/services/virtualAssistantsService';
import { getClientSelfById, getClientConfig } from '@/services/clientService';
import {
  getUserConfig,
  getPreferences,
  setPreferences,
} from '@/services/userService';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', {
  state: () => ({
    login: {
      stage: 'email',
      email: '',
    },
    virtualassistant: undefined,
    client: undefined,
    userConfig: undefined,
    user: undefined,
    preferences: undefined,
    clientConfig: undefined,
    vaConfig: undefined,
  }),
  getters: {
    homeLink() {
      if (!this.user) {
        return '/';
      }
      if (this.user.permissions.includes('admin')) {
        return '/admin/home';
      } else if (this.user.permissions.includes('virtualassistant')) {
        return '/va/home';
      } else if (this.user.permissions.includes('client')) {
        return '/client/home';
      }
      return '/';
    },
    homeLinkLabel() {
      if (!this.user) {
        return this.$t('talentplace.home.title');
      }
      if (this.user.permissions.includes('admin')) {
        return this.$t('portal:homeLink.admin.title');
      } else if (this.user.permissions.includes('virtualassistant')) {
        return this.$t('portal:homeLink.va.title');
      } else if (this.user.permissions.includes('client')) {
        return this.$t('portal:homeLink.client.title');
      }
      return this.$t('talentplace.home.title');
    },
  },
  actions: {
    async initUser(payload) {
      if (hasSession()) {
        const sessionUser = getUser();

        if (
          (!this.virtualassistant || payload?.forceReload) &&
          sessionUser.permissions.includes('virtualassistant')
        ) {
          this.virtualassistant = await getVASelfById().catch(() => {});
        }
        if (
          (!this.client || payload?.forceReload) &&
          sessionUser.permissions.includes('client')
        ) {
          this.client = await getClientSelfById().catch(() => {});
        }
        if (!this.userConfig || payload?.forceReload) {
          this.userConfig = await getUserConfig().catch(() => {});
        }
        if (!this.preferences || payload?.forceReload) {
          this.preferences = await getPreferences().catch(() => {});
        }
      }
      this.clientConfig = await getClientConfig().catch(() => ({}));
      this.vaConfig = await getVAConfig().catch(() => ({}));
    },
    async setPreferences(payload) {
      this.preferences = await setPreferences(payload);
    },
    async loadSession() {
      const session = await loadSession();
      this.user = session.user;
    },

    async saveSession(payload) {
      const session = await saveSession(payload.token, payload.user);
      this.user = session.user;
    },
    async endSession() {
      await endSession();
      this.user = undefined;
    },
    setLoginStage(payload) {
      this.login.stage = payload;
    },
    setLoginEmail(payload) {
      this.login.email = payload;
    },
  },
});
