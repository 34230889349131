import {
  getJSON,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';
import { flattenObj } from '@/utils/flatten';

export const getFullUserById = (id) => getJSON(`/user/${id}?full=true`);

export const getUserByEmail = (email) => getJSON(`/user/email/${email}`);

export const getUserConfig = () => getJSON(`/user/config`);

export const getPreferences = () => getJSON(`/user/preferences`);
export const setPreferences = (body) => putJSON(`/user/preferences`, body);

export const searchUsers = (filters) =>
  getJSON(`/user/search?${filters.join('&')}`);

export const saveUser = (user) =>
  user.uuid
    ? putJSON(`/user/${user.uuid}`, {
        ...user,
        auditLogEntries: undefined,
      })
    : postJSON(`/user`, user);

export const giveEarlyAccessPermission = () => putJSON(`/user/earlyAccess/`);

export const deleteUser = (uuid) => deleteAction(`/user/${uuid}`);

export const createUserWithClient = (user, client) =>
  postJSON(`/user?client_uuid=${client.uuid}`, user);

export const updateUserRolesForClient = (userUuid, clientUuid, roles) =>
  putJSON(
    `/user/contact/roles?client_uuid=${clientUuid}&user_uuid=${userUuid}`,
    roles
  );

export const getTimezone = (latitude, longitude) =>
  postJSON(`/user/timezone`, { latitude, longitude });

export const getClientRelationshipsFromUser = (uuid) =>
  getJSON(`/user/${uuid}/relationships/client/`);

export const getProjectRelationshipsFromUser = (uuid) =>
  getJSON(`/user/${uuid}/relationships/project/`);

export const createUserInHubSpot = (user) => {
  return postJSON(`/user/hubspot/`, user);
};

export const getAuditLogs = (user_uuid, pagination) => {
  const query = new URLSearchParams(flattenObj({ pagination })).toString();

  return getJSON(`/user/${user_uuid}/audit-logs?${query}`);
};
