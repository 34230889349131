import { createRouter, createWebHistory } from 'vue-router';
import Assistant from '@/views/Assistant.vue';
import ChangePassword from '@/views/ChangePassword.vue';
import ClientBilling from '@/views/ClientBilling.vue';
const HomePage = () => import('@/views/HomePage.vue');
const ClientDashboard = () => import('@/views/ClientDashboard.vue');
const ClientProjects = () => import('@/views/ClientProjects.vue');
const ClientProjectWrapper = () => import('@/views/ClientProjectWrapper.vue');
const ClientPassword = () => import('@/views/ClientPassword.vue');
const ClientProfile = () => import('@/views/ClientProfile.vue');
import Experiment from '@/views/Experiment.vue';
const ExpressRegister = () => import('@/views/ExpressRegister.vue');
const ClientDashboardInvoices = () =>
  import('@/views/ClientDashboardInvoices.vue');
const ClientDashboardInvoice = () =>
  import('@/views/ClientDashboardInvoice.vue');
const ClientDashboardTime = () => import('@/views/ClientDashboardTime.vue');
const ClientDashboardTaskLists = () =>
  import('@/views/ClientDashboardTaskLists.vue');
const ClientDashboardCompletedTasks = () =>
  import('@/views/ClientDashboardCompletedTasks.vue');
const ClientDashboardEOWReports = () =>
  import('@/views/ClientDashboardEOWReports.vue');
const ClientDashboardEOWReportDetail = () =>
  import('@/views/ClientDashboardEOWReportDetail.vue');
const ProjectRequirementsClient = () =>
  import('@/views/ProjectRequirementsClient.vue');
const ProjectClientPresentation = () =>
  import('@/views/ProjectClientPresentation.vue');
const CheckoutConfirmation = () => import('@/views/CheckoutConfirmation.vue');
const CheckoutNew = () => import('@/views/CheckoutNew.vue');
const AdminDashboard = () => import('@/views/AdminDashboard.vue');
const Promotions = () => import('@/views/Promotions.vue');
const Promotion = () => import('@/views/Promotion.vue');
const ReferralCodes = () => import('@/views/ReferralCodes.vue');
const ReferralCode = () => import('@/views/ReferralCode.vue');
const ReferralRedemptions = () => import('@/views/ReferralRedemptions.vue');
const ReferralRedemption = () => import('@/views/ReferralRedemption.vue');
const ReferralTransactions = () => import('@/views/ReferralTransactions.vue');
const ReferralTransaction = () => import('@/views/ReferralTransaction.vue');
const AdminTime = () => import('@/views/AdminTime.vue');
const AdminTimeEdit = () => import('@/views/AdminTimeEdit.vue');
const AdminTasksToDo = () => import('@/views/AdminTasksToDo.vue');
const AdminCurrentTasks = () => import('@/views/AdminCurrentTasks.vue');
const AdminEOWReports = () => import('@/views/AdminEOWReports.vue');
const AdminEOWReportDetail = () => import('@/views/AdminEOWReportDetail.vue');
const Invoices = () => import('@/views/Invoices.vue');
const Invoice = () => import('@/views/Invoice.vue');
const InvoiceDetail = () => import('@/views/InvoiceDetail.vue');
const InvoiceNew = () => import('@/views/InvoiceNew.vue');
const VABills = () => import('@/views/VABills.vue');
const VABill = () => import('@/views/VABill.vue');
const VABillNew = () => import('@/views/VABillNew.vue');
const VABillDetail = () => import('@/views/VABillDetail.vue');
const InvoiceAdjustments = () => import('@/views/InvoiceAdjustments.vue');
const InvoiceAdjustment = () => import('@/views/InvoiceAdjustment.vue');
const VABillAdjustments = () => import('@/views/VABillAdjustments.vue');
const VABillAdjustment = () => import('@/views/VABillAdjustment.vue');
const RolloverHoursPolicies = () => import('@/views/RolloverHoursPolicies.vue');
const RolloverHoursPolicy = () => import('@/views/RolloverHoursPolicy.vue');
const FinanceNetIncome = () => import('@/views/FinanceNetIncome.vue');
const AdminIntegrationReadiness = () =>
  import('@/views/AdminIntegrationReadiness.vue');
const AdminClients = () => import('@/views/AdminClients.vue');
const AdminClient = () => import('@/views/AdminClient.vue');
const AdminProject = () => import('@/views/AdminProject.vue');
const AdminProjectCheckout = () => import('@/views/AdminProjectCheckout.vue');
const AdminProjectMatching = () => import('@/views/AdminProjectMatching.vue');
const ProjectRequirementsWrapper = () =>
  import('@/views/ProjectRequirementsWrapper.vue');
const AdminOpportunityWrapper = () =>
  import('@/views/AdminOpportunityWrapper.vue');
const ProjectOpportunitiesVA = () =>
  import('@/views/ProjectOpportunitiesVA.vue');
const ProjectOpportunitiesPastVA = () =>
  import('@/views/ProjectOpportunitiesPastVA.vue');
const ProjectOpportunityVA = () => import('@/views/ProjectOpportunityVA.vue');

const AdminTags = () => import('@/views/AdminTags.vue');
const AdminTag = () => import('@/views/AdminTag.vue');
const AdminUsers = () => import('@/views/AdminUsers.vue');
const AdminUser = () => import('@/views/AdminUser.vue');
const AdminVirtualAssistants = () =>
  import('@/views/AdminVirtualAssistants.vue');
const AdminVirtualAssistantsProbationary = () =>
  import('@/views/AdminVirtualAssistantsProbationary.vue');
const AdminVirtualAssistantsNoBankInfo = () =>
  import('@/views/AdminVaNoBankInfo.vue');
const AdminVirtualAssistantApplicants = () =>
  import('@/views/AdminVirtualAssistantApplicants.vue');
const AdminVirtualAssistantsInactive = () =>
  import('@/views/AdminVirtualAssistantsInactive.vue');
const AdminVirtualAssistantsIneligible = () =>
  import('@/views/AdminVirtualAssistantsIneligible.vue');
const AdminVirtualAssistant = () => import('@/views/AdminVirtualAssistant.vue');
const AdminVaProjectRatePlans = () =>
  import('@/views/AdminVaProjectRatePlans.vue');
const AdminVALossRetentionTracking = () =>
  import('@/views/AdminVALossRetentionTracking.vue');
const AdminProjects = () => import('@/views/AdminProjects.vue');
const AdminAlerts = () => import('@/views/AdminAlerts.vue');
const AdminAlert = () => import('@/views/AdminAlert.vue');
const AdminConfigurations = () => import('@/views/AdminConfigurations.vue');
const AdminConfiguration = () => import('@/views/AdminConfiguration.vue');
const AdminDiscussions = () => import('@/views/AdminDiscussions.vue');
const AdminNotifications = () => import('@/views/AdminNotifications.vue');
const AdminNotification = () => import('@/views/AdminNotification.vue');
const AdminKeywordCriteria = () => import('./views/AdminKeywordCriteria.vue');
const AdminKeywordCriterion = () => import('./views/AdminKeywordCriterion.vue');
const AdminUtms = () => import('@/views/AdminUtms.vue');
const AdminUtm = () => import('@/views/AdminUtm.vue');
const AdminSockets = () => import('@/views/AdminSockets.vue');
const AdminSessions = () => import('@/views/AdminSessions.vue');
const AdminJobs = () => import('@/views/AdminJobs.vue');
const AdminJob = () => import('@/views/AdminJob.vue');
const AdminCronJobs = () => import('@/views/AdminCronJobs.vue');
const AdminCronJob = () => import('@/views/AdminCronJob.vue');
const AdminExternal = () => import('@/views/AdminExternal.vue');
const AdminPendingVAChangeReview = () =>
  import('@/views/AdminPendingVAChangeReview.vue');
const AdminVirtualAssistantWaitlist = () =>
  import('@/views/AdminVirtualAssistantWaitlist.vue');
const Notification = () => import('@/views/Notification.vue');
const Notifications = () => import('@/views/Notifications.vue');
const VaApply = () => import('@/views/VaApply.vue');
const VaApplySkills = () => import('@/views/VaApplySkills.vue');
const VaApplySkillTest = () => import('@/views/VaApplySkillTest.vue');
const VaApplyScheduleInterview = () =>
  import('@/views/VaApplyScheduleInterview.vue');
const VaApplyProfile = () => import('@/views/VaApplyProfile.vue');
const VaBookOnboardingMeeting = () =>
  import('@/views/VaBookOnboardingMeeting.vue');
const VaApplyContract = () => import('@/views/VaApplyContract.vue');
const VaApplyCourses = () => import('@/views/VaApplyCourses.vue');
const VaBio = () => import('@/views/VaBio.vue');
const VaDashboard = () => import('@/views/VaDashboard.vue');
const VaProjects = () => import('@/views/VaProjects.vue');
const VaProject = () => import('@/views/VaProject.vue');
const VaProfile = () => import('@/views/VaProfile.vue');
const PortalReferralCode = () => import('@/views/PortalReferralCode.vue');
const VaDashboardTime = () => import('@/views/VaDashboardTime.vue');
const VaDashboardTaskToDo = () => import('@/views/VaDashboardTaskToDo.vue');
const VaDashboardTaskLists = () => import('@/views/VaDashboardTaskLists.vue');
const VaDashboardCompletedTasks = () =>
  import('@/views/VaDashboardCompletedTasks.vue');
const VaDashboardEOWReports = () => import('@/views/VaDashboardEOWReports.vue');
const VaDashboardEOWReportDetail = () =>
  import('@/views/VaDashboardEOWReportDetail.vue');
const VaDashboardBills = () => import('@/views/VaDashboardBills.vue');
const VaDashboardBill = () => import('@/views/VaDashboardBill.vue');
const VaAvailability = () => import('@/views/VaAvailability.vue');
const Discussion = () => import('@/views/Discussion.vue');
const ClientCheckoutThanks = () => import('@/views/ClientCheckoutThanks.vue');
const ClientBookMatchingMeeting = () =>
  import('@/views/ClientBookMatchingMeeting.vue');
const BookConsultMeeting = () => import('@/views/BookConsultMeeting.vue');
const Preferences = () => import('@/views/Preferences.vue');
const NotFound = () => import('@/views/NotFound.vue');
const HealthCheck = () => import('@/views/HealthCheck.vue');
const FinanceInvoicePipeline = () =>
  import('@/views/FinanceInvoicePipeline.vue');
import { init as initApm } from '@elastic/apm-rum';
import {
  hasSession,
  sessionUserHasPermission,
  getSocket,
} from '@/services/clientSessionService';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { useConfigStore } from '@/stores/config';
import { checkBuildVersion } from '@/services/buildService';
import i18next from '@/plugins/i18next';

const router = createRouter({
  history: createWebHistory(),
  base: import.meta.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.name === 'search') {
      return savedPosition; // preserve scroll
    }

    if (to.hash) {
      return { el: to.hash };
    }

    return { left: 0, top: 0 };
  },
  routes: [
    {
      path: '/:language(en|fr)?',
      name: 'home',
      component: HomePage,
      meta: {
        hideHeaderFooter: true,
        hideSidebar: true,
        title: 'documentTitle.homePage.title',
      },
    },
    {
      path: '/assistants/:id',
      name: 'assistant',
      component: Assistant,
      meta: {
        hideSidebar: true,
        title: 'documentTitle.assistant.title',
      },
    },

    {
      path: '/experiment',
      name: 'experiment',
      component: Experiment,
      meta: {
        hideHeaderFooter: true,
        hideSidebar: true,
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/client/consult',
      redirect: () => {
        window.location = 'https://thevirtualgurus.com/book-a-consult';
      },
    },
    {
      path: '/client/plan/:hours?',
      component: ExpressRegister,
      meta: {
        hideSidebar: true,
        showDenseHeader: true,
        title: 'documentTitle.expressCheckout.title',
        service: 'va.hourly',
      },
    },
    {
      path: '/client/connect/:plan?',
      component: ExpressRegister,
      meta: {
        hideSidebar: true,
        showDenseHeader: true,
        title: 'documentTitle.expressCheckout.title',
        service: 'product.ai.connect',
      },
    },
    {
      path: '/client/start',
      redirect: () => {
        window.location = 'https://thevirtualgurus.com/book-a-consult';
      },
    },
    {
      path: '/client/contact',
      redirect: () => {
        window.location = 'https://thevirtualgurus.com/book-a-consult';
      },
    },
    {
      path: '/client/checkout_confirmation/:uuid?',
      name: 'checkout_confirmation',
      component: CheckoutConfirmation,
      meta: {
        hideSidebar: true,
        showDenseHeader: true,
      },
    },
    {
      path: '/client/checkout/:uuid',
      name: 'checkout',
      component: CheckoutNew,
      meta: {
        hideSidebar: true,
      },
    },
    {
      path: '/profile/password',
      component: ChangePassword,
    },
    {
      path: '/preferences',
      component: Preferences,
    },
    {
      path: '/client/billing',
      component: ClientBilling,
    },
    {
      path: '/client/time',
      component: ClientDashboardTime,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/tasks',
      component: ClientDashboardTaskLists,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/tasks/completed',
      component: ClientDashboardCompletedTasks,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/invoices',
      component: ClientDashboardInvoices,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/invoices/:uuid',
      component: ClientDashboardInvoice,
      meta: {
        permissionsRequired: ['client'],
        subNavRelated: '/client/invoices',
      },
    },
    {
      path: '/client/new/contact',
      redirect: () => {
        window.location = 'https://thevirtualgurus.com/book-a-consult';
      },
    },
    {
      path: '/client/home',
      component: ClientDashboard,
      meta: {
        permissionsRequired: ['client'],
        headerTitle: 'dashboard.title',
      },
    },
    {
      path: '/client/dashboard',
      redirect: '/client/home',
    },
    {
      path: '/client/projects',
      component: ClientProjects,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/projects_upcoming',
      component: ClientProjects,
      meta: {
        permissionsRequired: ['client'],
        navRelated: '/client/projects',
      },
    },
    {
      path: '/client/projects_draft',
      component: ClientProjects,
      meta: {
        permissionsRequired: ['client'],
        navRelated: '/client/projects',
      },
    },
    {
      path: '/client/projects_past',
      component: ClientProjects,
      meta: {
        permissionsRequired: ['client'],
        navRelated: '/client/projects',
      },
    },
    {
      path: '/client/projects/:uuid/:rate_plan_uuid?',
      component: ClientProjectWrapper,
      meta: {
        permissionsRequired: ['client'],
        navRelated: '/client/projects',
      },
    },
    {
      path: '/client/contact_info',
      component: ClientProfile,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/company_info',
      component: ClientProfile,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/referral',
      component: PortalReferralCode,
      meta: {
        permissionsRequired: ['client'],
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/client/password',
      component: ClientPassword,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/requirements/:opportunity_uuid',
      name: 'client_requirements',
      component: ProjectRequirementsClient,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/presentation/:opportunity_uuid',
      name: 'client_presentation',
      component: ProjectClientPresentation,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/notifications',
      component: Notifications,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/notifications/:uuid',
      component: Notification,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/discussions/:uuid',
      component: Discussion,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/matching_meeting/:project_uuid?',
      component: ClientBookMatchingMeeting,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/checkout/thank_you',
      component: ClientCheckoutThanks,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/meeting/:meeting_type',
      component: BookConsultMeeting,
      meta: {
        showDenseHeader: true,
      },
    },
    {
      path: '/client/matching_meeting',
      redirect: '/client/meeting/matching',
    },
    {
      path: '/admin/administration',
      component: AdminIntegrationReadiness,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/client/eow-reports',
      component: ClientDashboardEOWReports,
      meta: {
        permissionsRequired: ['client'],
      },
    },
    {
      path: '/client/eow-reports/:uuid',
      component: ClientDashboardEOWReportDetail,
      meta: {
        permissionsRequired: ['client'],
        navRelated: '/client/eow-reports',
      },
    },
    {
      path: '/admin/home',
      component: AdminDashboard,
      meta: {
        permissionsRequired: ['admin'],
        headerTitle: 'dashboard.title',
      },
    },
    {
      path: '/admin/dashboard',
      redirect: '/admin/home',
    },
    {
      path: '/admin/finance',
      component: Invoices,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/promotions',
      component: Promotions,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/promotions/:uuid',
      component: Promotion,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/promotions',
      },
    },
    {
      path: '/admin/referral',
      component: PortalReferralCode,
      meta: {
        permissionsRequired: ['admin'],
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/referrals',
      component: ReferralCodes,
      meta: {
        permissionsRequired: ['admin'],
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/referrals/redemptions',
      component: ReferralRedemptions,
      meta: {
        permissionsRequired: ['admin'],
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/referrals/redemptions/:uuid',
      component: ReferralRedemption,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/referrals/redemptions',
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/referrals/transactions',
      component: ReferralTransactions,
      meta: {
        permissionsRequired: ['admin'],
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/referrals/transactions/:uuid',
      component: ReferralTransaction,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/referrals/transactions',
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/referrals/:uuid',
      component: ReferralCode,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/referrals',
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/admin/time',
      component: AdminTime,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/time/:uuid',
      component: AdminTimeEdit,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/time',
      },
    },
    {
      path: '/admin/tasks/todo',
      component: AdminTasksToDo,
      meta: {
        permissionsRequired: ['admin', 'admin_tasks.view', 'admin_tasks.edit'],
      },
    },
    {
      path: '/admin/tasks',
      component: AdminCurrentTasks,
      meta: {
        permissionsRequired: ['admin', 'admin_tasks.view', 'admin_tasks.edit'],
      },
    },
    {
      path: '/admin/invoices',
      component: Invoices,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/invoices/edit/:uuid',
      component: InvoiceNew,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/invoices',
      },
    },
    {
      path: '/admin/invoices/:uuid',
      component: Invoice,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/invoices',
      },
    },
    {
      path: '/admin/invoices/:uuid/detail',
      component: InvoiceDetail,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/invoices',
      },
    },
    {
      path: '/admin/invoice-adjustments',
      component: InvoiceAdjustments,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/invoice-adjustments/:uuid',
      component: InvoiceAdjustment,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/invoice-adjustments',
      },
    },
    {
      path: '/admin/rollover-hours-policies',
      component: RolloverHoursPolicies,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/rollover-hours-policies/:uuid',
      component: RolloverHoursPolicy,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/rollover-hours-policies',
      },
    },
    {
      path: '/admin/bill-adjustments',
      component: VABillAdjustments,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/bill-adjustments/:uuid',
      component: VABillAdjustment,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/bill-adjustments',
      },
    },
    {
      path: '/admin/bills',
      component: VABills,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/bills/:uuid',
      component: VABill,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/bills',
      },
    },
    {
      path: '/admin/bills/edit/:uuid',
      component: VABillNew,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/bills',
      },
    },
    {
      path: '/admin/bills/:uuid/detail',
      component: VABillDetail,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/bills',
      },
    },
    {
      path: '/admin/net-income',
      component: FinanceNetIncome,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/integration-readiness',
      component: AdminIntegrationReadiness,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/invoice-pipeline',
      component: FinanceInvoicePipeline,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/criteria/:type',
      component: AdminKeywordCriteria,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/criteria/:type/:value',
      component: AdminKeywordCriterion,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/criteria/:type',
      },
    },
    {
      path: '/admin/clients',
      component: AdminClients,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/clients/:client_uuid',
      component: AdminClient,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/clients',
      },
    },
    {
      path: '/admin/projects',
      component: AdminProjects,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/projects/matching',
      component: AdminProjects,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/projects/matching/:project_uuid',
      component: AdminProjectMatching,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/projects/matching?searchType=Matching',
      },
    },
    {
      path: '/admin/projects/requirements/:project_uuid',
      component: ProjectRequirementsWrapper,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/projects',
      },
    },
    {
      path: '/admin/opportunities/:opportunity_uuid/:type',
      component: AdminOpportunityWrapper,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/projects',
      },
    },
    {
      path: '/admin/projects/:project_uuid',
      component: AdminProject,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/projects',
      },
    },
    {
      path: '/admin/checkout/:project_uuid',
      component: AdminProjectCheckout,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/alerts',
      component: AdminAlerts,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/alerts/:alert_uuid',
      component: AdminAlert,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/alerts',
      },
    },
    {
      path: '/admin/utms',
      component: AdminUtms,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/utms/:utm_uuid',
      component: AdminUtm,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/utms',
      },
    },
    {
      path: '/admin/sockets',
      component: AdminSockets,
      meta: {
        permissionsRequired: ['super_admin'],
      },
    },
    {
      path: '/admin/sessions',
      component: AdminSessions,
      meta: {
        permissionsRequired: ['super_admin'],
      },
    },
    {
      path: '/admin/jobs',
      component: AdminJobs,
      meta: {
        permissionsRequired: ['super_admin'],
      },
    },
    {
      path: '/admin/jobs/:job_uuid',
      component: AdminJob,
      meta: {
        permissionsRequired: ['super_admin'],
        navRelated: '/admin/jobs',
      },
    },
    {
      path: '/admin/cronjobs',
      component: AdminCronJobs,
      meta: {
        permissionsRequired: ['super_admin'],
      },
    },
    {
      path: '/admin/cronjobs/:cronjob_uuid',
      component: AdminCronJob,
      meta: {
        permissionsRequired: ['super_admin'],
        navRelated: '/admin/cronjobs',
      },
    },
    {
      path: '/admin/configurations',
      component: AdminConfigurations,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/configurations/:configuration_name',
      component: AdminConfiguration,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/configurations',
      },
    },
    {
      path: '/admin/discussions',
      component: AdminDiscussions,
      meta: {
        permissionsRequired: ['admin_project.view'],
      },
    },
    {
      path: '/admin/eow-reports',
      component: AdminEOWReports,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/eow-reports/:uuid',
      component: AdminEOWReportDetail,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/eow-reports',
      },
    },
    {
      path: '/admin/manage_notifications',
      component: AdminNotifications,
      meta: {
        permissionsRequired: ['admin_notification.view'],
      },
    },
    {
      path: '/admin/manage_notifications/:uuid',
      component: AdminNotification,
      meta: {
        permissionsRequired: ['admin_notification.view'],
        subNavRelated: '/admin/notifications',
      },
    },
    {
      path: '/admin/users',
      component: AdminUsers,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/users/:user_uuid',
      component: AdminUser,
      meta: {
        permissionsRequired: ['admin'],
        subNavRelated: '/admin/users',
      },
    },
    {
      path: '/admin/tags',
      component: AdminTags,
      meta: {
        permissionsRequired: ['super_admin'],
        title: 'documentTitle.tags.title',
      },
    },
    {
      path: '/admin/tags/:uuid',
      component: AdminTag,
      meta: {
        permissionsRequired: ['super_admin'],
        subNavRelated: '/admin/tags',
      },
    },
    {
      path: '/admin/virtualassistants',
      component: AdminVirtualAssistants,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/probationary',
      component: AdminVirtualAssistantsProbationary,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/applicants',
      component: AdminVirtualAssistantApplicants,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/inactive',
      component: AdminVirtualAssistantsInactive,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/ineligible',
      component: AdminVirtualAssistantsIneligible,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/loss-retention-tracking',
      component: AdminVALossRetentionTracking,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/pendingchangereview',
      component: AdminPendingVAChangeReview,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/waitlist',
      component: AdminVirtualAssistantWaitlist,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/missingbankinginfo',
      component: AdminVirtualAssistantsNoBankInfo,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/virtualassistants/:user_uuid',
      component: AdminVirtualAssistant,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/virtualassistants',
      },
    },
    {
      path:
        '/admin/projects/:project_uuid/virtualassistants/:virtualassistant_uuid',
      component: AdminVaProjectRatePlans,
      meta: {
        permissionsRequired: ['admin'],
        navRelated: '/admin/projects',
      },
    },
    {
      path: '/admin/notifications',
      component: Notifications,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/notifications/:uuid',
      component: Notification,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/discussions/:uuid',
      component: Discussion,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/admin/external/:path+',
      component: AdminExternal,
      meta: {
        permissionsRequired: ['admin'],
      },
    },
    {
      path: '/va/apply',
      component: VaApply,
      meta: {
        showDenseHeader: true,
        hideSidebar: true,
        redirectOnLogin: true,
        title: 'documentTitle.vaApply.title',
      },
    },
    {
      path: '/va/apply/skills',
      component: VaApplySkills,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/apply/skilltest',
      component: VaApplySkillTest,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/apply/scheduleinterview',
      component: VaApplyScheduleInterview,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/apply/profile',
      component: VaApplyProfile,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/apply/scheduleonboarding',
      component: VaBookOnboardingMeeting,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/apply/contract',
      component: VaApplyContract,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/apply/courses',
      component: VaApplyCourses,
      meta: {
        permissionsRequired: ['virtualassistant'],
        hideSidebar: true,
      },
    },
    {
      path: '/va/home',
      component: VaDashboard,
      meta: {
        permissionsRequired: ['virtualassistant'],
        headerTitle: 'dashboard.title',
      },
    },
    {
      path: '/va/dashboard',
      redirect: '/va/home',
    },
    {
      path: '/va/projects',
      component: VaProjects,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/projects_candidate',
      component: VaProjects,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/projects_past',
      component: VaProjects,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/projects/:uuid/:rate_plan_uuid?',
      component: VaProject,
      meta: {
        permissionsRequired: ['virtualassistant'],
        navRelated: '/va/projects',
      },
    },
    {
      path: '/va/profile',
      component: VaProfile,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/bio',
      component: VaBio,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/availability',
      component: VaAvailability,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/contact_info',
      component: VaProfile,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/referral',
      component: PortalReferralCode,
      meta: {
        permissionsRequired: ['virtualassistant'],
        featureFlag: 'referral-links',
      },
    },
    {
      path: '/va/profile/password',
      component: ChangePassword,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/billing',
      component: VaDashboardBills,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/billing/:uuid',
      component: VaDashboardBill,
      meta: {
        permissionsRequired: ['virtualassistant'],
        navRelated: '/va/billing',
      },
    },
    {
      path: '/va/time',
      component: VaDashboardTime,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/tasks',
      component: VaDashboardTaskLists,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/tasks/todo',
      component: VaDashboardTaskToDo,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/tasks/completed',
      component: VaDashboardCompletedTasks,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/eow-reports',
      component: VaDashboardEOWReports,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/eow-reports/:uuid',
      component: VaDashboardEOWReportDetail,
      meta: {
        permissionsRequired: ['virtualassistant'],
        navRelated: '/va/eow-reports',
      },
    },
    {
      path: '/va/notifications',
      component: Notifications,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/notifications/:uuid',
      component: Notification,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/discussions/:uuid',
      component: Discussion,
      meta: {
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/opportunities',
      component: ProjectOpportunitiesVA,
      meta: {
        permissionsRequired: ['virtualassistant'],
        featureFlag: 'ml-opportunity',
      },
    },
    {
      path: '/va/opportunities/past',
      component: ProjectOpportunitiesPastVA,
      meta: {
        featureFlag: 'ml-opportunity',
        permissionsRequired: ['virtualassistant'],
      },
    },
    {
      path: '/va/opportunity/:opportunity_uuid',
      name: 'va_opportunity',
      component: ProjectOpportunityVA,
      meta: {
        permissionsRequired: ['virtualassistant'],
        navRelated: '/va/opportunities',
      },
    },
    {
      path: '/healthcheck',
      component: HealthCheck,
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound,
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const configStore = useConfigStore();
  if (!configStore.configsLoaded) {
    await configStore
      .loadConfig()
      .then(() => {
        const userStore = useUserStore();
        return userStore.loadSession().then(() => userStore.initUser());
      })
      .then(() => {
        if (configStore.apmServerUrl) {
          window.apm = initApm({
            serviceName: 'Talentplace',
            serverUrl: configStore.apmServerUrl,
            serviceVersion: import.meta.env.APP_VERSION,
            active: import.meta.env.VITE_NODE_ENV !== 'development',
            environment: import.meta.env.VITE_NODE_ENV,
          });
        }
      });
  }
  to.meta.hasSession = hasSession();
  to.meta.fromFullPath = from.fullPath;

  if (from.meta.postLoginURL) {
    to.meta.postLoginURL = from.meta.postLoginURL;
  }
  if (
    (to.meta.featureFlag || to.meta.featurePermission) &&
    (!to.meta.featureFlag ||
      !useConfigStore().checkFeatureFlag(to.meta.featureFlag)) &&
    (!to.meta.featurePermission ||
      !sessionUserHasPermission(to.meta.featurePermission))
  ) {
    return next('/');
  }

  if (to.meta.permissionsSome) {
    if (!hasSession()) {
      from.meta.postLoginURL = to.fullPath;
      return next('/');
    }
    if (
      !to.meta.permissionsSome.some((permission) =>
        sessionUserHasPermission(permission)
      )
    ) {
      if (sessionUserHasPermission('admin')) {
        return next('/admin/home');
      } else if (sessionUserHasPermission('virtualassistant')) {
        return next('/va/home');
      } else if (sessionUserHasPermission('client')) {
        return next('/client/home');
      }
      return next('/');
    }
  }
  if (to.meta.permissionsRequired) {
    if (!hasSession()) {
      from.meta.postLoginURL = to.fullPath;
      return next('/');
    }
    if (
      to.meta.permissionsRequired.some(
        (permission) => !sessionUserHasPermission(permission)
      )
    ) {
      if (
        to.fullPath.startsWith('/admin') ||
        to.fullPath.startsWith('/client') ||
        to.fullPath.startsWith('/va')
      ) {
        const homePath = sessionUserHasPermission('client')
          ? '/client'
          : sessionUserHasPermission('virtualassistant')
          ? '/va'
          : '/admin';

        const newTo = homePath + to.fullPath.slice(to.fullPath.indexOf('/', 1));

        if (
          router.resolve(newTo)?.matched[0]?.path !== '/:pathMatch(.*)*' &&
          newTo !== to.fullPath
        ) {
          return next(newTo);
        }
      }
      if (sessionUserHasPermission('admin')) {
        return next(`/admin/home`);
      } else if (sessionUserHasPermission('virtualassistant')) {
        return next(`/va/home`);
      } else if (sessionUserHasPermission('client')) {
        return next(`/client/home`);
      }
      return next('/');
    }
  }
  if (hasSession()) {
    if (to.fullPath === '/' && sessionUserHasPermission('virtualassistant')) {
      return next('/va/home');
    }
  }
  getSocket().emit('current_page', to.fullPath);
  next();
});

router.afterEach((to) => {
  document.title = i18next.t(
    to.meta.title || 'documentTitle.defaultTitle.title'
  );
  useUiStore().setReady();
  checkBuildVersion(true);
});

export default router;
