import { defineStore } from 'pinia';
import { getSocket } from '@/services/clientSessionService';

let socket;

const updateNotifications = () => {
  socket.emit('talentplace.notifications_fetch');
};

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: [],
    notificationsSearch: {
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [{ key: 'created_date', order: 'desc' }],
        groupBy: [],
      },
      tableRows: [],
      rowCount: 0,
      show: 'all',
    },
  }),
  getters: {
    firstThreeNotifications() {
      return this.notifications.slice(0, 3);
    },
    notificationCount() {
      return this.notifications.length;
    },
  },
  actions: {
    setupSocket() {
      socket = getSocket();
      socket
        .on('authed', updateNotifications)
        .on('talentplace.notifications_updated', updateNotifications)
        .on('talentplace.notifications_update', (payload) => {
          this.notifications = payload;
        });
    },
    updateNotificationsSearch(payload) {
      this.notificationsSearch = Object.assign(
        this.notificationsSearch,
        payload
      );
    },
  },
});
