export default {
  content: ['./public/**/*.html', './src/**/*.vue'],
  theme: {
    extend: {
      screens: {
        print: { raw: 'print' },
      },
      height: {
        128: '32rem',
      },
      maxHeight: {
        128: '32rem',
      },
      colors: {
        vgorange: {
          DEFAULT: '#CF3E00',
          50: '#FCF8F0',
          100: '#FAF0E1',
          200: '#F2D8B6',
          300: '#EBBD8D',
          350: '#DE8143', // do not use this shade
          400: '#DE8143',
          480: '#CF3E00', // do not use this shade
          500: '#CF3E00',
          600: '#BA3500',
          700: '#9C2900',
          800: '#7D1D00',
          900: '#5C1400',
          950: '#3B0C00',
        },
        vgteal: {
          // Default shade not to be used for text or icons on white background
          // May be used as background or UI Elements such as borders, dividers, shadows
          // May be used as text on a dark background
          DEFAULT: '#19D3C5',
          50: '#f4fdfc',
          100: '#e8fbf9',
          200: '#c6f4f1',
          300: '#a3ede8',
          350: '#76e2db', // do not use this shade
          400: '#5ee0d6',
          480: '#43DED2', // do not use this shade
          500: '#19D3C5',
          600: '#17beb1',
          700: '#139e94',
          800: '#0f7f76',
          900: '#0c6761',
        },
        vgmedturq: {
          DEFAULT: '#158479',
          50: '#F0FAF9',
          100: '#DFF2F1',
          200: '#B1E0DD',
          300: '#88CFCA',
          400: '#45A8A0',
          500: '#158479',
          600: '#117868',
          700: '#0C6350',
          800: '#084F3B',
          900: '#043B28',
          950: '#022617',
        },
        vgocean: {
          DEFAULT: '#035C67',
          50: '#f2f7f7',
          100: '#e6eff0',
          200: '#c0d6d9',
          300: '#9abec2',
          350: '#65969a', // do not use this shade
          400: '#4f8d95',
          480: '#186670', // do not use this shade
          500: '#035C67',
          600: '#03535d',
          700: '#02454d',
          800: '#02373e',
          900: '#012d32',
        },
        vgnavy: {
          DEFAULT: '#00263E',
          50: '#f2f4f5',
          100: '#e6e9ec',
          200: '#bfc9cf',
          300: '#99a8b2',
          350: '#6f8590', // do not use this shade
          400: '#4d6778',
          480: '#0D3147', // do not use this shade
          500: '#00263E',
          600: '#002238',
          700: '#001d2f',
          800: '#001725',
          900: '#00131e',
        },
        vgsilver: {
          // Default shade not to be used for text or icons on white background
          // May be used as background or UI Elements such as borders, dividers, shadows
          // May be used as text on a dark background
          DEFAULT: '#BBBBBB',
          50: '#fcfcfc',
          100: '#f8f8f8',
          200: '#eeeeee',
          300: '#e4e4e4',
          400: '#cfcfcf',
          480: '#C4C4C4', // do not use this shade
          500: '#BBBBBB',
          600: '#a8a8a8',
          700: '#8c8c8c',
          800: '#707070',
          900: '#5c5c5c',
        },
        vgstone: {
          // Use this default shade for light text
          DEFAULT: '#5D7699',
          50: '#F5F8FA',
          100: '#EBF2F5',
          200: '#CFDDE6',
          300: '#B4C8D6',
          350: '#B4C8D6', // do not use this shade
          400: '#869FB8',
          480: '#869FB8', // do not use this shade
          500: '#5D7699',
          600: '#4C658A',
          700: '#354C73',
          800: '#22355C',
          900: '#132245',
          950: '#08112B',
        },
        vgpurple: {
          DEFAULT: '#A9A8D4',
          50: '#fbfbfd',
          100: '#f6f6fb',
          200: '#eae9f4',
          300: '#dddcee',
          400: '#c3c2e1',
          500: '#A9A8D4',
          600: '#9897bf',
          700: '#7f7e9f',
          800: '#65657f',
          900: '#535268',
        },
        vgtertiarydarkblue: {
          DEFAULT: '#1B95D2',
          500: '#1B95D2',
        },
        vgsoftblue: {
          DEFAULT: '#7EBDDB',
        },
        vgsoftcyan: {
          DEFAULT: '#7EDBC2',
        },
        vgsoftpurple: {
          DEFAULT: '#A9A8D4',
        },
        vgaltlightblue: {
          DEFAULT: '#86C6E5',
        },
      },
      borderRadius: {
        sm: '5px',
        DEFAULT: '5px',
        md: '10px',
        '4xl': '24px',
      },
      fontFamily: {
        sans: ['Proxima Nova'],
      },
      backgroundImage: {
        'application-gear': "url('/img/settings_u657.png')",
        'login-sidebar-bg': "url('/img/login-sidebar/sidebar-bg.png')",
        'vg-blotch':
          'radial-gradient(at 5% 95%, rgba(236, 248, 248, 1) 0, transparent 35%)',
      },
      animation: {
        'spin-slow': 'spin 3s linear infinite',
        'deep-pulse': 'deep 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
      },
      keyframes: {
        deep: {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0.1 },
        },
      },
    },
  },
  plugins: [],
};
