<template>
  <div class="mx-auto h-full p-0">
    <div v-if="!assistant" class="mx-auto max-w-[1000px] p-4">
      <content-placeholders :rounded="true">
        <content-placeholders-heading :img="true" />
        <content-placeholders-text :lines="3" />
      </content-placeholders>
      <div class="mt-4 px-4">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="10" />
        </content-placeholders>
      </div>
    </div>
    <template v-if="assistant">
      <div v-if="uiStore.mobile" class="relative m-4">
        <button class="text-vgocean absolute right-2 top-2" @click="close">
          <fa-icon icon="fa-regular fa-xmark-large" />
        </button>
        <div class="flex flex-col">
          <CurrentAvailability
            :assistant="assistant"
            :mobile="uiStore.mobile"
            class="bg-white"
          />
          <Bio :assistant="assistant" class="bg-white" />

          <ExperienceWrapper :mobile="uiStore.mobile" :assistant="assistant" />
          <Testimonials
            :testimonials="testimonials"
            :per-page="3"
            class="px-5"
          />
        </div>
      </div>
      <div v-else-if="uiStore.tablet" class="relative">
        <button class="text-vgocean absolute right-2 top-2" @click="close">
          <fa-icon icon="fa-regular fa-xmark-large" />
        </button>
        <div class="grid justify-items-center sm:grid-cols-2 md:grid-cols-3">
          <div class="bg-vgsilver-100 flex flex-col">
            <CurrentAvailability :assistant="assistant" />
            <WeeklySchedule :data="assistant" class="px-3" />
            <ExperienceWrapper :assistant="assistant" />
          </div>
          <div class="col-span-2 flex w-full flex-col">
            <Bio :assistant="assistant" />
            <Testimonials
              :testimonials="testimonials"
              :per-page="6"
              class="px-5"
            />
          </div>
        </div>
      </div>
      <v-container
        v-else
        fluid
        class="relative mx-auto my-0 h-full px-0 py-5"
        @click="close"
      >
        <div
          class="relative mx-auto my-0 grid h-full max-w-7xl grid-flow-row divide-x p-0 lg:grid-flow-col lg:grid-cols-4"
          @click.stop
        >
          <button class="text-vgocean absolute right-2 top-2" @click="close">
            <fa-icon icon="fa-regular fa-xmark-large" />
          </button>
          <div
            class="bg-vgsilver-100 justify-items-start divide-y rounded-l-xl p-5"
          >
            <CurrentAvailability :assistant="assistant" />
            <WeeklySchedule :data="assistant" class="px-3" />
          </div>
          <div class="flex flex-col bg-white lg:col-span-2">
            <Bio :assistant="assistant" class="border-b-8 border-gray-200" />
            <Testimonials
              :testimonials="testimonials"
              :per-page="3"
              class="px-5"
            />
          </div>
          <ExperienceWrapper :assistant="assistant" class="bg-white" />
        </div>
      </v-container>
    </template>
  </div>
</template>

<script>
import { inject } from 'vue';
import { getById } from '@/services/virtualAssistantsService';
import { hasSession } from '@/services/clientSessionService';
import CurrentAvailability from '@/components/vaPublicProfile/CurrentAvailability.vue';
import WeeklySchedule from '@/components/vaPublicProfile/WeeklySchedule.vue';
import ExperienceWrapper from '@/components/vaPublicProfile/ExperienceWrapper.vue';
import Bio from '@/components/vaPublicProfile/Bio.vue';
import Testimonials from '@/components/vaPublicProfile/Testimonials.vue';
import { getFeedback } from '@/services/feedbackService';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { canGoBack } from '@/services/httpService';

export default {
  name: 'Assistant',
  components: {
    CurrentAvailability,
    WeeklySchedule,
    ExperienceWrapper,
    Bio,
    Testimonials,
  },
  setup() {
    const loginDialog = inject('loginDialog');
    return {
      loginDialog,
    };
  },
  data() {
    return {
      loading: false,
      assistant: null,
      skills: [],
      testimonials: [],
    };
  },
  computed: {
    ...mapStores(useUiStore, useUserStore),
  },
  async created() {
    if (!hasSession()) {
      return this.$router.push('/');
    }

    await this.init();

    document.title = `${this.assistant.display_name} - ${this.$t(
      'portal:assistant.document.title'
    )}`;
  },

  methods: {
    async init() {
      try {
        const result = await getById(this.$route.params.id);

        this.assistant = result.assistant;

        const testimonialResult = await getFeedback([
          `subject_uuid=${this.assistant.uuid}`,
        ]).catch(() => {
          return { testimonials: [] };
        });

        this.testimonials = testimonialResult.testimonials;
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ?? this.$t('generic.fetching.error'),
          { persist: true }
        );
      }
    },
    close() {
      if (canGoBack()) {
        this.$router.go(-1);
      } else {
        this.$router.push(this.userStore.homeLink);
      }
    },
  },
};
</script>
