<!--
The timer has 4 states:

- INACTIVE: just created, never been started
- STARTED: start and running, time cannot be logged until paused
- PAUSED: temporarily stopped, time may be logged
- HALTED: is in a state where it cannot be started or paused, only time logging is allowed (eg: if left running past midnight)

Timer updates are sent to and received from the server via a websocket connection established from the VA dashboard module.

Changes to most fields will not trigger a server-side update, only user interactions via the descxeription field and the
start/pause/delete buttons are triggers.Updates to most fields - are debounced by delaying the update for a short time before sending
the socket message to the server. Any change of state, including the deletion of a timer, is sent immediately.

Gotchas:

- Changes are made immediately to the local Vuex state variable `state.va_timers`, and then sent to the server. The server echoes back
  those changes a short time later. This is because most updates sent to the server will be delayed by a few seconds to debounce them
  and prevent the server being hit with a series of rapid updates for relatively insignificant changes (eg: a change in the description).
  But, those changes need to be shown immediately on-screen for the user, so they need to be made locally first, then sent to the server.

- The order in which changes are made is important. Triggering a delayed update right after an immediate update MAY result in a race
  condition where the immediate state change has not echoed back yet if the network is slow. Always best to make delayed changes FIRST
  and then following them with a state change. Doing so will cancel any outstanding delayed changes and send all changes at once & immediately.

-->
<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    :max-width="options.width"
    @keydown.esc="handleClose"
    @click:outside="handleClose"
  >
    <v-card
      role="dialog"
      :aria-label="$t('portal:timer.label')"
      aria-live="polite"
    >
      <v-card-actions class="-mb-12 flex flex-row justify-end">
        <v-btn
          icon
          :aria-label="$t('portal:timer.dialog.close.label')"
          @click="handleClose"
          ><fa-icon icon="fa-regular fa-xmark-large" /> </v-btn
      ></v-card-actions>
      <v-card-title
        ><h2 class="break-normal text-2xl">
          {{
            state === 'INACTIVE'
              ? $t('portal:timer.new.heading', {
                  context: project?.company ? 'client' : '',
                  client: project ? project.company : '',
                })
              : state === 'STARTED'
              ? $t('portal:timer.running.heading', {
                  context: project?.company ? 'client' : '',
                  client: project ? project.company : '',
                })
              : $t('portal:timer.paused.heading', {
                  context: project?.company ? 'client' : '',
                  client: project ? project.company : '',
                })
          }}
        </h2>
      </v-card-title>
      <div>
        <h3 class="mx-5 mb-0 text-xl">
          <fa-icon icon="fa-regular fa-briefcase-blank" class="mr-2" />{{
            project ? project.project_name : ''
          }}
        </h3>
        <h3 v-if="task" class="mx-5 my-2 text-lg">
          <fa-icon icon="fa-regular fa-ballot-check" class="mr-2" />{{
            task.name
          }}
        </h3>
      </div>
      <v-form ref="form" v-model="valid" class="mx-5 grid gap-2">
        <label class="col-span-full mt-6 text-sm">
          {{ $t('portal:date.label') }}
          <div
            class="bg-vgsilver-200 text-vgnavy-800 border-vgsilver-700 mb-8 flex min-h-[56px] w-full rounded-sm border text-base"
          >
            <div class="self-center p-2">
              {{ task_date }}
            </div>
          </div>
        </label>
        <div class="text-vgocean mb-4 w-full text-center text-2xl font-bold">
          {{ `${running_hms.h}h ${running_hms.m}m ${running_hms.s}s` }}
        </div>
        <label class="col-span-full text-sm">
          {{ $t('portal:description.label') }}
          <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
          <v-textarea
            v-model="description"
            data-cy="va.timer.btn.description"
            variant="outlined"
            :color="vgTeal"
            maxlength="1024"
            counter="1024"
            rows="5"
            :rules="[requiredRule]"
            required
            autofocus
            :hint="task ? $t('portal:timer.description.warning') : ''"
            persistent-hint
          ></v-textarea>
        </label>
        <div v-if="checkFeatureFlag('allow-non-billable-time')">
          <v-checkbox
            id="billable_checkbox"
            v-model="billable"
            class="mx-4"
            :false-value="0"
            :true-value="1"
            :color="vgTeal"
            hide-details
          >
            <template #label>
              <span class="font-semibold">
                {{ $t('portal:timeEntry.client.billable.label') }}
              </span>
            </template>
          </v-checkbox>
        </div>
      </v-form>
      <v-card-text v-if="alert" class="pa-4">
        <vg-alert :model-value="alert" @onClose="alert = null"></vg-alert>
      </v-card-text>
      <v-card-text v-if="state === 'HALTED'" class="pa-4">
        <vg-alert
          :model-value="{
            message: $t('portal:timer.old.warning'),
          }"
        ></vg-alert>
      </v-card-text>
      <v-card-text v-if="timers.length > WARN_ON_TIMER_EVENTS" class="pa-4">
        <vg-alert
          :model-value="{
            type: 'info',
            message: $t('portal:timer.multiple.warning', {
              count: WARN_ON_TIMER_EVENTS,
            }),
          }"
        ></vg-alert>
      </v-card-text>
      <div
        class="mx-5 mb-4 flex flex-col-reverse justify-between gap-4 md:flex-row"
      >
        <v-btn
          v-if="
            description ||
            (timers && timers.length) ||
            ['INACTIVE', 'HALTED'].includes(state)
          "
          :disabled="saving"
          variant="tonal"
          size="x-large"
          :aria-label="$t('portal:timer.delete.label')"
          @click="handleDelete"
        >
          <fa-icon
            icon="fa-regular fa-trash-can-clock"
            class="text-vgstone-900 text-lg"
          />
        </v-btn>
        <div class="flex flex-row justify-between md:justify-end">
          <vg-btn
            v-if="['INACTIVE', 'PAUSED'].includes(state)"
            data-cy="va.timer.btn.start-timer"
            :disabled="saving"
            @click="handleStart"
            >{{ $t('portal:timer.start.label') }}</vg-btn
          >
          <vg-btn
            v-if="state === 'STARTED'"
            data-cy="va.timer.btn.pause"
            class="animate-deep-pulse"
            @click="handlePause"
            >{{ $t('portal:timer.pause.button') }}</vg-btn
          >
          <vg-btn
            :key="`btn_log_${state}`"
            data-cy="va.timer.btn.log-time"
            class="ml-2"
            :loading="saving"
            :disabled="
              state === 'STARTED' ||
              !timers ||
              !timers.length ||
              !valid ||
              saving
            "
            @click="handleSave"
          >
            {{ $t('portal:timer.log.button') }}
          </vg-btn>
        </div>
      </div>
      <ProjectTaskTimeLogged v-if="task" class="mx-5" :task="task" />
      <ProjectTaskCompletion
        v-if="task && timers.length > 0"
        class="mx-5"
        :task="task"
        :allow-immediate-complete="true"
        @change="updateTask"
      />
      <div class="mx-5 mt-4">
        <v-data-table
          class="mb-4 w-full"
          :no-data-text="$t('portal:timer.noData.message')"
          :headers="headers"
          :items="timers"
          :items-per-page="WARN_ON_TIMER_EVENTS"
          item-key="uuid"
          multi-sort
          density="compact"
        >
          <template #item.duration="{ item }">
            <span v-if="item.stop" :key="item.start.timestamp">{{
              formatTimerEventsToHMS(item)
            }}</span>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useTimersStore } from '@/stores/timers';
import { createEntry } from '@/services/timeService';
import {
  formatTimerEventsToHMS,
  formatDate,
  formatShortDate,
} from '@/services/formattingService';
import {
  getProjectTask,
  updateProjectTask,
  cloneNextProjectTask,
} from '@/services/projectService';
import { getEarliestTaskDate } from '@/services/billingService';
import moment from 'moment';
import tailwind from 'tailwind.config';
import VgBtn from '@/components/VgBtn.vue';
import {
  calculateTimerDuration,
  calculateTotalDuration,
  calculateDurationHMS,
} from '../utils/timer';
import ProjectTaskTimeLogged from '@/components/ProjectTaskTimeLogged.vue';
import ProjectTaskCompletion from '@/components/ProjectTaskCompletion.vue';

const DEFAULT_OPTIONS = {
  color: 'red',
  width: 580,
  zIndex: 200,
};

const WARN_ON_TIMER_EVENTS = 5;
const TIMER_TASK_DATE_NEXT_DAY_GRACE_PERIOD_HOURS = 6;

export default {
  name: 'TimerDialog',
  components: { VgBtn, ProjectTaskTimeLogged, ProjectTaskCompletion },
  data() {
    const timersStore = useTimersStore();
    const { checkFeatureFlag } = storeToRefs(useConfigStore());
    const { allTimers } = storeToRefs(timersStore);
    const { updateTimers } = timersStore;

    return {
      allTimers,
      updateTimers,
      checkFeatureFlag,
      dialog: false,
      saving: false,
      valid: true,
      alert: null,
      timer_id: null,
      initialProject: undefined,
      initialTask: null,
      initialAssistant: undefined,
      initialTaskDate: undefined,
      initialDescription: '',
      initialTimers: [],
      resolve: null,
      reject: null,
      options: DEFAULT_OPTIONS,
      WARN_ON_TIMER_EVENTS,
      secondsClock: null,
      running_hms: {},
      totalDuration: 0,
      headers: [
        {
          title: this.$t('portal:timer.status.started.heading'),
          value: 'start.time',
          sortable: false,
        },
        {
          title: this.$t('portal:timer.status.stopped.heading'),
          value: 'stop.time',
          sortable: false,
        },
        {
          title: this.$t('portal:timer.status.duration.heading'),
          value: 'duration',
          sortable: false,
        },
      ],
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
      requiredRule: (v) => !!v || this.$t('required.error'),
    };
  },
  computed: {
    project: {
      get() {
        return this.allTimers[this.timer_id]?.project || this.initialProject;
      },
    },
    task: {
      get() {
        return this.allTimers[this.timer_id]?.task || this.initialTask;
      },
    },
    assistant: {
      get() {
        return (
          this.allTimers[this.timer_id]?.assistant || this.initialAssistant
        );
      },
    },
    task_date: {
      get() {
        return this.allTimers[this.timer_id]?.task_date || this.initialTaskDate;
      },
      set(value) {
        this.updateTimers({
          timer_id: this.timer_id,
          values: {
            task_date: value,
          },
        });
      },
    },
    description: {
      get() {
        return (
          this.allTimers[this.timer_id]?.description || this.initialDescription
        );
      },
      set(value) {
        this.updateTimers({
          timer_id: this.timer_id,
          debounce: true,
          values: {
            project: this.project,
            task: this.task
              ? { uuid: this.task.uuid, name: this.task.name }
              : null,
            assistant: this.assistant,
            task_date: this.task_date,
            description: value,
            timers: this.timers,
            state: this.state,
          },
        });
      },
    },
    timers: {
      get() {
        return this.allTimers[this.timer_id]?.timers || this.initialTimers;
      },
    },
    state: {
      get() {
        return this.allTimers[this.timer_id]?.state || 'INACTIVE';
      },
      set(value) {
        this.updateTimers({
          timer_id: this.timer_id,
          debounce: false,
          values: {
            project: this.project,
            task: this.task
              ? { uuid: this.task.uuid, name: this.task.name }
              : null,
            assistant: this.assistant,
            task_date: this.task_date,
            description: this.description,
            timers: this.timers,
            state: value,
          },
        });
      },
    },
  },
  watch: {
    project(newValue) {
      // If an update to allTimers from another tab removes this project (ie: timer), close the dialog
      if (!newValue) this.handleClose();
    },
    state(newValue) {
      // If timer state changes in another dialog/sidebar/browser/device, reflect that state change here
      switch (newValue) {
        case 'INACTIVE':
          this.handleClose();
          break;
        case 'STARTED':
          this.startClock();
          break;
        case 'PAUSED':
          this.stopClock();
          break;
        default:
          break;
      }
    },
  },
  methods: {
    formatTimerEventsToHMS,
    formatDate,
    calculateTimerDuration,
    calculateTotalDuration,
    calculateDurationHMS,
    async open(project, assistant, options = {}) {
      // Set a timer key based on project & task uuids
      this.timer_id = `${project.project_uuid}${options.task?.uuid || ''}`;

      // Set default/initial values as if this were a new timer
      this.initialProject = undefined;
      this.initialTask = null;
      this.initialAssistant = undefined;
      this.initialTaskDate = undefined;
      this.initialDescription = '';
      this.initialTimers = [];

      // Look for an existing timer for this project/task
      let existing = this.allTimers[this.timer_id];

      // If there is an existing timer for the select project/task, set it up
      if (!existing) {
        this.initialProject = project;
        this.initialTask =
          options.task && (await getProjectTask(options.task.uuid));
        this.initialAssistant = assistant;
        this.initialTaskDate = formatShortDate(new Date());
        this.initialDescription = this.task?.description || '';
        this.initialTimers = [];
      }

      // Set any options passed in
      this.options = Object.assign({ ...DEFAULT_OPTIONS }, options);

      // Calculate the duration so far and start the clock
      this.running_hms = this.calculateDurationHMS(this.timers);
      if (this.state === 'STARTED') {
        this.startClock();
      }

      // Set the form & dialog to defaults
      this.$refs.form?.resetValidation();
      this.saving = false;
      this.alert = false;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    async handleStart() {
      if (formatShortDate(new Date()) > this.task_date) {
        if (this.timers.length) {
          this.state = 'HALTED';
          return;
        } else {
          this.task_date = formatShortDate(new Date());
        }
      }

      // Check for another timer running
      const running_id = Object.keys(this.allTimers).find(
        (k) => this.allTimers[k].state === 'STARTED'
      );

      if (running_id) {
        const running = this.allTimers[running_id];
        if (
          await this.$root.$confirm.open(
            this.$t('portal:timer.running.confirm.heading'),
            this.$t('portal:timer.running.confirm.message', {
              project: {
                company: running.project.company,
                name: running.project.project_name,
              },
            }),
            {
              okText: this.$t('portal:timer.running.confirm.ok.button'),
              cancelText: this.$t('no.text'),
              width: 400,
            }
          )
        ) {
          const active = running.timers.pop();
          active.stop = {
            time: moment().format('HH:mm:ss'),
            timestamp: moment().millisecond(0),
          };
          this.updateTimers({
            timer_id: running_id,
            values: {
              timers: running.timers.concat(active),
              state: 'PAUSED',
            },
          });
        } else {
          return;
        }
      }

      // Add a new timer
      this.timers.push({
        start: {
          time: moment().format('HH:mm:ss'),
          timestamp: moment().millisecond(0),
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: moment().utcOffset(),
      });

      // Set the new state & start the clock
      this.state = 'STARTED';
      this.startClock();
      this.handleClose();
    },
    handlePause() {
      // Update the last timer with the stop time
      const active = this.timers[this.timers.length - 1];
      active.stop = {
        time: moment().format('HH:mm:ss'),
        timestamp: moment().millisecond(0),
      };
      active.duration = this.calculateTimerDuration(active);

      // Set the new state & stop the clock
      this.state = 'PAUSED';
    },
    async handleDelete() {
      if (
        await this.$root.$confirm.open(
          this.$t('portal:timer.delete.confirm.heading'),
          this.$t('portal:timer.delete.confirm.message'),
          {
            okText: this.$t('portal:delete.button'),
            cancelText: this.$t('portal:cancel.button'),
            width: 400,
          }
        )
      ) {
        // Remove the timer entry from storage & close the dialog
        this.updateTimers({
          timer_id: this.timer_id,
        });
        this.handleClose(true);
      }
    },
    handleClose(result) {
      // Stop the clock and close the dialog
      this.stopClock();
      this.dialog = false;
      this.resolve(!!result);
    },
    updateTask(changes) {
      // Update any attached task with changes from an embedded component
      this.task = Object.assign(this.task, changes);
    },
    async handleSave() {
      // Validate the form
      if (!(await this.$refs.form.validate()).valid) {
        this.alert = {
          show: true,
          message: this.$t('portal:save.invalid.error"'),
        };
        return;
      }

      if (!this.timers.every((timer) => timer.start && timer.stop)) {
        this.alert = {
          show: true,
          message: this.$t('portal:timer.save.invalid.error'),
        };
        return;
      }

      if (
        this.project &&
        this.task_date < getEarliestTaskDate(this.project.invoice_day) &&
        this.task_date !==
          moment()
            .subtract(TIMER_TASK_DATE_NEXT_DAY_GRACE_PERIOD_HOURS, 'hours')
            .format('YYYY-MM-DD')
      ) {
        this.alert = {
          show: true,
          message: this.$t('portal:timer.save.earliestTaskDate.error'),
        };
        return;
      }

      try {
        // Create the time entry in the db
        this.totalDuration = this.calculateTotalDuration(this.timers);
        this.saving = true;

        await createEntry({
          source: 'talentplace-va-timer',
          project_uuid: this.project?.project_uuid,
          project_task_uuid: this.task?.uuid,
          virtualassistant_uuid: this.assistant.uuid,
          task_date: this.task_date,
          description: this.description,
          timers: this.timers,
          hours_decimal: this.totalDuration,
          hours: Math.floor(this.totalDuration),
          minutes: Math.round(
            (this.totalDuration - Math.floor(this.totalDuration)) * 60
          ),
        });

        // If a tesk was edited in this context. make sure it's updated in the db
        if (this.task?.dirty) {
          await updateProjectTask(this.task);
        }

        // If a tesk was completed in this context and has a next_due_date, clone it in the db
        if (this.task?.percent_complete === 100 && this.task.next_due_date) {
          await cloneNextProjectTask(this.task);
          this.$root.$snackbar.message(
            this.$t('portal:task.clone.success', {
              dueDate: formatDate(this.task.next_due_date),
            })
          );
        }

        // Remove the timer entry from storage & close the dialog
        this.updateTimers({
          timer_id: this.timer_id,
        });
        this.handleClose(true);
      } catch (error) {
        window.apm?.captureError(new Error(JSON.stringify(error)));

        this.alert = {
          show: true,
          message:
            error?.data?.message ?? this.$t('portal:timeEntry.save.error'),
        };
      } finally {
        this.saving = false;
      }
    },
    startClock() {
      this.secondsClock = setInterval(() => {
        this.running_hms = calculateDurationHMS(this.timers);
      }, 1000);
    },
    stopClock() {
      clearInterval(this.secondsClock);
    },
  },
};
</script>
