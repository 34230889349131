import { defineStore } from 'pinia';

export const useUiStore = defineStore('ui', {
  state: () => ({
    uiReady: false,
    navDrawerOpen: false,
    navDrawerExpanded: true,
    notificationDrawerOpen: false,
    notificationDrawerExpanded: false,
    notificationDrawerPanels: null,
    vaFilterNavDrawerOpen: false,
    showNewVersionAvailable: false,
    mobile: false,
    tablet: false,
    showFeedbackDialog: false,
    showHubSpotFormDialog: false,
  }),
  getters: {
    tabletOrSmaller() {
      return this.mobile || this.tablet;
    },
  },
  actions: {
    setNavDrawerOpen(payload) {
      if (this.navDrawerOpen !== payload) {
        this.navDrawerOpen = payload;
      }
    },
    setNavDrawerExpanded(payload) {
      if (this.navDrawerExpanded !== payload) {
        this.navDrawerExpanded = payload;
      }
    },
    setNotificationDrawerOpen(payload) {
      if (this.notificationDrawerOpen !== payload) {
        this.notificationDrawerOpen = payload;
      }
    },
    setNotificationDrawerExpanded(payload) {
      if (this.notificationDrawerExpanded !== payload) {
        this.notificationDrawerExpanded = payload;
      }
    },
    setNotificationDrawerPanels(payload) {
      if (this.notificationDrawerPanels !== payload) {
        this.notificationDrawerPanels = payload;
      }
    },
    toggleVaFilterNavDrawerOpen(payload) {
      this.vaFilterNavDrawerOpen = payload;
    },
    setMobile(payload) {
      this.mobile = payload;
      if (!this.mobile) {
        this.navDrawerOpen = false;
        this.notificationDrawerOpen = false;
      }
    },
    setTablet(payload) {
      this.tablet = payload;
    },
    setReady() {
      this.uiReady = true;
    },
  },
});
