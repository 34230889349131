import { getJSON, getPDF, putJSON, postJSON } from '@/services/httpService';
import i18next from '@/plugins/i18next';

export const getInvoice = (uuid) => getJSON(`/invoices/${uuid}`);

export const getInvoicePDF = (uuid) =>
  getPDF(`/invoices/${uuid}`).then((response) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response);
    link.download = i18next.t('portal:invoice.link', {
      uuid,
    });
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  });

export const updateInvoice = (invoice) => {
  return invoice.uuid === -1
    ? postJSON(`/invoices`, invoice)
    : putJSON(`/invoices/${invoice.uuid}`, {
        ...invoice,
        auditLogEntries: undefined,
      });
};

export const invoiceSearch = (filters) =>
  getJSON(`/invoices/?${filters.join('&')}`);

export const calculateInvoice = (invoice) => {
  return postJSON(`/invoices/calculate`, invoice);
};

export const postInvoice = (invoice) => {
  return postJSON(`/invoices/${invoice.uuid}/post`);
};

export const collectPayment = (invoice) => {
  return postJSON(`/invoices/${invoice.uuid}/payment/collect`);
};

export const addPayment = (invoice, payment) => {
  return postJSON(`/invoices/${invoice.uuid}/payment/add`, payment);
};

export const getPipeline = (filters) => {
  return getJSON(`/invoices/pipeline?${filters.join('&')}`);
};

export const payNow = (invoiceUuid, payload) =>
  postJSON(`/invoices/${invoiceUuid}/pay`, payload);

export const sendPaymentReminder = (invoiceUuid) =>
  postJSON(`/invoices/${invoiceUuid}/reminder`);
