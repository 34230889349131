<template>
  <v-expand-transition>
    <div
      v-if="message"
      class="bg-vgorange relative top-0 z-[1000] flex flex-none p-2 font-bold text-white"
    >
      <div class="mx-auto flex items-center justify-center gap-8">
        {{ message }}
        <vg-btn vg-size="small" @click="dismiss">Dismiss</vg-btn>
      </div>
    </div>
  </v-expand-transition>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';

export default {
  name: 'AlertBanner',
  data() {
    const { alertBanner } = storeToRefs(useConfigStore());

    return { alertBanner, message: '' };
  },
  watch: {
    alertBanner: {
      immediate: true,
      handler(value) {
        if (
          value &&
          value !== localStorage.getItem('tp.alertBanner-dismissed')
        ) {
          this.message = value;
        } else {
          this.message = '';
        }
      },
    },
  },
  methods: {
    dismiss() {
      localStorage.setItem('tp.alertBanner-dismissed', this.alertBanner);
      this.message = '';
    },
  },
};
</script>
