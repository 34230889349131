<template>
  <v-expand-transition>
    <v-alert
      v-if="modelValue"
      :v-model="modelValue.show"
      close-text="Close Alert"
      :type="modelValue.type || 'error'"
      :icon="
        !modelValue.type || modelValue.type === 'error' ? 'mdi-alert' : null
      "
      closable
      class="my-3"
      @update:model-value="onClose"
    >
      {{ modelValue.message }}
    </v-alert>
  </v-expand-transition>
</template>
<script>
export default {
  name: 'VgAlert',
  props: {
    modelValue: {
      type: Object,
      default() {
        return {
          show: false,
          message: '',
        };
      },
    },
  },
  emits: ['onClose'],
  methods: {
    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>
<style scoped>
.v-alert.success {
  @apply bg-vgmedturq !important;
}
.v-alert.warning {
  @apply bg-vgorange !important;
}
.v-alert.info {
  @apply bg-vgnavy-350 !important;
}
</style>
