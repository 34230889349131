import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { ref } from 'vue';
import { getJSON } from '@/services/httpService';

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'fr'],
    ns: ['common'],
    defaultNS: 'common',
    parseMissingKeyHandler: (key) => {
      window.apm?.captureError(new Error('MISSING_TRANSLATION_KEY: ' + key));
      if (import.meta.env.VITE_NODE_ENV === 'production') {
        return '';
      }

      // eslint-disable-next-line no-console
      console.warn('Missing translation key:', key);
      return 'MISSING_TRANSLATION_KEY: ' + key;
    },
    backend: {
      //   reloadInterval:
      //     import.meta.env.VITE_NODE_ENV === 'development' ? 5000 : false,
      loadPath: '/file/locales/{{lng}}/{{ns}}.json',
      request: async (options, url, payload, callback) => {
        try {
          const result = await getJSON(url);
          callback(null, { status: 200, data: result });
        } catch (error) {
          callback(error);
        }
      },
    },
    interpolation: { escapeValue: false },
  });

export default i18next;

let $t = ref((key, options) => i18next.t(key, options));

i18next.on('languageChanged', function (language) {
  $t.value = (key, options) => i18next.t(key, options);
  document.documentElement.setAttribute('lang', language);
});

export function i18nextPiniaPlugin() {
  return {
    i18next,
    $t,
  };
}
