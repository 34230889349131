const environmentConstants =
  import.meta.env.VITE_NODE_ENV === 'production'
    ? {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'https://api.thevirtualgurus.com',
        WS_URL: 'wss://api.thevirtualgurus.com',
        TALENTPLACE_URL: 'https://talentplace.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'https://app.ondemand.thevirtualgurus.com',
        WEBSITE_URL: 'https://thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_live_XE34WVCNyS0kBZX5CuLlNCTh',
        SHORT_ENV_PREFIX: '',
        GTM_ID: 'GTM-WNK8GH5',
        VA_ONBOARDING_URL: 'https://calendly.com/vg-talent/vg-onboarding',
        MATCHING_MEETING_URL:
          'https://calendly.com/vg-clientsuccess/matchingmeeting',
        MATCHING_MEETING_URL_FR:
          'https://calendly.com/vg-clientsuccess-fr/reunion-de-mise-en-correspondance',
        DISCOVERY_MEETING_URL:
          'https://calendly.com/virtual-gurus/30-minute-discovery',
        CONSULT_MEETING_URL_TIER_1:
          'https://calendly.com/virtual-gurus/30-minute-consult-2',
        CONSULT_MEETING_URL_TIER_2:
          'https://calendly.com/virtual-gurus/30-minute-consult-2',
        CONSULT_MEETING_URL_ENTERPRISE:
          'https://calendly.com/virtual-gurus/30-minute-consult-0',
      }
    : import.meta.env.VITE_NODE_ENV === 'qa'
    ? {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'https://qa-api.thevirtualgurus.com',
        WS_URL: 'wss://qa-api.thevirtualgurus.com',
        TALENTPLACE_URL: 'https://qa-talentplace.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'https://qa-app.ondemand.thevirtualgurus.com',
        WEBSITE_URL: 'https://thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_test_ac9n1ONiu7Ij8AiAWHx0MUPl',
        SHORT_ENV_PREFIX: 'QA',
        VA_ONBOARDING_URL: 'https://calendly.com/vg-qa/va-onboarding',
        MATCHING_MEETING_URL: 'https://calendly.com/vg-qa/client-matching',
        MATCHING_MEETING_URL_FR:
          'https://calendly.com/vg-qa/30-appariement-client',
        DISCOVERY_MEETING_URL: 'https://calendly.com/vg-qa/book-a-discovery',
        CONSULT_MEETING_URL_TIER_1: 'https://calendly.com/vg-qa/30-min-2',
        CONSULT_MEETING_URL_TIER_2: 'https://calendly.com/vg-qa/30-min-2',
        CONSULT_MEETING_URL_ENTERPRISE: 'https://calendly.com/vg-qa/30-min-0',
      }
    : import.meta.env.VITE_NODE_ENV === 'build'
    ? {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'https://build-api.thevirtualgurus.com',
        WS_URL: 'wss://build-api.thevirtualgurus.com',
        TALENTPLACE_URL: 'https://build-talentplace.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'https://build-app.ondemand.thevirtualgurus.com',
        WEBSITE_URL: 'https://thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_test_ac9n1ONiu7Ij8AiAWHx0MUPl',
        SHORT_ENV_PREFIX: 'BUILD',
        VA_ONBOARDING_URL: 'https://calendly.com/vg-qa/va-onboarding',
        MATCHING_MEETING_URL: 'https://calendly.com/vg-qa/client-matching',
        MATCHING_MEETING_URL_FR:
          'https://calendly.com/vg-qa/30-appariement-client',
        DISCOVERY_MEETING_URL: 'https://calendly.com/vg-qa/book-a-discovery',
        CONSULT_MEETING_URL_TIER_1: 'https://calendly.com/vg-qa/30-min-1',
        CONSULT_MEETING_URL_TIER_2: 'https://calendly.com/vg-qa/30-min-2',
        CONSULT_MEETING_URL_ENTERPRISE: 'https://calendly.com/vg-qa/30-min-0',
      }
    : {
        NODE_ENV: import.meta.env.VITE_NODE_ENV,
        API_URL: 'http://dev-api.thevirtualgurus.com',
        WS_URL: 'ws://dev-api.thevirtualgurus.com',
        TALENTPLACE_URL: 'http://dev-talentplace.thevirtualgurus.com',
        ASKBETTY_APP_URL: 'http://dev-app.ondemand.thevirtualgurus.com',
        WEBSITE_URL: 'https://thevirtualgurus.com',
        STRIPE_API_KEY: 'pk_test_ac9n1ONiu7Ij8AiAWHx0MUPl',
        SHORT_ENV_PREFIX: 'DEV',
        VA_ONBOARDING_URL: 'https://calendly.com/vg-qa/va-onboarding',
        MATCHING_MEETING_URL: 'https://calendly.com/vg-qa/client-matching',
        MATCHING_MEETING_URL_FR:
          'https://calendly.com/vg-qa/30-appariement-client',
        DISCOVERY_MEETING_URL: 'https://calendly.com/vg-qa/book-a-discovery',
        CONSULT_MEETING_URL_TIER_1: 'https://calendly.com/vg-qa/30-min-1',
        CONSULT_MEETING_URL_TIER_2: 'https://calendly.com/vg-qa/30-min-2',
        CONSULT_MEETING_URL_ENTERPRISE: 'https://calendly.com/vg-qa/30-min-0',
      };

export const {
  NODE_ENV,
  API_URL,
  WS_URL,
  TALENTPLACE_URL,
  ASKBETTY_APP_URL,
  WEBSITE_URL,
  MATCHING_MEETING_URL,
  MATCHING_MEETING_URL_FR,
  DISCOVERY_MEETING_URL,
  CONSULT_MEETING_URL_TIER_1,
  CONSULT_MEETING_URL_TIER_2,
  CONSULT_MEETING_URL_ENTERPRISE,
  STRIPE_API_KEY,
  SHORT_ENV_PREFIX,
  GTM_ID,
  VA_ONBOARDING_URL,
  validTimeZones,
  alertTypes,
  alertStatuses,
  jobStates,
  promotionTypeTypes,
  promotionAllowedCurrencies,
  promotionDurationTypes,
  referralSourceTypes,
  referralSourceCompensationTypes,
  referralTransactionTypes,
  referralTransactionArtifactTypes,
  countries,
  states,
  provinces,
  checkoutHoursItems,
  notificationIcons,
  offset_range,
  customizableRatePlan,
  paymentTerms,
  withdrawalOptions,
} = {
  ...environmentConstants,
  validTimeZones: [
    'America/St_Johns',
    'America/Halifax',
    'America/Glace_Bay',
    'America/Moncton',
    'America/Goose_Bay',
    'America/Blanc-Sablon',
    'America/Toronto',
    'America/Nipigon',
    'America/Thunder_Bay',
    'America/Iqaluit',
    'America/Pangnirtung',
    'America/Atikokan',
    'America/Winnipeg',
    'America/Rainy_River',
    'America/Resolute',
    'America/Rankin_Inlet',
    'America/Regina',
    'America/Swift_Current',
    'America/Edmonton',
    'America/Cambridge_Bay',
    'America/Yellowknife',
    'America/Inuvik',
    'America/Creston',
    'America/Dawson_Creek',
    'America/Fort_Nelson',
    'America/Vancouver',
    'America/Whitehorse',
    'America/Dawson',
    'America/New_York',
    'America/Detroit',
    'America/Kentucky/Louisville',
    'America/Kentucky/Monticello',
    'America/Indiana/Indianapolis',
    'America/Indiana/Vincennes',
    'America/Indiana/Winamac',
    'America/Indiana/Marengo',
    'America/Indiana/Petersburg',
    'America/Indiana/Vevay',
    'America/Chicago',
    'America/Indiana/Tell_City',
    'America/Indiana/Knox',
    'America/Menominee',
    'America/North_Dakota/Center',
    'America/North_Dakota/New_Salem',
    'America/North_Dakota/Beulah',
    'America/Denver',
    'America/Boise',
    'America/Phoenix',
    'America/Los_Angeles',
    'America/Anchorage',
    'America/Juneau',
    'America/Sitka',
    'America/Metlakatla',
    'America/Yakutat',
    'America/Nome',
    'America/Adak',
    'America/Port_of_Spain',
    'GMT',
    'UTC',
    'Unknown',
  ],
  alertTypes: [
    { title: 'Error', value: 'error' },
    { title: 'Warning', value: 'warning' },
    { title: 'Alert', value: 'alert' },
    { title: 'Info', value: 'info' },
  ],
  alertStatuses: [
    { title: 'New', value: 'new' },
    { title: 'In Progress', value: 'inprogress' },
    { title: 'Resolved', value: 'resolved' },
  ],
  jobStates: [
    { title: 'New', value: 'new' },
    { title: 'In Progress', value: 'inprogress' },
    { title: 'Done', value: 'done' },
    { title: 'Failed', value: 'failed' },
  ],
  promotionTypeTypes: [
    { title: 'Monthly Free Hours', value: 'hours' },
    { title: 'Percentage Off', value: 'percentage' },
    { title: 'Discount Amount', value: 'cents_amount' },
  ],
  promotionAllowedCurrencies: [
    { title: 'CAD', value: 'cad' },
    { title: 'USD', value: 'usd' },
  ],
  promotionDurationTypes: [
    { title: 'Once Only', value: 'once' },
    { title: 'Months', value: 'repeating' },
    { title: 'Forever', value: 'forever' },
  ],
  referralSourceTypes: [
    { title: 'CSM', value: 'cs' },
    { title: 'Sales', value: 'sales' },
    { title: 'Client', value: 'client' },
    { title: 'VA', value: 'va' },
    { title: 'Admin', value: 'admin' },
    { title: 'Affiliate', value: 'affiliate' },
    { title: 'Partner', value: 'partner' },
    { title: 'Other', value: 'other' },
  ],
  referralSourceCompensationTypes: [
    { title: 'Percentage', value: 'percentage' },
    { title: 'Amount', value: 'points_amount' },
  ],
  referralTransactionTypes: [
    { title: 'Source', value: 'source' },
    { title: 'Recipient', value: 'recipient' },
    { title: 'Withdrawal', value: 'withdrawal' },
  ],
  referralTransactionArtifactTypes: [
    { title: 'Invoice Adjustment', value: 'invoice_adjustment' },
    { title: 'Bill Adjustment', value: 'bill_adjustment' },
    { title: 'Gift Card', value: 'gift_card' },
    { title: 'EFT/Wire', value: 'eft_wire' },
    { title: 'Paycheque', value: 'paycheque' },
  ],
  countries: ['Canada', 'United States'],
  states: [
    'Alaska',
    'Alabama',
    'Arkansas',
    'American Samoa',
    'Arizona',
    'California',
    'Colorado',
    'Connecticut',
    'District of Columbia',
    'Delaware',
    'Florida',
    'Georgia',
    'Guam',
    'Hawaii',
    'Iowa',
    'Idaho',
    'Illinois',
    'Indiana',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Massachusetts',
    'Maryland',
    'Maine',
    'Michigan',
    'Minnesota',
    'Missouri',
    'Mississippi',
    'Montana',
    'North Carolina',
    'North Dakota',
    'Nebraska',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'Nevada',
    'New York',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Puerto Rico',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Virginia',
    'Virgin Islands',
    'Vermont',
    'Washington',
    'Wisconsin',
    'West Virginia',
    'Wyoming',
  ],
  provinces: [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon Territory',
  ],
  checkoutHoursItems: [
    { title: '10', value: 10 },
    { title: '15', value: 15 },
    { title: '20', value: 20 },
    { title: '25', value: 25 },
    { title: '30', value: 30 },
    { title: '35', value: 35 },
    { title: '40', value: 40 },
    { title: '50', value: 50 },
    { title: '60', value: 60 },
    { title: '70', value: 70 },
    { title: '80', value: 80 },
    { title: '90', value: 90 },
    { title: '100', value: 100 },
    { title: '100+', value: 59 },
  ],
  notificationIcons: [
    { title: 'fa-regular fa-comment-question', value: 'discussion' },
    {
      title: 'fa-regular fa-triangle-exclamation',
      value: 'alert',
      class: 'text-vgorange',
    },
    { title: 'fa-regular fa-message-smile', value: 'happy' },
    { title: 'fa-regular fa-circle-info', value: 'info' },
    { title: 'fa-regular fa-square-check', value: 'task' },
  ],
  offset_range: [
    { title: '0', value: 0 },
    { title: '1', value: 60 },
    { title: '2', value: 120 },
    { title: '3', value: 180 },
    { title: '4', value: 240 },
    { title: '5', value: 300 },
    { title: '6', value: 360 },
    { title: '7', value: 420 },
    { title: '8', value: 480 },
  ],
  customizableRatePlan: [
    'mrr_smb_custom',
    'mrr_enterprise',
    'term_smb_custom',
    'term_enterprise',
    'for_vg',
  ],
  paymentTerms: [
    { title: 'Due on receipt', value: 0 },
    { title: 'Net 15', value: 15 },
    { title: 'Net 21', value: 21 },
    { title: 'Net 30', value: 30 },
    { title: 'Net 45', value: 45 },
    { title: 'Other (Enter a number of days)', value: null },
  ],
  withdrawalOptions: {
    invoice_adjustment_hours: {
      key: 'invoice_adjustment_hours',
      label:
        'portal:referral.withdraw.options.invoice_adjustment_hours.message',
    },
    invoice_adjustment_cents: {
      key: 'invoice_adjustment_cents',
      label:
        'portal:referral.withdraw.options.invoice_adjustment_cents.message',
    },
    gift_card: {
      key: 'gift_card',
      label: 'portal:referral.withdraw.options.gift_card.message',
    },
    eft_wire: {
      key: 'eft_wire',
      label: 'portal:referral.withdraw.options.eft_wire.message',
    },
    bill_adjustment: {
      key: 'bill_adjustment',
      label: 'portal:referral.withdraw.options.bill_adjustment.message',
    },
    paycheque: {
      key: 'paycheque',
      label: 'portal:referral.withdraw.options.paycheque.message',
    },
  },
};
