import i18next from '@/plugins/i18next';

export const isValidEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z-0-9]{2,}))$/.test(
    email.toLowerCase().trim()
  );
};

export const isValidPhone = (phone) => {
  return /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/.test(phone);
};

export const validatePassword = (password) => {
  const requirements = [
    !/[A-Z]/.test(password) &&
      i18next.t('changePassword.requirements.uppercase.text'),
    !/[a-z]/.test(password) &&
      i18next.t('changePassword.requirements.lowercase.text'),
    !/\d/.test(password) &&
      i18next.t('changePassword.requirements.number.text'),
    !/[^A-Za-z\d]/.test(password) &&
      i18next.t('changePassword.requirements.special.text'),
  ].filter(Boolean);

  if (password?.length < 8) {
    return requirements.length > 0
      ? i18next.t('changePassword.requirements.lengthAndMissing.text', {
          requirements: requirements.join(', '),
        })
      : i18next.t('changePassword.requirements.length.text');
  }
  return (
    requirements.length === 0 ||
    i18next.t('changePassword.requirements.missing.text', {
      requirements: requirements.join(', '),
    })
  );
};

export const isValidDomainName = (domain) => {
  return /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(
    domain
  );
};
