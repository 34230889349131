import {
  getJSON,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';
const STORAGE_KEY = 'vg.client.checkout';
import { formatShortDate } from '@/services/formattingService';
import { flattenObj } from '@/utils/flatten';

export const getClient = (id) =>
  getJSON(`/client/${id}?current_date=${formatShortDate(new Date())}`);

export const getClientSelfById = () => {
  return getJSON(`/client/self`);
};

export const clientSearch = (filters) =>
  getJSON(`/client/text-search?${filters.join('&')}`);

export const getClientAssistants = (client_uuid) =>
  getJSON(`/client/${client_uuid}/assistants`);

export const getClientRatePlans = (client_uuid, project_uuid, type) =>
  getJSON(
    `/client/${client_uuid}/plans?project_uuid=${
      project_uuid ? project_uuid : ''
    }${type ? '&type=' + type : ''}`
  );

export const checkClientRatePlans = (client_uuid) =>
  getJSON(`/client/${client_uuid}/rate_plans`);

export const getVAProjectRatePlan = (projectId, planId) => {
  return getJSON(
    `/virtualassistants/projects/${projectId}/ratePlans/${planId}`
  );
};
export const getAllVAProjectRatePlan = (projectId, virtualassistantId) => {
  return getJSON(
    `/virtualassistants/dashboard/${virtualassistantId}/projects/${projectId}`
  );
};

export const getClientConfig = () => getJSON(`/client/config`);

export const getVAProjectRelationship = (relationshipId) => {
  return getJSON(`/virtualassistants/projects/relationship/${relationshipId}`);
};

export const saveClient = (client) => {
  return client.uuid === -1
    ? postJSON(`/client`, client)
    : putJSON(`/client/${client.uuid}`, {
        ...client,
        auditLogEntries: undefined,
      });
};

export const saveVAProjectRatePlan = (projectId, ratePlan) => {
  return ratePlan.uuid === -1
    ? postJSON(`/virtualassistants/projects/${projectId}/ratePlans`, ratePlan)
    : putJSON(
        `/virtualassistants/projects/${projectId}/ratePlans/${ratePlan.uuid}`,
        ratePlan
      );
};

export const deleteVAProjectRatePlan = (projectId, planId) => {
  return deleteAction(
    `/virtualassistants/projects/${projectId}/ratePlans/${planId}`
  );
};

export const deleteClient = (id) => deleteAction(`/client/${id}`);

export const updateCheckoutState = (payload) => {
  let checkoutState = getStoredCheckoutState();
  checkoutState = { ...checkoutState, ...payload };
  localStorage.setItem(STORAGE_KEY, JSON.stringify(checkoutState));
};

const getStoredCheckoutState = () => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY));
  } catch {
    return {};
  }
};

export const getClientTaskLists = () => {
  return getJSON(`/client/task/list`);
};

export const getClientTasks = (filters) => {
  return getJSON(`/client/task?${filters.join('&')}`);
};

export const getClientCreditCards = (clientUuid, source) =>
  getJSON(`/client/${clientUuid}/credit_card/${source}`);

export const saveClientCreditCard = (clientUuid, creditCard) =>
  postJSON(`/client/${clientUuid}/credit_card`, creditCard);

export const updateDefaultClientCreditCard = (clientUuid, creditCard) =>
  putJSON(`/client/${clientUuid}/credit_card`, creditCard);

export const deleteClientCreditCard = (clientUuid, creditCardUuid) =>
  deleteAction(`/client/${clientUuid}/credit_card/${creditCardUuid}`);

export const logClientCalendlyEvent = (clientUuid, body) =>
  postJSON(`/client/${clientUuid}/calendly_event`, body);

export const getAuditLogs = (client_uuid, pagination) => {
  const query = new URLSearchParams(flattenObj({ pagination })).toString();

  return getJSON(`/client/${client_uuid}/audit-logs?${query}`);
};
