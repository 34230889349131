<template>
  <div v-if="task.time_entries && task.time_entries.length > 0">
    <h5>{{ $t('portal:tasks.timeLogged.heading') }}</h5>
    <v-data-table
      class="mb-4"
      density="compact"
      show-expand
      :headers="[
        {
          title: $t('portal:date.label'),
          value: 'task_date',
          sortable: false,
        },
        {
          title: $t('portal:name.label'),
          value: 'full_name',
          sortable: false,
        },
        {
          title: $t('portal:duration.heading'),
          value: 'hours_decimal',
          sortable: false,
        },
      ]"
      :items="task.time_entries"
      :items-per-page="task.time_entries.length"
      item-key="uuid"
    >
      <template #bottom></template>

      <template #item.task_date="{ item }"
        >{{ formatDate(item.task_date) }}
      </template>
      <template #item.hours_decimal="{ item }"
        >{{ formatDuration(item.hours_decimal) }}
      </template>
      <template #body.append>
        <tr class="text-start font-bold">
          <td></td>
          <td>
            <span v-if="task.completed_date">{{
              formatDate(task.completed_date)
            }}</span>
          </td>
          <td>
            <span v-if="task.completed_date" class="font-bold">{{
              $t('portal:tasks.completed.label')
            }}</span>
          </td>
          <td>
            {{
              formatDuration(
                task.time_entries.reduce(
                  (total, entry) => total + entry.hours_decimal,
                  0
                )
              )
            }}
          </td>
        </tr>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          {{ item.description }}
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { formatDate, formatDuration } from '@/services/formattingService';

export default {
  name: 'ProjectTaskTimeLogged',
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
  methods: { formatDate, formatDuration },
};
</script>
<style scoped>
* :deep(.v-data-table__expanded.v-data-table__expanded__content) {
  @apply !shadow-none;
}
* :deep(td:first-child.text-start) {
  @apply !p-0;
}
</style>
