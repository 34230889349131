import {
  getJSON,
  postJSON,
  putJSON,
  deleteAction,
} from '@/services/httpService';
import { notificationIcons } from '@/config';

export const getNotification = (uuid) => getJSON(`/notifications/${uuid}`);

export const getNotifications = (filters) =>
  getJSON(`/notifications?${filters.join('&')}`);

export const getFullNotification = (uuid) =>
  getJSON(`/notifications/full/${uuid}`);

export const searchNotifications = (filters) =>
  getJSON(`/notifications/search?${filters.join('&')}`);

export const saveNotification = (notification, isNew) =>
  isNew
    ? postJSON(`/notifications`, notification)
    : putJSON(`/notifications/${notification.uuid}`, notification);

export const deleteNotification = (uuid) =>
  deleteAction(`/notifications/${uuid}`);

export const markRead = (uuid) => postJSON(`/notifications/read`, { uuid });

export const findNotificationIcon = (icon) =>
  notificationIcons.find((nIcon) => nIcon.value === icon)?.title ||
  notificationIcons[0].title;

export const findNotificationClass = (icon) =>
  notificationIcons.find((nIcon) => nIcon.value === icon)?.class;
