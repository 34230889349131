import {
  getJSON,
  getPDF,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';
import { formatShortDate } from '@/services/formattingService';
import { flattenObj } from '@/utils/flatten';
import i18next from '@/plugins/i18next';

export const getVAProjects = (uuid, dashboard = false) => {
  return getJSON(
    `/virtualassistants/${uuid}/projects?dashboard=${dashboard}&current_date=${formatShortDate(
      new Date()
    )}`
  );
};

export const getVADashboardProject = (
  virtualassistantId,
  project_uuid,
  rate_plan_uuid,
  relationship_uuid
) =>
  getJSON(
    `/virtualassistants/dashboard/${virtualassistantId}/project/${project_uuid}?current_date=${formatShortDate(
      new Date()
    )}&rate_plan_uuid=${rate_plan_uuid}&relationship_uuid=${relationship_uuid}`
  );

export const getProjectsForAdmin = (filters) =>
  getJSON(`/project?${filters?.join('&')}`);

export const getProject = (projectId) =>
  getJSON(`/project/${projectId}?current_date=${formatShortDate(new Date())}`);

export const getProjectPlans = (projectId) =>
  getJSON(`/project/${projectId}/plans`);

export const projectSearch = (filters) =>
  getJSON(`/project/text-search?${filters?.join('&')}`);

export const getVARelationships = (uuid) =>
  getJSON(`/project/${uuid}/va_relationships`);

export const getBasicProject = (projectId, rate_plan_uuid) => {
  return getJSON(
    `/project/dashboard/${projectId}?current_date=${formatShortDate(
      new Date()
    )}${rate_plan_uuid ? `&rate_plan_uuid=${rate_plan_uuid}` : ''}`
  );
};

export const getProjectTimeBilled = (projectId) => {
  return getJSON(
    `/project/${projectId}/timeBilled?current_date=${formatShortDate(
      new Date()
    )}`
  );
};

export const saveProject = (project) => postJSON(`/project`, project);

export const updateProject = (project) =>
  putJSON(`/project/${project.uuid}`, {
    ...project,
    auditLogEntries: undefined,
  });

export const saveProjectRatePlan = (
  projectId,
  ratePlan,
  adjustedOnboardingEndDateDiff
) => {
  return !ratePlan.uuid
    ? postJSON(`/project/${projectId}/ratePlan`, ratePlan)
    : putJSON(
        `/project/${projectId}/ratePlan/${
          ratePlan.uuid
        }?adjustedOnboardingEndDateDiff=${adjustedOnboardingEndDateDiff ?? ''}`,
        ratePlan
      );
};

export const deleteProjectRatePlan = (projectId, planId) => {
  return deleteAction(`/project/${projectId}/ratePlan/${planId}`);
};

export const getProjectRelationships = (projectId) => {
  return getJSON(`/project/${projectId}/relationship`);
};

export const saveProjectRelationship = (relationship) => {
  return postJSON(
    `/project/${relationship.projectId}/relationship`,
    relationship
  );
};

export const updateProjectRelationship = (relationship) => {
  return putJSON(
    `/project/${relationship.projectId}/relationship/${relationship.uuid}`,
    relationship
  );
};

export const deleteProjectRelationship = (relationship) => {
  return deleteAction(
    `/project/${relationship.projectId}/relationship/${relationship.uuid}`
  );
};

export const saveProjectVaRelationship = (relationship) => {
  return postJSON(
    `/project/${relationship.project_uuid}/va_relationship`,
    relationship
  );
};

export const updateProjectVaRelationship = (relationship) => {
  return putJSON(
    `/project/${relationship.project_uuid}/va_relationship/${relationship.uuid}`,
    relationship
  );
};

export const deleteProjectVaRelationship = (relationship) => {
  return deleteAction(
    `/project/${relationship.project_uuid}/va_relationship/${relationship.uuid}`
  );
};

export const saveProjectStatus = (projectId, status) => {
  putJSON(`/project/${projectId}/status`, status);
};

export const saveProjectMatchingState = (projectId, matchingState) => {
  putJSON(`/project/${projectId}/matchingState`, matchingState);
};

export const getPricing = (rateInformation, user_uuid) =>
  postJSON(
    `/project/pricing${user_uuid ? `?user_uuid=${user_uuid}` : ''}`,
    rateInformation
  );

export const getPlans = () => {
  return getJSON(`/project/plans`);
};

export const getVaPlans = () => {
  return getJSON(`/project/va/plans`);
};

export const createProjectTask = (task) => postJSON(`/project/task`, task);

export const getProjectTasks = (filters) => {
  return getJSON(`/project/task?${filters.join('&')}`);
};

export const getProjectTask = (uuid) => {
  return getJSON(`/project/task/${uuid}`);
};

export const getProjectTaskTimerCount = (task_uuid) =>
  getJSON(`/project/task/${task_uuid}/timer_count`);

export const cloneNextProjectTask = (task) => {
  return postJSON(
    `/project/task`,
    Object.assign(task, {
      percent_complete: 0,
      completed_date: null,
      completed_by_user_uuid: null,
    })
  );
};

export const updateProjectTask = (task) =>
  putJSON(`/project/task/${task.uuid}`, task);

export const deleteProjectTask = (task) => {
  return deleteAction(`/project/task/${task.uuid}`);
};

export const createProjectTaskList = (list) =>
  postJSON(`/project/task/list`, list);

export const getProjectTaskLists = (filters) => {
  return getJSON(`/project/task/list?${filters?.join('&') ?? ''}`);
};

export const updateProjectTaskList = (list) =>
  putJSON(`/project/task/list/${list.uuid}`, list);

export const deleteProjectTaskList = (list) =>
  deleteAction(`/project/task/list/${list.uuid}`);

export const getProjectRequirements = (projectUuid) =>
  getJSON(`/project/${projectUuid}/requirements`);

export const updateProjectRequirements = (project) =>
  putJSON(`/project/${project.uuid}/requirements`, project);

export const deleteDraftProject = (projectId) => {
  return deleteAction(`/project/${projectId}`);
};

export const sendCheckoutEmail = (emailInformation) =>
  postJSON(`/project/sendCheckoutEmail`, emailInformation);

export const createCompanyInHubSpot = (project) => {
  return postJSON(`/project/hubspot/`, project);
};

export const getAuditLogs = (project_uuid, pagination) => {
  const query = new URLSearchParams(flattenObj({ pagination })).toString();

  return getJSON(`/project/${project_uuid}/audit-logs?${query}`);
};

export const getProjectReports = (filters) =>
  getJSON(`/project/eow-report?${filters.join('&')}`);

export const getProjectReportDetails = (uuid, filters) =>
  getJSON(`/project/eow-report/${uuid}?${filters.join('&')}`);

export const saveProjectReport = (report) => {
  return report.uuid === -1
    ? postJSON(`/project/eow-report`, report)
    : putJSON(`/project/eow-report/${report.uuid}`, report);
};

export const saveProjectReportFeedback = (feedback) => {
  return feedback.uuid === -1
    ? postJSON(`/project/eow-report/feedback`, feedback)
    : putJSON(`/project/eow-report/feedback/${feedback.uuid}`, feedback);
};

export const loadTranscript = (uuid, engagements, start_date, end_date) =>
  postJSON(
    `/project/${uuid}/ml/transcripts?start_date=${start_date}&end_date=${end_date}`,
    engagements
  );

export const getAutofillData = (uuid) =>
  getJSON(`/project/${uuid}/ml/autofill`);

export const sendPrompt = (uuid, chat, type) =>
  postJSON(`/project/${uuid}/ml/prompt?request_type=${type}`, chat);

export const publishOpportunity = (project_uuid, opportunity_uuid) =>
  postJSON(`/project/${project_uuid}/opportunity/${opportunity_uuid}/publish`);

export const updatePublishedOpportunity = (opportunity_uuid, payload) =>
  putJSON(`/project/opportunity/${opportunity_uuid}/update`, payload);

export const getPublishedOpportunityByUuid = (opportunity_uuid) =>
  getJSON(`/project/opportunity/published/${opportunity_uuid}`);

export const getPublishedOpportunities = (filters) => {
  const query = new URLSearchParams(flattenObj(filters)).toString();
  return getJSON(`/project/opportunity/published?${query}`);
};

export const searchOpportunities = (filters) =>
  getJSON(`/project/opportunities/search?${filters.join('&')}`);

export const saveOpportunityResponse = (opportunity_uuid, response) =>
  putJSON(`/project/opportunity/${opportunity_uuid}/response`, {
    response,
  });

export const getVaLoyaltyRate = (user_uuid, project_uuid) =>
  getJSON(`/project/${project_uuid}/loyalty/${user_uuid}/`);

export const updateVaLoyaltyRate = (user_uuid, project_uuid, loyalty_rate) =>
  putJSON(`/project/${project_uuid}/loyalty/${user_uuid}/`, loyalty_rate);

export const getClientRequirementsPDF = (opportunity_uuid, type) =>
  getPDF(`/project/opportunity/${opportunity_uuid}/${type}/pdf`).then(
    (response) => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response);
      link.download = i18next.t('portal:requirements.link');
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    }
  );
