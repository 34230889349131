<template>
  <ClientCreditCards />
</template>

<script>
import ClientCreditCards from '@/components/ClientCreditCards.vue';

export default {
  name: 'ClientBilling',
  components: { ClientCreditCards },
  data() {
    return {};
  },
};
</script>
