import moment from 'moment';
import { formatDate } from '@/services/formattingService';
import i18next from '@/plugins/i18next';

export const describe = function (schedule) {
  if (!schedule) return null;

  // A lone start date is not valid
  let { start_date, end_date, repeat_every, period } = schedule;
  if (!end_date && !repeat_every && !period) return null;

  if (!repeat_every || !period) {
    return start_date
      ? i18next.t('portal:date.fromUntil.label', {
          date: {
            from: formatDate(start_date),
            to: formatDate(end_date),
          },
        })
      : i18next.t('portal:date.dueBy.label', {
          date: formatDate(end_date),
        });
  }
  return i18next.t('portal:date.everyFromUntil.label', {
    date: {
      from: formatDate(start_date),
      to: formatDate(end_date),
    },
    period:
      (parseInt(repeat_every) === 1 ? '' : repeat_every + ' ') +
      i18next.t(
        {
          days: 'portal:time.day.label',
          weeks: 'portal:time.week.label',
          months: 'portal:time.month.label',
          years: 'portal:time.year.label',
        }[period],
        { count: parseInt(repeat_every) }
      ),
    count: parseInt(repeat_every),
  });
};

export const upcoming = function (schedule, now = moment().startOf('day')) {
  return (function* () {
    // An empty schedule object is not valid
    if (!schedule) return null;

    // A lone start date is not valid
    let { start_date, end_date, repeat_every, period } = schedule;
    if (!end_date && !repeat_every && !period) return null;

    const next = moment(repeat_every && period ? start_date : end_date);
    const until = end_date && moment(end_date);

    if (!repeat_every || !period) return next.clone().format('YYYY-MM-DD');
    while (!until || next.isBefore(until)) {
      if (next.isSameOrAfter(now)) {
        yield next.clone().format('YYYY-MM-DD');
      }
      next.add(repeat_every, period);
    }
    return next.clone().format('YYYY-MM-DD');
  })();
};

export default {
  namespace: true,
  describe,
  upcoming,
};
