<template>
  <div
    :class="[
      'fixed inset-y-0 right-0 bg-white',
      uiStore.tabletOrSmaller ? 'z-[200]' : 'z-[100]',
    ]"
  >
    <!-- Toggle Button -->
    <button
      v-if="!uiStore.tabletOrSmaller"
      class="text-vgmedturq absolute left-[-2.5rem] top-2 h-10 w-10 rounded-l-lg bg-white text-left"
      style="box-shadow: -4px 4px 4px -1px rgba(0, 0, 0, 0.1)"
      :aria-label="$t('notificationDrawer.toggleDrawer.label')"
      @click="
        uiStore.setNotificationDrawerExpanded(
          !uiStore.notificationDrawerExpanded
        );
        panels = sessionUserHasPermission('virtualassistant') ? 1 : 0;
      "
    >
      <v-tooltip location="left" :disabled="full && !initialLoad">
        <template #activator="{ props }">
          <fa-icon
            class="px-3 py-1 text-xl font-bold"
            :class="{ 'mr-2': uiStore.notificationDrawerExpanded }"
            :icon="
              uiStore.notificationDrawerExpanded
                ? 'fa-light fa-chevron-right'
                : 'fa-light fa-chevron-left'
            "
            v-bind="props"
          />
        </template>
        <span>{{ $t('notificationDrawer.toggleDrawer.label') }}</span>
      </v-tooltip>
    </button>
    <!-- Notification Drawer Content -->
    <div
      :class="[
        'fixed flex h-full flex-col overflow-y-auto bg-white transition lg:relative lg:translate-x-0',
        uiStore.tabletOrSmaller ? 'w-full' : full ? 'w-[17rem]' : 'w-20',
        !uiStore.notificationDrawerOpen ? 'translate-x-0' : '-translate-x-full',
      ]"
      role="region"
      :aria-label="$t('notificationDrawer.notification.content.label')"
    >
      <!-- With Notification Drawer Expanded -->
      <div
        v-if="
          uiStore.notificationDrawerExpanded || uiStore.notificationDrawerOpen
        "
        class="flex h-full flex-col"
        @keyup.esc="uiStore.setNotificationDrawerOpen(false)"
      >
        <div v-if="uiStore.tabletOrSmaller" class="mx-auto p-4">
          <button
            class="absolute right-2 top-2 p-2"
            :aria-label="$t('notificationDrawer.notification.close.label')"
            @click.prevent.stop="uiStore.setNotificationDrawerOpen(false)"
          >
            <fa-icon icon="fa-regular fa-xmark-large" class="text-vgocean" />
          </button>
        </div>
        <!-- Running Timer -->
        <v-expand-transition>
          <div
            v-if="sessionUserHasPermission('virtualassistant') && activeTimer"
            class="bg-white p-4"
          >
            <h2 class="text-vgorange m-0 mb-2 text-lg">
              {{ $t('notificationDrawer.timers.running.label') }}
            </h2>
            <div class="timer active">
              <h2 class="my-0 truncate text-sm">
                {{ activeTimer.project.project_name }}
              </h2>
              <h3
                v-if="activeTimer.task"
                class="text-vgmedturq my-0 h-4 truncate text-xs font-bold leading-3"
              >
                <span>{{ activeTimer.task.name }}</span>
              </h3>
              <div class="panel">
                <span>
                  <fa-icon
                    icon="fa-regular fa-circle-pause"
                    class="text-vgorange mr-2 shrink"
                    :title="$t('notificationDrawer.timers.pause.title')"
                    @click="handleTimerPause(activeTimer.id)"
                  />
                </span>
                <span v-if="activeTimer.running_hms" class="grow">{{
                  `${activeTimer.running_hms.h}h ${activeTimer.running_hms.m}m ${activeTimer.running_hms.s}s`
                }}</span>
                <span>
                  <fa-icon
                    icon="fa-regular fa-circle-stop"
                    class="mr-2 shrink"
                    :title="$t('notificationDrawer.timers.stop.title')"
                    @click="handleOpenTimerDialog(activeTimer.id)"
                  />
                </span>
              </div>
            </div>
          </div>
        </v-expand-transition>
        <!-- Accordion -->
        <v-expansion-panels
          v-model="panels"
          class="w-full"
          :class="activeTimer ? 'mt-0' : 'mt-8'"
          variant="accordion"
        >
          <!-- Timers -->
          <v-expansion-panel
            v-if="sessionUserHasPermission('virtualassistant')"
          >
            <v-expansion-panel-title ref="timerPanel">
              <fa-icon class="mr-3 text-2xl" icon="fa-regular fa-timer" />
              <span class="hover:text-vgorange w-full">
                {{ $t('timers.label') }}
              </span>
              <template #actions>
                <fa-icon
                  v-if="panels === 0"
                  class="text-vgstone"
                  icon="fa-regular fa-chevron-down"
                />
                <fa-icon
                  v-else
                  class="text-vgstone"
                  icon="fa-regular fa-chevron-right"
                />
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div v-if="inactiveTimers.length">
                <div
                  v-for="inactiveTimer in inactiveTimers"
                  :key="inactiveTimer.id"
                  class="timer"
                >
                  <h4>
                    {{ inactiveTimer.project.project_name }}
                  </h4>
                  <h5 v-if="inactiveTimer.task" class="h-4 truncate">
                    <span>{{ inactiveTimer.task.name }}</span>
                  </h5>
                  <div class="panel">
                    <span>
                      <fa-icon
                        v-if="inactiveTimer.state === 'HALTED'"
                        icon="fa-regular fa-octagon-exclamation text-vgorange"
                        class="mr-2 shrink"
                        :title="$t('notificationDrawer.timers.halted.label')"
                        @click="handleOpenTimerDialog(inactiveTimer.id)"
                      />
                      <fa-icon
                        v-else
                        icon="fa-regular fa-circle-play"
                        class="mr-2 shrink"
                        :class="
                          inactiveTimer.state === 'INACTIVE'
                            ? 'text-vgocean'
                            : 'text-vgorange'
                        "
                        :title="$t('notificationDrawer.timers.start.label')"
                        @click="handleTimerStart(inactiveTimer.id)"
                      /> </span
                    ><span class="grow">{{
                      formatTimerEventsToHMS(inactiveTimer.timers)
                    }}</span
                    ><span>
                      <fa-icon
                        v-if="inactiveTimer.state === 'HALTED'"
                        icon="fa-regular fa-octagon-exclamation text-vgorange"
                        class="mr-2 shrink"
                        :title="$t('notificationDrawer.timers.halted.label')"
                        @click="handleOpenTimerDialog(inactiveTimer.id)" />
                      <fa-icon
                        v-else
                        icon="fa-regular fa-circle-stop"
                        class="mr-2 shrink"
                        :title="$t('notificationDrawer.timers.open.label')"
                        @click="handleOpenTimerDialog(inactiveTimer.id)"
                    /></span>
                  </div>
                </div>
              </div>
              <div v-else class="text-vgstone pb-4 text-sm uppercase">
                {{ $t('notificationDrawer.timers.noInactiveTimers.label') }}
              </div>
            </v-expansion-panel-text></v-expansion-panel
          >
          <!-- Notifications -->
          <v-expansion-panel>
            <v-expansion-panel-title ref="notificationsPanel">
              <fa-icon class="mr-3 text-2xl" icon="fa-regular fa-bell" />
              <span class="hover:text-vgorange w-full">
                {{ $t('notifications.label') }}
              </span>
              <template #actions>
                <fa-icon
                  v-if="
                    (sessionUserHasPermission('virtualassistant') &&
                      panels === 1) ||
                    (!sessionUserHasPermission('virtualassistant') &&
                      panels === 0)
                  "
                  class="text-vgstone"
                  icon="fa-regular fa-chevron-down"
                />
                <fa-icon
                  v-else
                  class="text-vgstone"
                  icon="fa-regular fa-chevron-right"
                />
              </template>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="text-vgstone pb-4 text-sm uppercase">
                {{ $t('notificationDrawer.notifications.recent.label') }}
              </div>
              <p v-if="firstThreeNotifications.length === 0" class="italic">
                {{ $t('notificationDrawer.notifications.noNew.label') }}
              </p>
              <div
                v-for="(notification, key) in firstThreeNotifications"
                :key="notification.uuid"
                class="flex py-4"
              >
                <fa-icon
                  :icon="findNotificationIcon(notification.icon)"
                  :class="findNotificationClass(notification.icon)"
                  class="bg-vgsilver-200 mr-2 h-[2.5rem] w-[2.5rem] min-w-[2.5rem] rounded-full pt-1 text-center text-2xl"
                />
                <router-link
                  :ref="`notification_${key}`"
                  :to="
                    notification.entity_link &&
                    notification.entity_link.startsWith('discussion')
                      ? `/${
                          sessionUserHasPermission('client')
                            ? 'client'
                            : sessionUserHasPermission('virtualassistant')
                            ? 'va'
                            : 'admin'
                        }/discussions/${notification.entity_link.split(':')[1]}`
                      : `/${
                          sessionUserHasPermission('client')
                            ? 'client'
                            : sessionUserHasPermission('virtualassistant')
                            ? 'va'
                            : 'admin'
                        }/notifications/${notification.uuid}`
                  "
                  class="flex flex-col"
                >
                  <div class="text-vgnavy font-semibold">
                    <span
                      v-if="notification.priority === 0"
                      class="text-vgorange uppercase"
                      >{{ $t('notificationDrawer.priority.label') }}</span
                    >
                    {{ notification.title }}
                  </div>
                  <div class="text-vgstone flex text-sm font-normal">
                    {{ formatShortDateTime(notification.created_date) }}
                    <fa-icon
                      icon="fa-regular fa-chevron-right"
                      class="text-md bg-vgsilver-200 text-vgmedturq ml-auto h-[1.5rem] w-[1.5rem] rounded-full py-1 text-center"
                    />
                  </div>
                </router-link>
              </div>
              <button
                v-if="full"
                class="border-vgocean text-vgorange w-full rounded-md border-2 py-4 hover:font-bold"
                @click="handleViewAllNotifications"
              >
                {{ $t('showAll.label') }}
              </button>
            </v-expansion-panel-text>
          </v-expansion-panel>
          <!-- Resources -->
        </v-expansion-panels>
        <div class="mt-auto justify-end p-4">
          <div class="mt-6">
            <button
              class="text-vgocean flex w-full items-center rounded-md text-sm font-semibold uppercase"
              @click="uiStore.showFeedbackDialog = true"
            >
              <fa-icon
                class="text-vgocean w-12 text-2xl"
                icon="fa-light fa-bullhorn"
              />
              {{ $t('notificationDrawer.widgets.sendFeedback.link') }}
            </button>
          </div>
          <div
            v-if="
              (sessionUserHasPermission('client') ||
                sessionUserHasPermission('virtualassistant')) &&
              showEarlyAccess &&
              !checkFeatureFlag('remove-early-access-banner')
            "
            class="mt-6"
          >
            <button
              class="text-vgocean flex w-full items-center rounded-md text-sm font-semibold uppercase"
              aria-labelledby="join-early-access"
              @click="giveEarlyAccess()"
            >
              <fa-icon
                class="text-vgocean w-12 text-2xl"
                icon="fa-light fa-gift"
              />
              {{ $t('notificationDrawer.widgets.joinEarlyAccess.link') }}
            </button>
          </div>
          <div v-if="sessionUserHasPermission('virtualassistant')" class="mt-6">
            <a
              class="text-vgocean flex w-full items-center rounded-md text-sm font-semibold uppercase"
              href="https://va.thevirtualgurus.com/"
              target="_blank"
              :title="$t('notificationDrawer.needHelp.message')"
              :aria-label="$t('notificationDrawer.widgets.communityHub.link')"
            >
              <fa-icon
                class="text-vgocean flex w-12 justify-center text-2xl"
                icon="fa-light fa-people-line"
              />
              {{ $t('notificationDrawer.widgets.communityHub.link') }}
            </a>
          </div>
          <div v-if="showHubSpotFormButton" class="mt-6">
            <a
              class="text-vgocean flex w-full items-center rounded-md text-sm font-semibold uppercase"
              href="#"
              @click="uiStore.showHubSpotFormDialog = true"
            >
              <fa-icon
                class="text-vgocean flex w-12 justify-center text-2xl"
                icon="fa-light fa-comment-question"
              />
              {{ $t('contactUs.label') }}
            </a>
          </div>
        </div>
        <!-- /Accordion -->
      </div>
      <!-- With Notification Drawer Collapsed -->
      <div v-else class="flex flex-auto flex-col justify-start">
        <button
          v-if="sessionUserHasPermission('virtualassistant')"
          class="text-vgnavy mt-8 shrink py-2 font-bold"
          :aria-label="$t('notificationDrawer.timers.show.label')"
          @click="showTimers()"
        >
          <v-tooltip location="left">
            <template #activator="{ props }">
              <fa-icon
                v-if="activeTimer"
                icon="fa-regular fa-timer mx-3"
                class="animate-deep-pulse text-vgorange text-2xl"
                v-bind="props"
              />
              <fa-icon
                v-else
                icon="fa-regular fa-timer"
                class="mx-3 text-2xl"
                v-bind="props"
              />
            </template>
            <span>{{ $t('timers.label') }}</span>
          </v-tooltip>
        </button>
        <button
          class="text-vgnavy mt-3 shrink py-2 font-bold"
          :aria-label="$t('notificationDrawer.showNotifications.label')"
          @click="showNotifications()"
        >
          <v-tooltip location="left">
            <template #activator="{ props }">
              <v-badge
                :content="notificationBadgeContent"
                :model-value="!!notificationBadgeContent"
                :color="vgOrange"
                offset-x="10"
              >
                <fa-icon
                  icon="fa-regular fa-bell"
                  class="mx-3 text-2xl"
                  v-bind="props"
                />
              </v-badge>
            </template>
            <span>{{ $t('notifications.label') }}</span>
          </v-tooltip>
        </button>
        <div class="grow"></div>
        <div
          role="region"
          :aria-label="$t('notificationDrawer.widgets.pane.label')"
        >
          <div class="mb-6">
            <button
              class="w-full"
              :aria-label="$t('notificationDrawer.widgets.sendFeedback.link')"
              @click="uiStore.showFeedbackDialog = true"
            >
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <fa-icon
                    class="text-vgocean w-full px-3 text-center text-2xl"
                    icon="fa-light fa-bullhorn"
                    v-bind="props"
                  />
                </template>
                <span>{{
                  $t('notificationDrawer.widgets.sendFeedback.link')
                }}</span>
              </v-tooltip>
            </button>
          </div>
          <div
            v-if="
              (sessionUserHasPermission('client') ||
                sessionUserHasPermission('virtualassistant')) &&
              showEarlyAccess &&
              !checkFeatureFlag('remove-early-access-banner')
            "
            class="mb-6"
          >
            <button
              class="w-full"
              :aria-label="
                $t('notificationDrawer.widgets.joinEarlyAccess.link')
              "
              @click="giveEarlyAccess()"
            >
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <fa-icon
                    class="text-vgocean w-full px-3 text-center text-2xl"
                    icon="fa-light fa-gift"
                    v-bind="props"
                  />
                </template>
                <span>{{
                  $t('notificationDrawer.widgets.joinEarlyAccess.link')
                }}</span>
              </v-tooltip>
            </button>
          </div>
          <div v-if="sessionUserHasPermission('virtualassistant')" class="mb-6">
            <a
              href="https://va.thevirtualgurus.com/"
              target="_blank"
              :aria-label="$t('notificationDrawer.widgets.communityHub.link')"
            >
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <fa-icon
                    class="text-vgocean w-full px-3 text-center text-2xl"
                    icon="fa-light fa-people-line"
                    v-bind="props"
                  />
                </template>
                <span>{{
                  $t('notificationDrawer.widgets.communityHub.link')
                }}</span>
              </v-tooltip>
            </a>
          </div>
          <div v-if="showHubSpotFormButton" class="mb-6">
            <a
              href="#"
              :aria-label="$t('contactUs.label')"
              @click="uiStore.showHubSpotFormDialog = true"
            >
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <fa-icon
                    class="text-vgocean w-full px-3 text-center text-2xl"
                    icon="fa-light fa-comment-question"
                    v-bind="props"
                  />
                </template>
                <span>{{ $t('contactUs.label') }}</span>
              </v-tooltip>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div ref="srAnnouncement" role="alert" class="sr-only" aria-live="polite" />
    <FeedbackDialog ref="feedback" />
    <HubSpotFormDialog ref="hubspotForm" />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { sessionUserHasPermission } from '@/services/clientSessionService';
import {
  findNotificationIcon,
  findNotificationClass,
} from '@/services/notificationService';
import {
  formatShortDateTime,
  formatTimerEventsToHMS,
} from '@/services/formattingService';
import { mapStores, storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useNotificationsStore } from '@/stores/notifications';
import { useTimersStore } from '@/stores/timers';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import tailwind from 'tailwind.config';
import FeedbackDialog from '../dialogs/FeedbackDialog.vue';
import HubSpotFormDialog from '../dialogs/HubSpotFormDialog.vue';

export default {
  name: 'NotificationDrawer',
  components: {
    FeedbackDialog,
    HubSpotFormDialog,
  },
  data() {
    const notificationsStore = useNotificationsStore();
    const timersStore = useTimersStore();
    const { user } = storeToRefs(useUserStore());
    const { checkFeatureFlag, showHubSpotFormButton } = storeToRefs(
      useConfigStore()
    );
    const { firstThreeNotifications, notificationCount } = storeToRefs(
      notificationsStore
    );
    const { updateNotificationsSearch } = notificationsStore;
    const { allTimers, activeTimer, inactiveTimers } = storeToRefs(timersStore);
    const { timerStart, timerPause } = timersStore;

    return {
      user,
      checkFeatureFlag,
      showHubSpotFormButton,
      firstThreeNotifications,
      notificationCount,
      updateNotificationsSearch,
      allTimers,
      activeTimer,
      inactiveTimers,
      timerStart,
      timerPause,
      initialLoad: true,
      collapseCurrent: false,
      panels: null,
      vgOrange: tailwind.theme.extend.colors.vgorange[500],
    };
  },
  computed: {
    ...mapStores(useUiStore),
    full: function () {
      return (
        this.uiStore.notificationDrawerExpanded ||
        this.uiStore.notificationDrawerOpen
      );
    },
    notificationBadgeContent() {
      return this.notificationCount > 50 ? '50+' : this.notificationCount;
    },
    showEarlyAccess: function () {
      return (
        !this.user.permissions.includes('admin') &&
        !(
          (this.user.permissions.includes('client') &&
            this.user.permissions.includes('client.early-access')) ||
          (this.user.permissions.includes('virtualassistant') &&
            this.user.permissions.includes('virtualassistant.early-access'))
        )
      );
    },
  },
  watch: {
    $route() {
      this.collapseCurrent = false;
    },
    panels: {
      handler(newVal) {
        this.uiStore.setNotificationDrawerPanels(newVal);
      },
    },
    'uiStore.notificationDrawerPanels': {
      handler(newVal) {
        if (this.panels !== newVal) this.panels = newVal;
      },
    },
    'uiStore.notificationDrawerExpanded': {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          nextTick(() => {
            this.announceToScreenReader(
              this.$t('notificationDrawer.notification.expanded.message')
            );
          });
        } else if (newVal === false && oldVal === true) {
          this.announceToScreenReader(
            this.$t('notificationDrawer.notification.closed.message')
          );
        }
      },
    },
    'uiStore.notificationDrawerOpen': {
      handler(newVal, oldVal) {
        if (newVal === true && oldVal === false) {
          nextTick(() => {
            this.announceToScreenReader(
              this.$t('notificationDrawer.notifications.open.message')
            );
          });
        } else if (newVal === false && oldVal === true) {
          this.announceToScreenReader(
            this.$t('notificationDrawer.notification.closed.message')
          );
        }
      },
    },
  },
  created() {
    nextTick(() => {
      this.initialLoad = false;
    });
  },
  methods: {
    findNotificationIcon,
    findNotificationClass,
    sessionUserHasPermission,
    formatShortDateTime,
    formatTimerEventsToHMS,
    handleTimerStart(timer_id) {
      this.timerStart(timer_id);
    },
    handleTimerPause(timer_id) {
      this.timerPause(timer_id);
    },
    handleOpenTimerDialog(timer_id) {
      const timer = this.allTimers[timer_id];

      this.$root.$timer.open(timer.project, timer.assistant, {
        task: timer.task,
      });
    },
    handleViewAllNotifications() {
      const allNotificationsUrl = `/${
        sessionUserHasPermission('client')
          ? 'client'
          : sessionUserHasPermission('virtualassistant')
          ? 'va'
          : 'admin'
      }/notifications`;
      if (this.$route.path !== allNotificationsUrl) {
        this.$router.push(allNotificationsUrl);
      }
      if (this.uiStore.notificationDrawerExpanded) {
        this.uiStore.setNotificationDrawerExpanded(false);
      }
      if (this.uiStore.notificationDrawerOpen) {
        this.uiStore.setNotificationDrawerOpen(false);
      }
      this.updateNotificationsSearch({
        show: 'all',
      });
    },
    giveEarlyAccess() {
      const homePath = this.user.permissions.includes('client')
        ? '/client'
        : this.user.permissions.includes('virtualassistant')
        ? '/va'
        : '';
      if (this.$route.fullPath === `${homePath}/home?earlyAccess=show`) {
        this.$router.replace(`${homePath}/home`);
        nextTick(() => {
          this.$router.replace(`${homePath}/home?earlyAccess=show`);
        });
      } else {
        this.$router.push(`${homePath}/home?earlyAccess=show`);
      }
      return;
    },
    announceToScreenReader(text) {
      nextTick(() => {
        const announcement = this.$refs.srAnnouncement;
        if (announcement) {
          announcement.textContent = text;
        }

        setTimeout(() => {
          announcement.textContent = '';
        }, 1000);
      });
    },
    showNotifications() {
      this.uiStore.setNotificationDrawerExpanded(
        !this.uiStore.notificationDrawerExpanded
      );

      sessionUserHasPermission('client') || sessionUserHasPermission('admin')
        ? (this.panels = 0)
        : (this.panels = 1);
      nextTick(() => {
        this.$refs.notificationsPanel?.$el.focus();
      });
    },
    showTimers() {
      this.panels = 0;
      this.uiStore.setNotificationDrawerExpanded(
        !this.uiStore.notificationDrawerExpanded
      );
      nextTick(() => {
        this.$refs.timerPanel?.$el.focus();
      });
    },
  },
};
</script>
<style scoped>
* :deep(.v-expansion-panel) {
  @apply mb-0 px-4 pb-2;
}
* :deep(.v-expansion-panel .v-expansion-panel__shadow),
* :deep(.v-expansion-panels--variant-accordion > .v-expansion-panel::after) {
  @apply hidden;
}
* :deep(.v-expansion-panel-title) {
  font-size: initial;
  @apply p-0 min-h-[2.5rem] text-left text-vgstone-800;
}
* :deep(.v-expansion-panel-title--active) {
  @apply !min-h-[2.5rem] font-bold text-vgnavy;
}
* :deep(.v-expansion-panel-text__wrapper) {
  @apply p-0;
}
* :deep(.v-expansion-panel-title__overlay) {
  @apply shadow-none opacity-0 hidden;
}
.timer {
  @apply border border-vgocean rounded-lg px-2 py-1 mb-2;
}
.timer h4 {
  @apply text-sm m-0 text-vgocean;
}
.timer h5 {
  @apply text-xs m-0 text-vgocean;
}
.timer .panel {
  @apply flex flex-row justify-between mt-1 w-full font-bold text-base text-vgocean;
}
.timer.active {
  @apply border-2 border-vgorange;
}
.timer.active .panel {
  @apply text-vgorange;
}
</style>
