import {
  getJSON,
  postJSON,
  putJSON,
  deleteAction,
} from '@/services/httpService';

export const getFeedback = (filters) => {
  return getJSON(`/feedback/?${filters.join('&')}`);
};

export const saveFeedback = (testimonial) => {
  return postJSON(`/feedback/`, testimonial);
};

export const saveTalentplaceFeedback = (feedback) => {
  return postJSON(`/feedback/talentplace/`, feedback);
};

export const updateFeedback = (testimonial) => {
  return putJSON(`/feedback/${testimonial.uuid}`, testimonial);
};

export const deleteFeedback = (testimonialUuid) => {
  return deleteAction(`/feedback/${testimonialUuid}`);
};
