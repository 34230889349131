import { getConfigs } from '@/services/configurationService';
import { defineStore } from 'pinia';
import { useUserStore } from './user';

// set this to true if you want to show the maintenance mode page when unable to connect
const DEFAULT_TO_MAINTENANCE_MODE = false;

export const useConfigStore = defineStore('config', {
  state: () => ({
    configs: [],
    configsLoaded: false,
  }),
  getters: {
    checkFeatureFlag() {
      return (feature) => {
        return this.configs.some(
          (config) =>
            config.name === `featureFlag.${feature}` &&
            config.value === 'enabled'
        );
      };
    },

    checkMaintenanceFlag() {
      return (feature) => {
        return this.configs.some(
          (config) =>
            config.name === `maintenanceFlag.${feature}` &&
            config.value === 'enabled'
        );
      };
    },
    inMaintenanceMode() {
      const userStore = useUserStore();
      return (
        (this.checkMaintenanceFlag('all') ||
          (DEFAULT_TO_MAINTENANCE_MODE && this.configs.length === 0)) &&
        !userStore.user?.permissions.includes('super_admin')
      );
    },
    alertBanner() {
      return this.configs.find((config) => config.name === 'alertBanner')
        ?.value;
    },
    apmServerUrl() {
      return this.configs.find((config) => config.name === 'apmServerUrl')
        ?.value;
    },
    showHubSpotFormButton() {
      const userStore = useUserStore();
      const hubspotConfig = this.configs.find(
        (config) => config.name === 'hubspot' && config.value === 'enabled'
      );
      return (
        (userStore.user?.permissions.includes('client') &&
          hubspotConfig?.extra?.forms?.[this.i18next.language]?.client) ||
        (userStore.user?.permissions.includes('virtualassistant') &&
          hubspotConfig?.extra?.forms?.[this.i18next.language]?.va)
      );
    },
  },
  actions: {
    async loadConfig() {
      this.configs = await getConfigs().catch(() => []);
      this.configsLoaded = true;
    },
  },
});
