import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@mdi/font/css/materialdesignicons.css';
import { en, fr } from 'vuetify/locale';
import { createVuetify } from 'vuetify';

export default new createVuetify({
  icons: {
    iconfont: 'mdi',
  },
  locale: {
    locale: 'en',
    fallback: 'en',
    messages: { en, fr },
  },
});
