<template>
  <div
    v-if="inMaintenanceMode"
    class="text-vgnavy flex min-h-screen flex-1 flex-col"
    data-app
  >
    <VgHeader />
    <div class="mx-auto max-w-xl overflow-hidden py-5 md:max-w-4xl lg:flex">
      <div class="mx-auto mr-6 max-w-2xl p-2 text-center lg:pt-20">
        <h1>Maintenance In Progress</h1>

        <p class="spacing-6 pb-10 text-xl">
          We're sorry, but Talentplace is currently undergoing maintenance to
          improve your experience. We apologize for any inconvenience this may
          cause and thank you for your patience. Our team is working hard to get
          the website back online as soon as possible. The page will
          automatically update when the maintenance is complete.
        </p>
      </div>
      <div class="md:shrink-0">
        <img
          class="mx-auto h-full object-contain md:w-24"
          src="/img/person_gear.png"
          alt
        />
      </div>
    </div>
    <VgFooter />
  </div>
  <div
    v-else-if="uiReady"
    class="text-vgnavy flex min-h-screen flex-1 flex-col"
    data-app
  >
    <AlertBanner />
    <PotentialMatchDialog ref="potentialMatchDialog"></PotentialMatchDialog>
    <VgHeader v-if="!$route.meta.hideHeaderFooter" />
    <div
      :class="`bg-vgsilver-100 flex flex-auto flex-col overflow-hidden ${
        $route.meta.hasSession && !$route.meta.hideSidebar
          ? uiStore.navDrawerExpanded
            ? 'lg:pl-[17rem]'
            : 'lg:pl-20'
          : ''
      } ${
        $route.meta.hasSession && !$route.meta.hideSidebar
          ? uiStore.notificationDrawerExpanded
            ? 'lg:pr-[17rem]'
            : 'lg:pr-20'
          : ''
      } ${uiStore.navDrawerOpen ? 'h-0 overflow-hidden' : ''}`"
    >
      <main class="bg-vgstone-100/50 bg-vg-blotch relative flex-auto">
        <router-view v-if="user || !$route.meta.requiresAuth" />
      </main>
      <VgFooter v-if="!$route.meta.hideHeaderFooter" />
    </div>
    <NavDrawer v-if="$route.meta.hasSession && !$route.meta.hideSidebar" />
    <NotificationDrawer
      v-if="
        $route.meta.hasSession &&
        !$route.meta.hideSidebar &&
        hasNotificationDrawerPermission
      "
    />
    <NewVersionPopup />
  </div>
  <div v-else>
    <v-skeleton-loader
      class="mx-auto mt-8 max-w-[80%]"
      type="article"
    ></v-skeleton-loader>
  </div>
  <LoginDialog ref="loginDialog" />
  <ConfirmDialog ref="confirm"></ConfirmDialog>
  <Snackbar ref="snackbar"></Snackbar>
  <TimerDialog ref="timer"></TimerDialog>
</template>
<script>
import { provide } from 'vue';
import { getSessionError } from '@/services/clientSessionService';
import VgHeader from '@/components/VgHeader.vue';
import VgFooter from '@/components/VgFooter.vue';
import LoginDialog from './dialogs/LoginDialog.vue';
import ConfirmDialog from '@/dialogs/ConfirmDialog.vue';
import Snackbar from '@/components/Snackbar.vue';
import NavDrawer from '@/components/NavDrawer.vue';
import NotificationDrawer from '@/components/NotificationDrawer.vue';
import AlertBanner from '@/components/AlertBanner.vue';
import NewVersionPopup from '@/components/NewVersionPopup.vue';
import { mapStores, storeToRefs } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { useConfigStore } from '@/stores/config';
import PotentialMatchDialog from '@/dialogs/PotentialMatchDialog.vue';
import TimerDialog from './dialogs/TimerDialog.vue';
import { useTranslation } from 'i18next-vue';
import { moment } from '@/services/formattingService';

// Tailwindcss classes that must be kept by purgecss (do not delete):
// v-ripple__animation v-btn__overlay v-snack__content project-status-1 project-status-2 project-status-3 project-status-4 project-status-5 ql-snow

export default {
  name: 'App',
  components: {
    VgHeader,
    VgFooter,
    LoginDialog,
    ConfirmDialog,
    Snackbar,
    NavDrawer,
    NotificationDrawer,
    AlertBanner,
    PotentialMatchDialog,
    TimerDialog,
    NewVersionPopup,
  },
  data() {
    const userStore = useUserStore();
    const { virtualassistant, user, preferences } = storeToRefs(userStore);
    const { setPreferences } = userStore;
    const { checkFeatureFlag, inMaintenanceMode, configsLoaded } = storeToRefs(
      useConfigStore()
    );
    const { uiReady } = storeToRefs(useUiStore());
    const { i18next } = useTranslation();

    return {
      virtualassistant,
      user,
      preferences,
      setPreferences,
      checkFeatureFlag,
      inMaintenanceMode,
      configsLoaded,
      uiReady,
      i18next,
      languagePreferenceAutoSet: false,
    };
  },
  computed: {
    ...mapStores(useUiStore),
    hasNotificationDrawerPermission() {
      return (
        !this.user?.permissions?.includes('virtualassistant') ||
        ['active', 'probationary', 'inactive', 'ineligible', 'lost'].includes(
          this.virtualassistant?.state
        )
      );
    },
  },
  watch: {
    user() {
      if (this.user?.permissions.includes('admin')) {
        this.i18next.loadNamespaces('admin');
      }
      if (this.user) {
        this.i18next.loadNamespaces('portal');
      }
    },
    preferences() {
      if (
        this.preferences?.language &&
        this.checkFeatureFlag('language-preference')
      ) {
        this.i18next.changeLanguage(this.preferences.language).catch(() => {});
      }
      if (
        this.preferences &&
        !this.preferences.language &&
        this.checkFeatureFlag('language-preference') &&
        this.i18next.language !== 'en' &&
        !this.languagePreferenceAutoSet
      ) {
        // In case we try to set the language to a non-supported value,
        // avoid endlessly trying to update preferences
        this.languagePreferenceAutoSet = true;
        this.setPreferences({
          language: this.i18next.language,
        }).catch(() => {});
      }
    },
    async configsLoaded() {
      if (!this.checkFeatureFlag('language-preference')) {
        await this.i18next.changeLanguage('en').catch(() => {});
      }
    },
  },
  mounted() {
    this.$root.$loginDialog = this.$refs.loginDialog;
    provide('loginDialog', this.$refs.loginDialog);
    this.$root.$confirm = this.$refs.confirm;
    provide('confirm', this.$refs.confirm);
    this.$root.$snackbar = this.$refs.snackbar;
    provide('snackbar', this.$refs.snackbar);
    provide('$t', this.$t);
    provide('i18next', this.i18next);
    this.$root.$timer = this.$refs.timer;
    this.$root.$potentialMatchDialog = this.$refs.potentialMatchDialog;
    const sessionError = getSessionError();

    if (sessionError) {
      this.$refs.snackbar.error(sessionError, { persist: true });
    }
    this.i18next.on('languageChanged', (language) => {
      this.$vuetify.locale.current = language;
      this.$vuetify.date.locale = language === 'fr' ? 'fr-CA' : 'en-US';
      moment.locale(language);
      document.title = this.i18next.t(
        this.$route.meta.title || 'documentTitle.defaultTitle.title'
      );
    });
  },
};
</script>
