<template>
  <v-dialog v-model="showHubSpotFormDialog" persistent class="max-w-4xl">
    <div class="overflow-y-auto rounded-xl bg-white p-4 lg:p-8">
      <fa-icon
        icon="fa-solid fa-xmark"
        class="absolute right-2 top-1 p-4 text-xl"
        :aria-label="$t('portal:close.button')"
        @click.prevent="handleClose"
      />
      <div class="pb-2">
        <h1 class="text-2xl font-bold">
          {{ $t('contactUs.label') }}
        </h1>
      </div>
      <div id="hubspotForm">{{ $t('portal:loading.message') }}</div>
    </div>
  </v-dialog>
</template>
<script setup>
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import { useRouter, useRoute } from 'vue-router';
import { inject, watch } from 'vue';
import hubSpotFormInit from '@/utils/hubspot-forms';

const route = useRoute();
const router = useRouter();
const $t = inject('$t');
const i18next = inject('i18next');

const uiStore = useUiStore();
const { user } = storeToRefs(useUserStore());
const { showHubSpotFormDialog } = storeToRefs(uiStore);
const { configs } = storeToRefs(useConfigStore());

watch(showHubSpotFormDialog, async (newValue) => {
  if (newValue) {
    try {
      const hubspotConfig = configs.value.find(
        (config) => config.name === 'hubspot'
      );
      const userType = user.value.permissions.includes('virtualassistant')
        ? 'va'
        : 'client';
      if (hubspotConfig?.extra?.forms?.[i18next.language]?.[userType]) {
        router.replace({
          query: { ...route.query, email: user?.email },
        });
        await hubSpotFormInit().catch(() => {});
        window.hbspt.forms.create({
          region: 'na1',
          locale: i18next.language,
          portalId:
            hubspotConfig.extra.forms[i18next.language][userType].portalId,
          formId: hubspotConfig.extra.forms[i18next.language][userType].formId,
          target: '#hubspotForm',
          onFormReady: (form) => {
            const emailInput = form.querySelector('input[name="email"]');
            if (emailInput) {
              emailInput.value = user.value.email;
              const event = new Event('input', { bubbles: true });
              emailInput.dispatchEvent(event);
            }
          },
        });
      }
    } catch (error) {
      //
    }
  }
});

function handleClose() {
  showHubSpotFormDialog.value = false;
}
</script>
