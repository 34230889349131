import { getJSON, postJSON, putJSON } from '@/services/httpService';
import { formatShortDate } from '@/services/formattingService';
import { flattenObj } from '@/utils/flatten';

let vaCache = {};

export const getById = (id) => {
  return getJSON(`/virtualassistants/${id}`);
};

export const getVASelfById = () => {
  return getJSON(`/virtualassistants/self`);
};

export const getVARatePlans = (virtualassistant_uuid, project_uuid) =>
  getJSON(
    `/virtualassistants/${virtualassistant_uuid}/plans?${
      project_uuid ? 'project_uuid=' + project_uuid : ''
    }`
  );

export const getVAMatches = (query) => {
  const cacheKey = `matches-${query}`;

  if (Object.prototype.hasOwnProperty.call(vaCache, cacheKey)) {
    return new Promise((resolve) => {
      resolve(vaCache[cacheKey]);
    });
  } else {
    return getJSON(`/matching/vasearch?${query}`).then((result) => {
      vaCache[cacheKey] = result;
      return result;
    });
  }
};

export const getScoredCandidates = (body) => {
  return postJSON(`/matching/vascore/`, body);
};

export const getLearnRequestResult = (body) => {
  return postJSON(`/matching/valearn/`, body);
};

export const createVA = (virtualassistant) => {
  return postJSON(`/virtualassistants`, virtualassistant);
};

export const logVAProfileEdit = (virtualassistant_uuid, payload) => {
  return postJSON(
    `/virtualassistants/${virtualassistant_uuid}/profile/edit`,
    payload
  );
};

export const getAllVAPendingEdits = (virtualassistant_uuid) => {
  return getJSON(`/virtualassistants/${virtualassistant_uuid}/profile/review`);
};

export const getVAPendingEditsByType = (virtualassistant_uuid, types) => {
  return getJSON(
    `/virtualassistants/${virtualassistant_uuid}/profile/changes?types=${types}`
  );
};

export const getVAUnapprovedEditsByType = (virtualassistant_uuid, types) => {
  return getJSON(
    `/virtualassistants/${virtualassistant_uuid}/profile/unapproved?types=${types}`
  );
};

export const updateVAProfileEditDecisions = (
  virtualassistant_uuid,
  payload
) => {
  return putJSON(
    `/virtualassistants/${virtualassistant_uuid}/profile/update`,
    payload
  );
};

export const getVAConfig = () => getJSON(`/virtualassistants/config`);

export const saveVA = (virtualassistant) =>
  virtualassistant.id === -1
    ? postJSON(`/virtualassistants`, virtualassistant)
    : putJSON(`/virtualassistants/${virtualassistant.uuid}`, {
        ...virtualassistant,
        auditLogEntries: undefined,
      });

export const checkout = (body) => {
  return postJSON('/virtualassistants/checkout', body);
};

export const textSearch = (filters) =>
  getJSON(`/virtualassistants/text-search?${filters.join('&')}`);

export const getFullVAById = (id) =>
  getJSON(`/virtualassistants/${id}?full=true`);

export const getIntegrationReadiness = (filters) =>
  getJSON(`/virtualassistants/integration-readiness?${filters.join('&')}`);

export const onboard = (id) => {
  return postJSON(`/virtualassistants/${id}/onboard`);
};

export const getVATimeBilled = (virtualassistantId) => {
  return getJSON(
    `/virtualassistants/projects/${virtualassistantId}/timeBilled?current_date=${formatShortDate(
      new Date()
    )}`
  );
};

export const createVAInERP = (virtualassistant) => {
  return postJSON(`/virtualassistants/erp/`, virtualassistant);
};

export const checkVAavailability = (virtualAssistants) => {
  return postJSON(`/virtualassistants/availability`, virtualAssistants);
};

export const getVATaskLists = () => {
  return getJSON(`/virtualassistants/task/list`);
};

export const getVATasks = (filters) => {
  return getJSON(`/virtualassistants/task?${filters.join('&')}`);
};

export const logVACalendlyEvent = (vaUuid, body) =>
  postJSON(`/virtualassistants/${vaUuid}/calendly_event`, body);

export const getAuditLogs = (va_uuid, pagination) => {
  const query = new URLSearchParams(flattenObj({ pagination })).toString();

  return getJSON(`/virtualassistants/${va_uuid}/audit-logs?${query}`);
};
