<template>
  <v-dialog persistent :model-value="showLoginDialog" max-width="600">
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <div v-if="showLoginDialog" class="overflow-y-auto bg-white">
        <header class="p-4 shadow-md">
          <v-btn icon class="float-right" @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <h3 class="m-0 mx-16 text-center">{{ title }}</h3>
        </header>

        <template v-if="loginBrands.length > 0">
          <div class="flex justify-evenly pt-4">
            <img
              v-for="brand in loginBrands"
              :key="brand"
              class="h-full"
              :src="brandMap[brand]"
              height="128"
              width="128"
            />
          </div>
          <p class="p-5 pb-0">
            Welcome Back! You can log into VG OnDemand or the Virtual Gurus
            Talentplace with the same account. Just enter your Virtual Gurus
            account details below.
          </p>
        </template>
        <p v-else-if="stage === 'signin'" class="p-4 pb-0 text-center">
          {{ $t('welcomeBackExclamation.heading') }}
        </p>
        <p
          v-else-if="stage === 'email' && showMatchesMessage"
          class="p-4 pb-0 text-center"
        >
          {{ $t('signUp.showMatchesMessage.heading') }}
        </p>

        <div class="p-4">
          <template v-if="stage === 'verify'">
            <div class="p-4">
              {{
                $t('loginForm.showTokenSent.message', {
                  email: registerForm.email,
                })
              }}
            </div>
            <label class="text-sm">
              {{ $t('loginForm.resetToken.label') }}
              <v-text-field
                v-model="confirmationCode"
                :color="vgTeal"
                variant="outlined"
              >
              </v-text-field>
            </label>
          </template>
          <template v-else>
            <template v-if="stage === 'reset_password'">
              <div v-if="showEmailSentMessage" class="p-4">
                {{
                  $t('loginForm.showTokenSent.message', {
                    email: registerForm.email,
                  })
                }}
              </div>
              <label class="text-sm">
                {{ $t('loginForm.resetToken.label') }}
                <div class="flex gap-4">
                  <v-text-field
                    v-model="resetPasswordTokenInput"
                    data-cy="checkout.login.input.confirmation-code"
                    :color="vgTeal"
                    variant="outlined"
                  >
                  </v-text-field>
                </div>
              </label>
            </template>

            <label class="text-sm">
              {{ $t('loginForm.emailInput.label') }}
              <v-text-field
                v-if="stage === 'email'"
                v-model.trim="registerForm.email"
                :rules="emailRules"
                required
                variant="outlined"
                :color="vgTeal"
              ></v-text-field>
              <p v-else class="font-bold">
                {{ registerForm.email }}
              </p>
            </label>
            <label v-show="stage !== 'email'" class="text-sm">
              {{ $t('loginForm.passwordInput.label') }}
              <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
              <v-text-field
                v-model="registerForm.password"
                data-cy="checkout.login.input.password"
                autocomplete="new-password"
                :rules="stage !== 'signin' ? passwordRules : requiredRules"
                required
                variant="outlined"
                type="password"
                :color="vgTeal"
              ></v-text-field>
            </label>
            <template v-if="stage === 'reset_password'">
              <label class="text-sm">
                {{ $t('loginForm.confirmPasswordInput.label') }}
                <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
                <v-text-field
                  v-model="registerForm.confirm_password"
                  data-cy="checkout.login.input.confirm-password"
                  autocomplete="new-password"
                  :rules="confirmPasswordRules"
                  required
                  variant="outlined"
                  type="password"
                  :color="vgTeal"
                >
                </v-text-field>
              </label>
            </template>
          </template>
          <v-row
            v-if="stage === 'email' || stage === 'reset_password'"
            class="px-4"
          >
            <v-col class="p-0 pb-4 text-sm">
              {{ $t('signIn.privacyPolicy.message') }}

              <a
                :href="`${WEBSITE_URL}/legal/privacy-policy`"
                class="font-bold"
                target="_blank"
              >
                {{ $t('signIn.here.label') }}</a
              >
              {{ $t('signIn.privacyPolicy.reviewTerms.message') }}
              <a
                :href="`${WEBSITE_URL}/legal/terms-of-service`"
                class="font-bold"
                target="_blank"
              >
                {{ $t('signIn.here.label') }}</a
              >
            </v-col>
          </v-row>

          <vg-alert :model-value="alert" @onClose="alert = null"></vg-alert>
          <vg-btn
            :loading="loading"
            type="submit"
            data-cy="checkout.login.input.submit"
            block
            class="p-4"
          >
            {{ submitLabel }}
          </vg-btn>
          <div v-if="stage === 'signin'" class="pt-2 text-center">
            <vg-btn
              vg-type="tertiary"
              :loading="loading"
              @click.prevent.stop="forgotPassword()"
            >
              {{ $t('loginForm.forgotYourPassword.label') }}
            </vg-btn>
          </div>
          <div
            v-if="stage === 'signin' || stage === 'reset_password'"
            class="pt-2 text-center"
          >
            <a
              href="javascript:void(0)"
              class="text-sm"
              @click="
                stage = 'email';
                registerForm.email = '';
                registerForm.password = '';
                loginBrands = [];
              "
            >
              {{ $t('loginForm.anotherAccount.label') }}
            </a>
          </div>
          <div v-if="stage === 'reset_password'" class="pt-2 text-center">
            <a
              href="javascript:void(0)"
              class="text-sm"
              :loading="loading"
              @click.prevent.stop="forgotPassword(true)"
            >
              {{ $t('loginForm.sendNewToken.button') }}
            </a>
          </div>
        </div>
      </div>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  verify,
  login,
  checkUserExistByEmail,
  resetPassword,
  changePasswordByToken,
  hasSession,
} from '@/services/clientSessionService';
import { isValidEmail, validatePassword } from '@/services/validationService';
import { WEBSITE_URL } from '@/config';
import tailwind from 'tailwind.config';
import { mapStores, storeToRefs } from 'pinia';
import { useClientDashboardStore } from '@/stores/clientDashboard';
import { useUserStore } from '@/stores/user';

export default {
  name: 'LoginDialog',
  emits: ['updateSession'],
  data() {
    const clientDashboardStore = useClientDashboardStore();
    const { clientOrganizedProjects } = storeToRefs(clientDashboardStore);
    const { initClientProjects } = clientDashboardStore;

    return {
      clientOrganizedProjects,
      initClientProjects,
      showLoginDialog: false,
      showEmailSentMessage: true,
      showMatchesMessage: false,
      loading: false,
      valid: false,
      stage: 'email',
      registerForm: {
        email: '',
        password: '',
        confirm_password: '',
      },
      confirmationCode: '',
      resetPasswordToken: '',
      metadata: {},
      alert: null,
      loginBrands: [],
      requiredRules: [(v) => !!v || this.$t('required.error')],
      passwordRules: [
        (v) => !!v || this.$t('required.error'),
        validatePassword,
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t('required.error'),
        (v) =>
          v === this.registerForm.password ||
          this.$t('signIn.passwordMatch.error'),
      ],
      emailRules: [
        (v) => !!v || this.$t('required.error'),
        (v) => isValidEmail(v) || this.$t('signIn.validEmail.error'),
      ],
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
      WEBSITE_URL,
      brandMap: {
        virtualgurus: '/img/Virtual-Gurus-Assistants-Color-Logo-mobile.png',
        ondemand: '/img/VG OnDemand-logo-vertical.svg',
      },
    };
  },
  computed: {
    ...mapStores(useUserStore),
    title() {
      switch (this.stage) {
        case 'verify':
          return this.$t('signUp.confirmEmail.heading');
        case 'reset_password':
          return this.$t('signIn.enterCodeSetPass.message');
        case 'signin':
          return this.$t('signIn.button');
        case 'email':
          return this.$t('signIn.validEmail.heading');
        default:
          return '';
      }
    },
    submitLabel() {
      switch (this.stage) {
        case 'verify':
        case 'reset_password':
          return this.$t('confirm.button');
        case 'email':
          return this.$t('next.button');
        default:
          return this.$t('signIn.button');
      }
    },
    resetPasswordTokenInput: {
      get() {
        return this.resetPasswordToken;
      },
      set(value) {
        this.resetPasswordToken = value.toUpperCase();
      },
    },
  },
  watch: {
    metadata() {
      if (this.metadata.email) {
        this.registerForm.email = this.metadata.email;
      }
    },
    '$route.query.action'() {
      if (
        this.$route.query.action === 'checkout' &&
        this.$route.query.email &&
        !hasSession()
      ) {
        this.registerForm.email = this.$route.query.email;
      }
    },
  },
  methods: {
    open(payload) {
      this.onClose = payload?.onClose;
      this.stage = 'email';
      this.registerForm = payload?.registerForm ?? this.registerForm;
      this.loginBrands = [];
      this.alert = null;
      this.showLoginDialog = true;
      this.metadata = { ...this.metadata, ...payload?.metadata };
      this.showMatchesMessage = payload?.showMatchesMessage;
      if (payload?.autoNext) {
        this.submit();
      }
    },
    async forgotPassword(send_new_token) {
      if (!(this.registerForm.email && isValidEmail(this.registerForm.email))) {
        this.alert = {
          show: true,
          message: this.$t('signIn.validEmail.error'),
        };

        return;
      }

      this.loading = true;
      this.alert = null;

      try {
        await resetPassword(this.registerForm.email, send_new_token);

        this.registerForm.password = '';
        this.registerForm.confirm_password = '';
        this.resetPasswordToken = '';
        this.stage = 'reset_password';
        this.$refs.form.resetValidation();
      } catch (error) {
        this.alert = {
          show: true,
          message:
            error?.data?.message ?? this.$t('signIn.resettingPassword.error'),
        };

        return;
      } finally {
        this.loading = false;
      }

      if (send_new_token) {
        this.$root.$snackbar.message(
          this.$t('signIn.resetCodeSent.message', {
            email: this.registerForm.email,
          })
        );
      }
    },
    async submit() {
      this.alert = null;
      if (this.stage === 'email') {
        try {
          if (!isValidEmail(this.registerForm.email)) {
            this.alert = {
              show: true,
              message: this.$t('signIn.validEmail.error'),
            };
            return;
          }
          const result = await checkUserExistByEmail(this.registerForm.email);
          const matches = result.matches;
          this.stage = result.nextStage;

          if (this.stage === 'reset_password' || this.stage === 'verify') {
            return;
          } else if (this.stage === 'register') {
            this.userStore.setLoginEmail(this.registerForm.email);
            this.closeDialog();
          }

          if (
            matches.length === 1 &&
            matches[0].permissions.includes('askbetty_client') &&
            matches[0].permissions.includes('client')
          ) {
            this.loginBrands = ['ondemand', 'virtualgurus'];
          }
        } catch (error) {
          this.alert = {
            show: true,
            message:
              error?.data?.message ?? this.$t('signIn.checkingEmail.error'),
          };
          return;
        }
        return;
      }

      if (!(await this.$refs.form.validate()).valid) {
        return;
      }

      if (this.stage === 'signin') {
        this.handleLogin();
      } else if (this.stage === 'verify') {
        this.handleVerify();
      } else if (this.stage === 'reset_password') {
        this.handleSetNewPassword();
      }
    },
    async handleLogin() {
      this.loading = true;

      try {
        const user = await login(
          this.registerForm.email,
          this.registerForm.password
        );

        this.userStore.saveSession({
          token: user.token,
          user: {
            ...user,
            token: undefined,
          },
        });
        await this.userStore.initUser();
        if (user.permissions.includes('client')) {
          this.$gtm?.trackEvent({
            event: 'DL - Client Portal Login',
          });
        }
        this.showLoginDialog = false;
        if (
          this.metadata.postLoginURL &&
          (!this.metadata.email ||
            this.metadata.email === this.registerForm.email)
        ) {
          return this.$router.push(this.metadata.postLoginURL).catch(() => {});
        }
        if (this.onClose) {
          this.$emit('updatedSession');
          this.onClose(true);
        }
        if (
          user.permissions.includes('admin') &&
          this.$route.meta.redirectOnLogin
        ) {
          this.$router.push('/admin/home');
        } else if (
          user.permissions.includes('virtualassistant') &&
          this.$route.meta.redirectOnLogin
        ) {
          this.$router.push('/va/home');
        } else if (user.permissions.includes('client')) {
          await this.initClientProjects({ forceRefresh: true });
          if (this.$route.meta.redirectOnLogin) {
            this.$router.push('/client/home');
          }
        }
      } catch (error) {
        if (this.stage !== 'signin') {
          this.stage = 'signin';

          return;
        }
        this.alert = {
          show: true,
          message: error?.data?.message ?? this.$t('signIn.failed.error'),
        };

        return;
      } finally {
        this.loading = false;
      }
    },
    async handleVerify() {
      if (this.confirmationCode.length !== 6) {
        this.alert = {
          show: true,
          message: this.$t('signIn.resetToken6Chars.error'),
        };
      }
      this.loading = true;
      try {
        await verify(this.confirmationCode, this.registerForm.email);

        if (this.registerForm.password) {
          this.handleLogin();
        } else {
          this.stage = 'signin';
        }
      } catch (error) {
        this.alert = {
          show: true,
          message:
            error?.data?.message ?? this.$t('signUp.failedVerification.error'),
        };
        return;
      } finally {
        this.loading = false;
      }
    },
    async handleSetNewPassword() {
      try {
        await changePasswordByToken(
          this.registerForm.email,
          this.resetPasswordToken,
          this.registerForm.password
        );

        this.$root.$snackbar.message(this.$t('signIn.passwordChanged.message'));
        this.handleLogin();
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ?? this.$t('signIn.changingPassword.error')
        );
      } finally {
        this.loading = false;
      }
    },
    closeDialog() {
      if (this.onClose) {
        this.onClose(false);
      }
      this.showLoginDialog = false;
    },
  },
};
</script>
<style scoped>
* :deep(.v-messages__message) {
  @apply mb-5;
}
</style>
