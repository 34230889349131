<template>
  <v-btn
    v-bind="{ ...$props, size }"
    :disabled="false"
    :aria-disabled="disabled"
    variant="text"
    :ripple="!disabled"
    :color="currentColor.text"
    :class="[currentColor.class, vgFontSize]"
    class="focus-visible:outline-vgorange vgbtn rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
    @click="handleClick"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <slot />
  </v-btn>
</template>

<script>
import tailwind from 'tailwind.config';

const colorMap = {
  primary: {
    default: {
      text: '#fff',
      class: 'bg-vgocean',
    },
    hover: {
      text: '#fff',
      class: 'bg-vgmedturq',
    },
  },
  secondary: {
    default: {
      text: tailwind.theme.extend.colors.vgocean[700],
      class: 'bg-vgmedturq-300',
    },
    hover: {
      text: tailwind.theme.extend.colors.vgocean[500],
      class: 'bg-vgmedturq-200',
    },
  },
  tertiary: {
    default: {
      text: tailwind.theme.extend.colors.vgocean[500],
      class: 'bg-white border-solid border-2 border-vgocean',
    },
    hover: {
      text: tailwind.theme.extend.colors.vgocean[500],
      class: 'bg-vgmedturq-200 border-solid border-2 border-vgmedturq-200',
    },
  },
  primary_dark: {
    default: {
      text: '#fff',
      class: 'bg-vgnavy',
    },
    hover: {
      text: '#fff',
      class: 'bg-vgstone-800',
    },
  },
  disabled: {
    default: {
      text: tailwind.theme.extend.colors.vgstone[800],
      class: 'bg-vgsilver-300',
    },
    hover: {
      text: tailwind.theme.extend.colors.vgstone[800],
      class: 'bg-vgsilver-300',
    },
  },
};

export default {
  name: 'VgBtn',
  props: {
    vgType: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vgSize: {
      type: String,
      default: 'x-large',
    },
  },
  emits: ['click', 'click:disabled'],
  data() {
    return {
      hovering: false,
      colorMap,
    };
  },
  computed: {
    currentColor: function () {
      const type = this.disabled ? 'disabled' : this.vgType;
      return (this.colorMap[type] ?? this.colorMap.primary)[
        this.hovering ? 'hover' : 'default'
      ];
    },
    size() {
      return this.vgSize ?? 'x-large';
    },
    vgFontSize() {
      const size = {
        'x-small': 'text-sm',
        small: 'text-sm',
        large: 'text-sm',
        'x-large': 'text-base',
      }[this.vgSize];
      return size ? [size] : 'text-base';
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      } else {
        event.preventDefault();
        this.$emit('click:disabled', event);
      }
    },
  },
};
</script>
<style scoped>
.vgbtn {
  font-weight: 600;
}
</style>
