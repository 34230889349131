import {
  getClient,
  getClientAssistants,
  getClientTasks,
  getClientTaskLists,
} from '@/services/clientService';
import { hasSession, getUser } from '@/services/clientSessionService';
import { formatShortDate } from '@/services/formattingService';
import { invoiceSearch } from '@/services/invoicesService';
import { timeSearch } from '@/services/timeService';
import { getProjectReports } from '@/services/projectService';
import moment from 'moment';
import { defineStore } from 'pinia';

export const useClientDashboardStore = defineStore('clientDashboard', {
  state: () => ({
    assistants: [],
    projects: [],
    clientInvoices: {
      tableOptions: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [{ key: 'invoice_date', order: 'desc' }],
      },
      resetPagination: false,
      tableRows: [],
      rowCount: 0,
      filterForm: {},
    },
    clientInvoice: {},
    clientInvoiceAdjustments: [],
    clientTime: {
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [
          { key: 'task_date', order: 'desc' },
          { key: 'created_date', order: 'asc' },
        ],
      },
      resetPagination: false,
      tableRows: [],
      rowCount: 0,
      totalTime: 0,
      totalBillableTime: 0,
      totalNonBillableTime: 0,
      filterForm: {},
    },
    clientTasks: {
      tableRows: [],
      rowCount: 0,
      filterForm: {},
    },
    clientTaskLists: { lists: [], rowCount: 0 },
    clientTaskListSelections: { lists: [], rowCount: 0 },
    clientCompletedTasks: {
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [{ key: 'completed_date', order: 'desc' }],
        groupBy: [],
      },
      resetPagination: false,
      tableRows: [],
      rowCount: 0,
      filterForm: {},
    },
    clientReports: {
      tableOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [{ key: 'end_date', order: 'desc' }],
        groupBy: [],
      },
      resetPagination: false,
      tableRows: [],
      rowCount: 0,
      filterForm: {},
    },
  }),
  getters: {
    taskDateQuickPickOptions() {
      return [
        { title: this.$t('portal:time.overdue.heading'), value: 'overdue' },
        { title: this.$t('portal:time.today.label'), value: 'today' },
        { title: this.$t('portal:time.tomorrow.label'), value: 'tomorrow' },
        { title: this.$t('portal:time.endOfWeek.label'), value: 'end_week' },
      ];
    },
    completedDateQuickPickOptions() {
      return [
        { title: this.$t('portal:time.yesterday.label'), value: 'yesterday' },
        { title: this.$t('portal:time.today.label'), value: 'today' },
        { title: this.$t('portal:time.thisWeek.label'), value: 'this_week' },
      ];
    },
    clientOrganizedProjects() {
      const projectHeaders = [
        { title: this.$t('portal:name.label'), value: 'project_name' },
        { title: this.$t('portal:roles.heading'), value: 'roles' },
        {
          title: this.$t('portal:service.heading'),
          value: 'service',
          align: 'center',
        },
        {
          title: this.$t('portal:remainingHours.title'),
          value: 'hours_remaining',
          align: 'center',
        },
        {
          title: this.$t('portal:project.client.additionalHours.heading'),
          value: 'additional_hours',
          align: 'center',
        },
        {
          title: this.$t('portal:project.invoiceDay.heading'),
          value: 'invoice_day',
          align: 'center',
        },
        {
          title: this.$t('portal:date.start.label'),
          value: 'rate_start_date',
        },
        { title: this.$t('portal:date.end.label'), value: 'end_date' },
      ];
      const draftProjectHeaders = [
        { title: this.$t('portal:name.label'), value: 'project_name' },
      ];
      const now = formatShortDate(new Date());
      return this.projects.reduce(
        (accumulator, project) => {
          if (project.rate_start_date && project.rate_start_date > now) {
            accumulator.upcoming.projects.push(project);
          } else if (
            project.rate_start_date &&
            project.rate_start_date <= now &&
            (!project.end_date || project.end_date >= now)
          ) {
            accumulator.current.projects.push(project);
            accumulator.current.projects.sort(
              (a, b) => a.days_remaining - b.days_remaining
            );
          } else if (
            project.end_date &&
            project.end_date < now &&
            !accumulator.current.projects.find(
              (currentProject) => currentProject.uuid === project.uuid
            )
          ) {
            accumulator.past.projects.push(project);
          } else if (!project.rate_start_date) {
            accumulator.draft.projects.push(project);
          }
          return accumulator;
        },
        {
          upcoming: {
            label: this.$t('portal:subscription.upcoming.label'),
            projects: [],
            header: projectHeaders.filter(
              (header) =>
                header.value !== 'hours_remaining' &&
                header.value !== 'additional_hours'
            ),
          },
          current: {
            label: this.$t('portal:subscription.current.label'),
            projects: [],
            header: projectHeaders,
          },
          past: {
            label: this.$t('portal:subscription.past.label'),
            projects: [],
            header: projectHeaders.filter(
              (header) =>
                header.value !== 'hours_remaining' &&
                header.value !== 'additional_hours'
            ),
          },
          draft: {
            label: this.$t('portal:subscription.draft.label'),
            projects: [],
            header: draftProjectHeaders,
          },
        }
      );
    },
    clientProjectSelections() {
      const today = formatShortDate(new Date());
      return this.projects.map((project) => {
        const option = {
          project_uuid: project.uuid,
          project_name: project.project_name,
          service: project.service,
          state: null,
        };
        if (!project.rate_start_date) {
          // No rate plan
          option.type = 'draft';
        } else if (
          // Rate plan start is future
          project.rate_start_date > today
        ) {
          option.type = 'upcoming';
          // Project is past its end date
        } else if (project.end_date && project.end_date < today) {
          option.type = 'past';
        } else {
          // Everything else is current
          option.type = 'current';
        }
        return option;
      });
    },
  },
  actions: {
    async initClientProjects(payload) {
      if (this.projects.length === 0 || payload?.forceRefresh) {
        if (hasSession()) {
          const sessionUser = getUser();
          const result = await getClient(sessionUser.client_uuid).catch(() => ({
            projects: [],
          }));
          this.setClientProjects(result.projects);
          this.clientInvoiceAdjustments = result.invoiceAdjustments;
          this.assistants = await getClientAssistants(
            sessionUser.client_uuid
          ).catch(() => []);
        } else {
          location.href = '/';
        }
      }
    },
    async invoiceSearch(payload) {
      let caughtError;
      const searchResult = await invoiceSearch(
        [
          `pagination.limit=${this.clientInvoices.tableOptions.itemsPerPage}`,
          `pagination.offset=${
            (this.clientInvoices.tableOptions.page - 1) *
            this.clientInvoices.tableOptions.itemsPerPage
          }`,
          this.clientInvoices.tableOptions.sortBy
            .map((sortBy) => `pagination.sortBy[]=${sortBy.key}`)
            .join('&'),
          this.clientInvoices.tableOptions.sortBy
            .map((sortBy) => `pagination.sortDesc[]=${sortBy.order === 'desc'}`)
            .join('&'),
          ...(payload?.filters || []),
        ].filter((x) => x)
      ).catch((error) => {
        caughtError = error;
        return { invoices: [], rowCount: 0 };
      });
      this.updateClientInvoices({
        tableRows: searchResult.invoices,
      });
      if (searchResult.rowCount !== undefined) {
        this.updateClientInvoices({
          rowCount: searchResult.rowCount,
        });
      }
      if (caughtError) {
        throw caughtError;
      }
    },
    async timeSearch(payload) {
      let caughtError;
      const searchResult = await timeSearch(
        [
          `pagination.limit=${this.clientTime.tableOptions.itemsPerPage}`,
          `pagination.offset=${
            (this.clientTime.tableOptions.page - 1) *
            this.clientTime.tableOptions.itemsPerPage
          }`,
          this.clientTime.tableOptions.sortBy
            .map((sortBy) => `pagination.sortBy[]=${sortBy.key}`)
            .join('&'),
          this.clientTime.tableOptions.sortBy
            .map((sortBy) => `pagination.sortDesc[]=${sortBy.order === 'desc'}`)
            .join('&'),
          ...payload.filters,
        ].filter((x) => x)
      ).catch((error) => {
        caughtError = error;
        return { bills: [], rowCount: 0 };
      });
      this.updateClientTime({
        tableRows: searchResult.time,
      });
      if (searchResult.rowCount !== undefined) {
        this.updateClientTime({
          rowCount: searchResult.rowCount,
        });
      }
      if (caughtError) {
        throw caughtError;
      }
    },
    async taskSearch(payload) {
      let caughtError;
      const searchResult = await getClientTasks(
        [...payload.filters].filter((x) => x)
      ).catch((error) => {
        caughtError = error;
        return { tasks: [], rowCount: 0 };
      });

      // Update the main tasks collection
      this.updateClientTasks({
        tableRows: searchResult.tasks,
        rowCount: searchResult.rowCount,
      });

      // Update the lists collection
      this.updateClientTaskLists({
        tableRows: searchResult.tasks,
      });

      // Get all the task lists (even if there are no tasks on them)
      const listSearchResult = await getClientTaskLists().catch((error) => {
        caughtError = error;
        return { lists: [], rowCount: 0 };
      });

      this.clientTaskListSelections = listSearchResult;

      if (caughtError) {
        throw caughtError;
      }
    },
    async completedTaskSearch(payload) {
      let caughtError;
      const searchResult = await getClientTasks(
        [...payload.filters].filter((x) => x)
      ).catch((error) => {
        caughtError = error;
        return { tasks: [], rowCount: 0 };
      });

      // Update the main tasks collection
      this.updateClientCompletedTasks({
        tableRows: searchResult.tasks,
        rowCount: searchResult.rowCount,
      });

      if (caughtError) {
        throw caughtError;
      }
    },
    setTaskDateQuickPick(selected) {
      switch (selected) {
        case 'overdue':
          this.updateClientTasks({
            filterForm: {
              ...this.clientTasks.filterForm,
              due_from_date: undefined,
              due_to_date: moment().subtract(1, 'day').format('YYYY-MM-DD'),
            },
          });
          break;
        case 'today':
          this.updateClientTasks({
            filterForm: {
              ...this.clientTasks.filterForm,
              due_from_date: moment().format('YYYY-MM-DD'),
              due_to_date: moment().format('YYYY-MM-DD'),
            },
          });
          break;
        case 'tomorrow':
          this.updateClientTasks({
            filterForm: {
              ...this.clientTasks.filterForm,
              due_from_date: moment().add(1, 'day').format('YYYY-MM-DD'),
              due_to_date: moment().add(1, 'day').format('YYYY-MM-DD'),
            },
          });
          break;
        case 'end_week':
          this.updateClientTasks({
            filterForm: {
              ...this.clientTasks.filterForm,
              due_from_date: undefined,
              due_to_date: moment().isoWeekday(5).format('YYYY-MM-DD'),
            },
          });
          break;
        default:
          this.updateClientTasks({
            filterForm: {
              ...this.clientTasks.filterForm,
              due_from_date: undefined,
              due_to_date: undefined,
            },
          });
          break;
      }
    },
    setCompletedDateQuickPick(selected) {
      switch (selected) {
        case 'yesterday':
          this.updateClientCompletedTasks({
            filterForm: {
              ...this.clientCompletedTasks.filterForm,
              completed_from_date: moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD'),
              completed_to_date: moment()
                .subtract(1, 'day')
                .format('YYYY-MM-DD'),
            },
          });
          break;
        case 'today':
          this.updateClientCompletedTasks({
            filterForm: {
              ...this.clientCompletedTasks.filterForm,
              completed_from_date: moment().format('YYYY-MM-DD'),
              completed_to_date: moment().format('YYYY-MM-DD'),
            },
          });
          break;
        case 'this_week':
          this.updateClientCompletedTasks({
            filterForm: {
              ...this.clientCompletedTasks.filterForm,
              completed_from_date: moment().isoWeekday(1).format('YYYY-MM-DD'),
              completed_to_date: moment().isoWeekday(5).format('YYYY-MM-DD'),
            },
          });
          break;
        default:
          this.updateClientCompletedTasks({
            filterForm: {
              ...this.clientCompletedTasks.filterForm,
              completed_from_date: undefined,
              completed_to_date: undefined,
            },
          });
          break;
      }
    },
    async eowReportSearch(payload) {
      let caughtError;
      const searchResult = await getProjectReports([...payload.filters]).catch(
        (error) => {
          caughtError = error;
          return { reports: [], rowCount: 0 };
        }
      );

      this.updateClientReports({
        tableRows: searchResult.reports,
      });
      if (searchResult.rowCount) {
        this.updateClientReports({
          rowCount: searchResult.rowCount,
        });
      }

      if (caughtError) {
        throw caughtError;
      }
    },
    setClientProjects(payload) {
      this.projects = payload;
    },
    updateClientInvoices(payload) {
      if (
        payload.filterForm !== undefined &&
        this.clientInvoices.tableOptions.page !== 1
      ) {
        payload.resetPagination = true;
      }
      this.clientInvoices = Object.assign(this.clientInvoices, payload);
    },
    updateClientTime(payload) {
      if (
        payload.filterForm !== undefined &&
        this.clientTime.tableOptions.page !== 1
      ) {
        payload.resetPagination = true;
      }
      this.clientTime = Object.assign(this.clientTime, payload);
    },
    updateClientTasks(payload) {
      this.clientTasks = Object.assign(this.clientTasks, payload);
    },
    updateClientCompletedTasks(payload) {
      if (
        payload.filterForm !== undefined &&
        this.clientCompletedTasks.tableOptions.page !== 1
      ) {
        payload.resetPagination = true;
      }
      this.clientCompletedTasks = Object.assign(
        this.clientCompletedTasks,
        payload
      );
    },
    updateClientTaskLists() {
      const lists = Array.from(
        this.clientTasks.tableRows
          .reduce((lists, task) => {
            const listId =
              task.project_task_list_uuid || task.project_uuid + '_default';
            let list = lists.get(listId) || {
              uuid: listId,
              name: task.project_task_list_name,
              created_by: task.project_task_list_created_by,
              created_by_user_name: task.project_task_list_created_by_user_name,
              created_date: task.project_task_list_created_date,
              client_uuid: task.client_uuid,
              company: task.company,
              project_uuid: task.project_uuid,
              project_name: task.project_name,
              tasks: [],
            };
            list.tasks.push(task);
            lists.set(listId, list);
            return lists;
          }, new Map())
          .values()
      ).sort((a, b) => a.company.localeCompare(b.company));

      this.clientTaskLists = Object.assign(this.clientTaskLists, {
        lists,
        rowCount: lists.length,
      });
    },
    updateClientReports(payload) {
      this.clientReports = Object.assign(this.clientReports, payload);
    },
  },
});
