<template>
  <footer
    v-if="(!$route.meta.hasSession || inMaintenanceMode) && !removeFooter"
    class="b-page-footer"
  >
    <div class="FOOTER tiller-footer">
      <div class="b-frame">
        <div class="b-columns">
          <div class="b-column" data-width="1/6">
            <a class="logo" :href="`${WEBSITE_URL}/`"
              ><img
                class="b-img"
                style="aspect-ratio: 1/1; object-fit: contain"
                src="/img/Virtual-Gurus-Assistants-Color-Logo-mobile.png"
                loading="lazy"
                :alt="$t('virtualGurus.home.label')"
                width="1"
                height="1"
            /></a>
          </div>
          <div class="b-column" data-width="1/6">
            <div class="content">
              <p>
                {{ $t('vgFooter.vgDescription.first.text') }}
              </p>
              <p>
                {{ $t('vgFooter.vgDescription.second.text') }}
              </p>
            </div>
          </div>

          <div class="b-column" data-width="1/6">
            <div class="styled-list">
              <p class="normal-text">{{ $t('findGurus.heading') }}</p>
              <ul>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/executive-assistant`"
                    >{{ $t('roles.assistant.executive.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/administrative-assistant`"
                    >{{ $t('roles.assistant.administrative.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/bookkeeping-assistant`"
                    >{{ $t('roles.assistant.bookkeeping.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/marketing-assistant`"
                    >{{ $t('roles.assistant.marketing.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/social-media-assistant`"
                    >{{ $t('roles.assistant.socialMedia.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/sales-assistant`"
                    >{{ $t('roles.assistant.sales.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/real-estate-assistant`"
                    >{{ $t('roles.assistant.realEstate.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/legal-assistant`"
                    >{{ $t('roles.assistant.legal.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/find-gurus/medical-office-assistant`"
                    >{{ $t('roles.assistant.medicalOffice.label') }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="b-column" data-width="1/6">
            <div class="links">
              <ul>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/pricing`">{{
                    $t('vgHeader.menu.pricing.label')
                  }}</a>
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/become-a-guru`"
                    >{{ $t('becomeGuru.heading') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/success-stories`"
                    >{{ $t('vgHeader.menu.successStories.label') }}</a
                  >
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/events`">{{
                    $t('events.link')
                  }}</a>
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/company/about`"
                    >{{ $t('vgHeader.menu.about.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/company/our-mission`"
                    >{{ $t('vgHeader.menu.ourMission.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/company/careers`"
                  >
                    {{ $t('vgHeader.menu.careers.label') }}</a
                  >
                </li>
                <li>
                  <a
                    class="normal-text"
                    :href="`${WEBSITE_URL}/company/contact`"
                    >{{ $t('vgHeader.menu.contact.label') }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="b-column" data-width="1/6">
            <div class="styled-list mobile-hidden">
              <p class="normal-text">
                {{ $t('vgHeader.menu.resourceHub.label') }}
              </p>
              <ul>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/blog`">{{
                    $t('vgFooter.blog.link')
                  }}</a>
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/video`">{{
                    $t('vgFooter.video.link')
                  }}</a>
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/webinar`">
                    {{ $t('vgFooter.webinar.link') }}</a
                  >
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/ebook`">{{
                    $t('vgFooter.eBook.link')
                  }}</a>
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/whitepaper`">{{
                    $t('vgFooter.whitepaper.link')
                  }}</a>
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/podcast`">{{
                    $t('vgFooter.podcast.link')
                  }}</a>
                </li>
                <li>
                  <a class="normal-text" :href="`${WEBSITE_URL}/news`">
                    {{ $t('vgFooter.news.link') }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="b-column" data-width="1/6">
            <ul class="social-links">
              <li>
                <a
                  href="https://www.instagram.com/virtual_gurus/"
                  target="blank"
                  :aria-label="$t('social.instagram.label')"
                >
                  <svg
                    width="22"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.403 0A5.84 5.84 0 0 0 .572 5.835v8.333A5.84 5.84 0 0 0 6.407 20h8.333a5.84 5.84 0 0 0 5.832-5.835V5.832A5.84 5.84 0 0 0 14.737 0H6.403Zm10.002 3.333a.834.834 0 1 1 0 1.668.834.834 0 0 1 0-1.668ZM10.572 5c2.757 0 5 2.242 5 5s-2.243 5-5 5c-2.758 0-5-2.242-5-5s2.242-5 5-5Zm0 1.667a3.333 3.333 0 1 0 0 6.666 3.333 3.333 0 0 0 0-6.666Z"
                      fill="currentColor"
                      fill-rule="nonzero"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/yourvirtualgurus/"
                  target="_blank"
                  :aria-label="$t('social.facebook.label')"
                >
                  <svg
                    width="14"
                    height="24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M.959 12.782h2.63v10.83c0 .215.174.388.388.388h4.46a.387.387 0 0 0 .387-.387v-10.78h3.025a.387.387 0 0 0 .384-.342l.46-3.988a.387.387 0 0 0-.385-.431H8.824v-2.5c0-.753.406-1.135 1.206-1.135h2.278a.387.387 0 0 0 .387-.387V.39a.387.387 0 0 0-.387-.387H9.169A3.077 3.077 0 0 0 9.025 0c-.544 0-2.438.107-3.933 1.483-1.657 1.524-1.427 3.35-1.372 3.666v2.923H.96a.387.387 0 0 0-.387.387v3.936c0 .214.173.387.387.387Z"
                      fill="currentColor"
                      fill-rule="nonzero"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/virtual_gurus"
                  target="_blank"
                  :aria-label="$t('social.twitter.label')"
                >
                  <svg
                    width="22"
                    height="16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.384 1.894a8.063 8.063 0 0 1-2.32.636A4.046 4.046 0 0 0 19.84.295a8.1 8.1 0 0 1-2.566.981 4.04 4.04 0 0 0-6.883 3.683A11.467 11.467 0 0 1 2.067.74 4.03 4.03 0 0 0 1.52 2.77c0 1.4.712 2.638 1.796 3.361a4.031 4.031 0 0 1-1.83-.505v.051a4.04 4.04 0 0 0 3.24 3.96 4.04 4.04 0 0 1-1.824.07 4.044 4.044 0 0 0 3.774 2.806 8.102 8.102 0 0 1-5.981 1.673A11.436 11.436 0 0 0 6.887 16c7.43 0 11.492-6.155 11.492-11.493 0-.175-.004-.349-.011-.522a8.22 8.22 0 0 0 2.016-2.091Z"
                      fill="currentColor"
                      fill-rule="nonzero"
                    ></path>
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/3009747/"
                  target="_blank"
                  :aria-label="$t('social.linkedIn.label')"
                >
                  <svg
                    width="22"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="currentColor" fill-rule="nonzero">
                      <path
                        d="M5.05 6.426H1.026a.323.323 0 0 0-.323.324v12.927c0 .178.145.323.323.323H5.05a.323.323 0 0 0 .323-.323V6.75a.323.323 0 0 0-.323-.324ZM3.04 0A2.657 2.657 0 0 0 .384 2.652 2.657 2.657 0 0 0 3.04 5.306a2.656 2.656 0 0 0 2.653-2.654A2.656 2.656 0 0 0 3.04 0ZM15.285 6.105c-1.616 0-2.81.695-3.535 1.484v-.84a.323.323 0 0 0-.324-.323H7.573a.323.323 0 0 0-.323.324v12.927c0 .178.144.323.323.323h4.015a.323.323 0 0 0 .323-.323V13.28c0-2.155.586-2.995 2.088-2.995 1.636 0 1.766 1.346 1.766 3.106v6.285c0 .178.145.323.324.323h4.016a.323.323 0 0 0 .323-.323v-7.091c0-3.205-.61-6.481-5.143-6.481Z"
                      ></path>
                    </g>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="copyright normal-text">
          <a href="/terms-of-service"> {{ $t('termsOfService.label') }} </a> |

          <a href="/privacy-policy">{{ $t('privacyPolicy.label') }}</a> |
          <span class="copyright">© 2023 The Virtual Gurus</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { WEBSITE_URL, SHORT_ENV_PREFIX } from '@/config';
import { storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';

export default {
  name: 'VgFooter',
  data() {
    const { inMaintenanceMode } = storeToRefs(useConfigStore());

    return { inMaintenanceMode, WEBSITE_URL, SHORT_ENV_PREFIX };
  },
  computed: {
    removeFooter() {
      return this.$route.meta?.showDenseHeader;
    },
  },
};
</script>

<style scoped>
/* TILLER CSS */
.b-page-footer {
  position: relative;
}
.b-img {
  width: 100%;
}
.b-frame {
  max-width: 1200em;
  margin: 0 auto;
  padding: 100em 40em;
}

.FOOTER {
  background: #ffffff;
}

.FOOTER .b-frame {
  padding-top: 50em;
  padding-bottom: 50em;
}

.FOOTER .b-columns {
  flex-wrap: nowrap;
}

.FOOTER .b-columns > .b-column {
  flex: 0 1 auto;
  width: unset;
}
.FOOTER .b-columns > .b-column:nth-child(3) {
  flex: 1 1 90%;
}
.FOOTER .b-columns > .b-column:nth-child(4) {
  flex: 1 1 70%;
}
.FOOTER .b-columns > .b-column:nth-child(5) {
  flex: 1 1 70%;
}

@media screen and (max-width: 960px) {
  .FOOTER .b-frame {
    padding-top: 60em;
    padding-bottom: 40em;
  }
  .FOOTER .b-columns {
    flex-wrap: wrap;
    margin: -10em;
  }
  .FOOTER .b-columns > .b-column {
    padding: 10em;
  }

  .FOOTER .b-columns > .b-column:nth-child(1) {
    order: 1;
  }
  .FOOTER .b-columns > .b-column:nth-child(2) {
    order: 2;
  }
  .FOOTER .b-columns > .b-column:nth-child(3) {
    order: 3;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) {
    order: 5;
    padding-bottom: 60em;
  }
  .FOOTER .b-columns > .b-column:nth-child(5) {
    order: 4;
  }
  .FOOTER .b-columns > .b-column:nth-child(6) {
    order: 6;
  }

  .FOOTER .b-columns > .b-column:nth-child(2) {
    padding-bottom: 40em;
  }

  .FOOTER .b-columns > .b-column:nth-child(3),
  .FOOTER .b-columns > .b-column:nth-child(5) {
    flex: 1 0 auto;
    padding-bottom: 20em;
  }

  .FOOTER .b-columns > .b-column:nth-child(5) {
    padding: 10em 0;
  }

  .FOOTER .b-columns > .b-column:nth-child(1),
  .FOOTER .b-columns > .b-column:nth-child(6) {
    width: 100%;
  }

  .FOOTER .b-columns > .b-column:nth-child(4) .links ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 50em;
    grid-row-gap: 0px;
  }

  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li {
    margin: 5em;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(1) {
    grid-area: 1 / 1 / 2 / 2;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(2) {
    grid-area: 1 / 2 / 2 / 3;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(4) {
    grid-area: 2 / 2 / 3 / 3;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(5) {
    grid-area: 3 / 1 / 4 / 2;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(6) {
    grid-area: 3 / 2 / 4 / 3;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(7) {
    grid-area: 4 / 1 / 5 / 2;
  }
  .FOOTER .b-columns > .b-column:nth-child(4) .links ul li:nth-child(8) {
    grid-area: 4 / 2 / 5 / 3;
  }
  .b-frame {
    max-width: 480em;
  }
}

/* footer logo  */

.FOOTER .logo {
  display: block;
  width: 105em;
  height: 105em;
}
@media screen and (max-width: 960px) {
  .FOOTER .logo {
    margin: 0 auto;
  }
}

/* this affects all content but not the shrunken
/* footer content */

.FOOTER .content,
.FOOTER .normal-text {
  font-size: 14em;
}

/* footer list & styled list */

.FOOTER ul {
  list-style: none;
}

.FOOTER ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.FOOTER ul li:not(:first-child) {
  margin-top: 10em;
}
.FOOTER ul li a {
  line-height: 1.42;
}

.FOOTER .links ul li a {
  text-transform: uppercase;
}

.FOOTER .styled-list > p {
  color: #00263d;
  font-weight: 700;
  text-transform: uppercase;
}

.FOOTER .styled-list ul {
  padding: 0 0 0 20em;
  border-left: 2px solid #19d3c5;
}

.FOOTER .styled-list ul li {
  margin-top: 0;
}

.FOOTER .styled-list ul a {
  font-weight: 600;
  line-height: 1.78;
}

/* footer social links */

.FOOTER .social-links li:not(:first-child) {
  margin: 20em 0 0;
}

.FOOTER .social-links li a {
  width: 24em;
  height: 24em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #045d67;
  min-width: 28px;
}
.FOOTER .social-links li a:hover {
  color: #14978a;
}

@media screen and (max-width: 960px) {
  .FOOTER .social-links {
    display: flex;
    justify-content: center;
  }
  .FOOTER .social-links li:not(:first-child) {
    margin: 0 0 0 20em;
  }
}

/* COPYRIGHT */

.FOOTER .copyright {
  margin: 4em 0 0;
}

/* NAV */

.NAV {
  padding: 10em 0 50em;
}

.NAV nav input {
  display: none;
}

.NAV nav .a {
  cursor: default;
  position: relative;
}

.NAV nav .a svg {
  display: inline-block;
  margin: 0 0 0 20em;
  transform: translate(0, -1em);
  transition: 0.2s;
}

.NAV nav input:checked + .a svg {
  transform: translate(0, -1em) rotate(180deg);
}

.NAV nav .a::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 14em;
  height: 14em;
  margin: -2em 0 0 6em;
}

.NAV nav .a,
.NAV nav a:not(.b-button) {
  text-transform: uppercase;
  color: #045d67;
  position: relative;
  font-weight: 600;
}

.NAV nav a:not(.b-button):hover {
  color: #00263d;
  font-weight: 700;
}

.NAV .social {
  padding: 30em;
  margin: 30em 0 0;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 2px 2px 10px 0 rgba(20, 20, 43, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NAV .social .logo {
  width: 105em;
  height: 105em;
  margin: 0 0 18em;
}

.NAV .social .content {
  font-size: 15em;
  font-weight: 500;
  line-height: 20em;
  color: #13171a;
}

.NAV .social .social-links {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 30em 0;
  display: flex;
}

.NAV .social .social-links li {
  width: 24em;
  height: 24em;
  margin: 0 15em 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NAV .social .social-links li:first-child {
  margin-left: 0;
}
.NAV .social .social-links li:last-child {
  margin-right: 0;
}

/* tier 1 */

.NAV nav > ul {
  padding: 15em 0;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 2px 2px 10px 0 rgba(20, 20, 43, 0.1);
}

.NAV nav > ul > li {
  position: relative;
  padding: 15em 30em;
  font-size: 18em;
  line-height: 1.25;
}

.NAV nav > ul > li:last-child {
  border-bottom: none;
}
.NAV nav > ul > li > .b-button {
  width: 100%;
}

.NAV nav input:checked + label {
  color: #00263d;
  font-weight: 700;
}

/* tier 2 */

.NAV nav ul ul {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.25s;
  padding: 0 0 0 30em;
}

.NAV nav ul ul > li {
  font-size: 16em;
}
.NAV nav ul ul > li:first-child {
  margin-top: 20em;
}
.NAV nav ul ul > li:not(:last-child) {
  margin-bottom: 20em;
}

/* tier 2 open */

.NAV nav input:checked ~ ul {
  max-height: 240em;
}
.NAV nav input:checked ~ .a::after {
  transform: scaleY(-1);
}

/* LINKS */

.tiller-footer a {
  color: #00263d;
  background-color: transparent;
  text-decoration: none;
  transition: color 0.25s;
  font-weight: 700;
}

.tiller-footer a.orange {
  color: #cf3e00;
}
.tiller-footer a.orange:hover {
  color: #19d3c5;
}

.tiller-footer a:hover {
  color: #14978a;
}
a svg {
  fill: currentColor;
}

/* COLUMNS */

.b-columns {
  display: flex;
  flex-wrap: wrap;
  margin: -20em;
}

.b-column {
  padding: 20em;
  box-sizing: border-box;
  flex: 0 0 auto;
  align-items: stretch;
}

.b-column[data-width='1/6'] {
  width: 16.666666%;
}

@media screen and (min-width: 961px) {
  .b-columns.alt-tight {
    margin: -10em;
  }
  .b-columns.alt-tight .b-column {
    padding: 10em;
  }

  .b-columns.alt-loose {
    margin: -30em;
  }
  .b-columns.alt-loose .b-column {
    padding: 30em;
  }
}

/* EM SCALING */
.tiller-footer {
  font-size: 1px;
}

@media screen and (min-width: 1921px) {
  .tiller-footer {
    font-size: 1.25px;
  }
}
@media screen and (max-width: 1520px) {
  .tiller-footer {
    font-size: 0.9583333px;
  }
}
@media screen and (max-width: 1440px) {
  .tiller-footer {
    font-size: 0.9166666px;
  }
}
@media screen and (max-width: 1360px) {
  .tiller-footer {
    font-size: 0.875px;
  }
}
@media screen and (max-width: 1280px) {
  .tiller-footer {
    font-size: 0.8333333px;
  }
}
@media screen and (max-width: 1200px) {
  .tiller-footer {
    font-size: 0.7966666px;
  }
}
@media screen and (max-width: 1120px) {
  .tiller-footer {
    font-size: 0.75px;
  }
}
@media screen and (max-width: 960px) {
  .tiller-footer {
    font-size: 1px;
  }
}
</style>
