<template>
  <div>
    <v-dialog
      v-if="!isFirst"
      v-model="dialog"
      persistent
      width="600"
      @keydown.esc="cancel"
    >
      <v-card class="p-8 text-center">
        <v-form @submit.prevent="ok">
          <h3 class="text-3xl">Candidate added to your talent pool!</h3>

          <p class="text-vgstone">
            This candidate has been added as a possible match for the role(s)
            you’re looking to fill and will be contacted to confirm their
            interest. One of our Client Success team members will coordinate
            with you and the VA to ensure a successful match.
          </p>
          <div class="flex justify-center p-4">
            <vg-btn type="submit">Ok</vg-btn>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
    <div v-else>
      <v-form
        class="centered absolute z-[500] rounded-lg bg-white px-16 py-5 text-center drop-shadow-lg"
        @submit.prevent="ok"
      >
        <div class="flex justify-center">
          <fa-icon
            icon="fa-regular fa-circle-check"
            class="text-vgocean mr-3 self-start object-contain pt-0.5 text-2xl"
          />
          <h3 class="m-0 text-3xl font-bold">Success!</h3>
        </div>
        <p class="text-vgocean mt-1 text-base font-bold">
          Candidate added to your match list.
        </p>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PotentialMatchDialog',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    isFirst: false,
  }),
  methods: {
    open(isFirst) {
      this.dialog = true;
      this.isFirst = isFirst;
      if (isFirst) {
        setTimeout(() => {
          this.ok();
        }, 2000);
      }

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      this.resolve(true);
      this.dialog = false;
      this.isFirst = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.centered {
  position: fixed;
  top: 15%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
</style>
