<template>
  <div
    v-if="uiStore.showNewVersionAvailable"
    class="border-vgorange fixed bottom-4 right-4 z-[101] flex flex-col gap-2 rounded-md border border-solid bg-white p-4 shadow-lg"
  >
    <p>A new version is available.</p>
    <vg-btn class="self-end" @click="handleReload">Reload</vg-btn>
  </div>
</template>
<script setup>
import { useUiStore } from '@/stores/ui';

const uiStore = useUiStore();

function handleReload() {
  window.location.reload(true);
}
</script>
