<template>
  <div class="p-5">
    <h1 class="mb-2 break-words text-3xl font-semibold">
      {{ assistant.display_name }}
    </h1>
    <p v-if="assistant.preferred_pronouns" class="mb-3 text-sm font-semibold">
      ({{ assistant.preferred_pronouns }})
    </p>
    <!-- <div
        v-if="assistant.va_rating"
        class="flex flex-col justify-self-end items-center place-self-end mb-2"
      >
        <div class="flex flex-row items-center mb-0">
          <p class="mb-0 font-bold text-vgocean">GURU RATING</p>
          <v-tooltip top>
            <template #activator="{ props }">
              <v-btn icon v-bind="props">
                <v-icon :color="vgOrange"> mdi-alert-circle-outline </v-icon>
              </v-btn>
            </template>
            <span>VG rate info</span>
          </v-tooltip>
        </div>
        <v-rating
          v-model="rating"
          class="self-start -mt-2"
          background-color="grey lighten-3"
          :color="vgOrange"
          half-increments
          density="compact"
          small
          readonly
        ></v-rating>
      </div> -->
    <p class="whitespace-pre-line text-sm">{{ assistant.bio }}</p>
  </div>
</template>

<script>
import tailwind from 'tailwind.config';

export default {
  name: 'Bio',
  props: {
    assistant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      vgOrange: tailwind.theme.extend.colors.vgorange[500],
    };
  },
  computed: {
    rating() {
      return this.assistant.va_rating ? this.assistant.va_rating * 5 : null;
    },
  },
};
</script>
