let initialized = false;
let resolveInitPromise;
let rejectInitPromise;
const initPromise = new Promise((resolve, reject) => {
  resolveInitPromise = resolve;
  rejectInitPromise = reject;
});

export default function init() {
  if (initialized) return initPromise;

  initialized = true;

  const script = document.createElement('script');

  script.onload = () => {
    resolveInitPromise(window._hsq);
  };
  script.async = true;
  script.id = 'hubspot-forms-script';
  script.src = 'https://js.hsforms.net/forms/v2.js';
  script.onerror = rejectInitPromise;
  document.head.appendChild(script);

  return initPromise;
}
