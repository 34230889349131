<template>
  <v-dialog
    v-model="showFeedbackDialog"
    class="max-w-4xl"
    @click:outside="handleClose()"
  >
    <div class="overflow-y-auto rounded-xl bg-white p-4 lg:p-8">
      <div class="pb-2">
        <h1 class="text-2xl font-bold">
          🚀
          {{
            $t('portal:feedbackDialog.heading', {
              name: userStore.user.known_as || userStore.user.full_name,
            })
          }}
        </h1>
      </div>
      <div class="mb-2">
        <h2 class="mb-2 text-xl font-semibold">
          {{ $t('portal:feedbackDialog.subheading') }}
        </h2>
        <v-form ref="form" v-model="status.valid">
          <label class="mt-6 flex flex-col text-sm">
            <span class="text-base font-semibold">
              {{ $t('portal:feedbackDialog.rating.label') }}
            </span>
            <v-rating
              v-model="feedback.rating"
              :color="vgMedTurq"
              length="5"
              size="30"
            />
          </label>

          <label class="mt-6 flex flex-col text-sm">
            <span class="text-base font-semibold">
              {{ $t('portal:feedbackDialog.message.label') }}
            </span>
            <v-textarea
              v-model.trim="feedback.message"
              :color="vgMedTurq"
              class="text-base font-semibold"
              rounded="lg"
              rows="10"
              no-resize
              variant="outlined"
              :rules="requiredRules"
              :aria-label="$t('portal:feedback.heading')"
              :placeholder="$t('portal:feedbackDialog.message.placeholder')"
            />
          </label>
        </v-form>
      </div>
      <div
        class="flex-inline flex items-center justify-center gap-3 text-sm tracking-wider md:justify-end"
      >
        <vg-btn
          vg-type="secondary"
          class="h-10 self-start rounded-full px-6 uppercase tracking-wider text-gray-600"
          :disabled="status.saving"
          @click="handleClose()"
        >
          {{ $t('portal:cancel.button') }}
        </vg-btn>
        <vg-btn
          :disabled="status.saving"
          :loading="status.saving"
          @click="submit()"
        >
          {{ $t('portal:feedbackDialog.submit.button') }}
        </vg-btn>
      </div>
    </div>
  </v-dialog>
</template>
<script setup>
import tailwind from 'tailwind.config';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useUiStore } from '@/stores/ui';
import { inject, reactive, watch, ref } from 'vue';
import { saveTalentplaceFeedback } from '@/services/feedbackService';

const snackbar = inject('snackbar');
const $t = inject('$t');

const uiStore = useUiStore();
const userStore = useUserStore();
const { showFeedbackDialog } = storeToRefs(uiStore);

const form = ref();
const feedback = reactive({ message: '', rating: null });
const vgMedTurq = tailwind.theme.extend.colors.vgmedturq[500];
const status = reactive({
  error: null,
  saving: false,
  valid: false,
});
const requiredRules = [(v) => !!v || $t('required.error')];

watch(status, (newValue) => {
  if (newValue?.error?.length > 0) {
    snackbar.error(newValue.error);
  }
});

async function submit() {
  status.error = null;

  if (!(await form.value.validate()).valid) {
    status.error = $t('portal:feedbackDialog.valid.error');

    return;
  }

  try {
    status.saving = true;
    await saveTalentplaceFeedback(feedback);
    snackbar.message($t('portal:feedbackDialog.save.success'));
    handleClose();
    feedback.message = '';
    feedback.rating = null;
  } catch (error) {
    status.error = $t('portal:feedbackDialog.save.error');
  }
}
function handleClose() {
  status.error = '';
  status.saving = false;
  showFeedbackDialog.value = false;
}
</script>
