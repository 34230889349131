<template>
  <header
    class="bg-vgsilver-100 relative top-0 w-full justify-center shadow-md"
  >
    <div
      v-if="
        !hasGeneralSession ||
        $route.meta.hideSidebar ||
        uiStore.tabletOrSmaller ||
        inMaintenanceMode
      "
      class="flex items-center justify-between gap-4"
    >
      <button
        v-if="hasGeneralSession && !$route.meta.hideSidebar"
        size="large"
        class="ml-2 lg:hidden"
        :aria-label="$t('menu.label')"
        @click.stop="uiStore.setNavDrawerOpen(true)"
      >
        <fa-icon icon="fa-solid fa-bars" class="ml-4 text-2xl" />
      </button>
      <router-link
        :to="homeLink"
        :aria-current="$route.path === homeLink"
        class="mx-4 mt-0 min-w-fit py-2"
        :aria-label="$t('talentplace.title')"
      >
        <img
          src="/img/Virtual-Gurus-Assistants-Color-Logo-p-500.png"
          class="hidden h-20 p-3 xl:block"
          alt />
        <img
          src="/img/Virtual-Gurus-Assistants-Color-Logo-mobile.png"
          class="mt-2 h-16 xl:hidden"
          alt
      /></router-link>
      <div id="headerTeleportTarget"></div>
      <div>
        <div
          v-if="!hasGeneralSession && !showDenseView"
          class="hidden uppercase lg:flex"
        >
          <a
            :href="`${WEBSITE_URL}/find-gurus`"
            class="nav__page-link new-header-style"
          >
            <p>{{ $t('findGurus.heading') }}</p>
            <div class="nav__pointer nav_pointer--modified"></div>
          </a>
          <a
            :href="`${WEBSITE_URL}/pricing`"
            class="nav__page-link new-header-style"
          >
            <p>{{ $t('vgHeader.menu.pricing.label') }}</p>
            <div class="nav__pointer nav_pointer--modified"></div>
          </a>
          <a
            :href="`${WEBSITE_URL}/success-stories`"
            class="nav__page-link new-header-style"
          >
            <p>{{ $t('vgHeader.menu.successStories.label') }}</p>
            <div class="nav__pointer nav_pointer--modified"></div>
          </a>
          <a class="nav__page-link new-header-style cusror-pointer">
            <v-menu open-on-hover open-delay="0" offset="-20">
              <template #activator="{ props }">
                <p v-bind="props">{{ $t('company.label') }}</p>
                <fa-icon icon="fa-solid fa-caret-down" class="-mt-1 pl-2" />
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in companyItems"
                  :key="index"
                  link
                  :href="item.value"
                >
                  <v-list-item-title class="nav__page-link new-header-style">
                    {{ item.title() }}
                    <div class="nav__pointer nav_pointer--modified"></div
                  ></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </a>
          <a
            :href="`${WEBSITE_URL}/become-a-guru`"
            class="nav__page-link new-header-style"
          >
            <p>{{ $t('becomeGuru.heading') }}</p>
            <div class="nav__pointer nav_pointer--modified"></div>
          </a>
          <router-link to="/" class="nav__page-link new-header-style">
            <p>{{ $t('becomeGuru.heading') }}</p>
            <div class="nav__pointer nav_pointer--modified"></div
          ></router-link>
        </div>
        <v-btn
          v-if="
            sessionUserHasPermission('virtualassistant') && vastate === 'active'
          "
          class="lg:hidden"
          variant="text"
          icon
          :aria-label="$t('timer.label')"
          @click.stop="
            uiStore.setNotificationDrawerPanels(0);
            uiStore.setNotificationDrawerOpen(!uiStore.notificationDrawerOpen);
          "
        >
          <fa-icon
            class="text-2xl"
            :class="{
              'animate-deep-pulse text-vgorange text-2xl': activeTimer,
            }"
            icon="fa-regular fa-timer"
          />
        </v-btn>
        <v-btn
          v-if="
            hasGeneralSession &&
            !$route.meta.hideSidebar &&
            hasNotificationDrawerPermission
          "
          variant="text"
          icon
          class="mr-4 lg:hidden"
          :aria-label="$t('notificationDrawer.showNotifications.label')"
          @click.stop="
            uiStore.setNotificationDrawerOpen(!uiStore.notificationDrawerOpen);
            sessionUserHasPermission('virtualassistant')
              ? uiStore.setNotificationDrawerPanels(1)
              : uiStore.setNotificationDrawerPanels(0);
          "
        >
          <v-badge
            :content="notificationCount"
            :model-value="notificationCount > 0"
            :color="vgOrange"
            offset-x="10"
          >
            <fa-icon icon="fa-regular fa-bell" class="mx-3 text-2xl" />
          </v-badge>
        </v-btn>
        <div id="menu-container">
          <v-menu
            min-width="180"
            :content-class="
              hasGeneralSession || showDenseView ? 'inline' : 'inline'
            "
            attach="#menu-container"
          >
            <template #activator="{ props }">
              <div class="justify-self-end uppercase">
                <v-btn
                  v-if="hasGeneralSession"
                  variant="text"
                  icon
                  size="small"
                  class="bg-vgocean-100 mr-2 w-[10px]"
                  :class="
                    $route.meta.hideSidebar ? 'inline' : 'hidden lg:inline'
                  "
                  :aria-label="
                    $t('vgHeader.account.dropdownMenu.label', {
                      name: displayName,
                    })
                  "
                  v-bind="props"
                  @click="handleMenuClick"
                >
                  <v-icon>mdi-account-outline</v-icon>
                </v-btn>
                <template v-else>
                  <a
                    :class="{ 'lg:inline-block': !showDenseView }"
                    class="book-consult-button hidden"
                    href="https://thevirtualgurus.com/book-a-consult"
                    ><strong> {{ $t('bookConsult.label') }} </strong>
                  </a>
                  <v-btn
                    :class="{ 'lg:hidden': !showDenseView }"
                    variant="text"
                    icon
                    size="large"
                    class="inline"
                    :aria-label="$t('menu.label')"
                    v-bind="props"
                  >
                    <v-icon>mdi-menu</v-icon>
                  </v-btn>
                </template>
              </div>
            </template>
            <v-list v-if="hasGeneralSession">
              <v-list-item>
                {{ displayName }}
              </v-list-item>
              <v-list-item v-if="$route.meta.hideSidebar" :to="homeLink">
                <span class="text-vgorange">{{ homeLinkLabel }}</span>
              </v-list-item>
              <hr aria-hidden="true" />
              <v-list-item @click="logout">
                <v-list-item-title class="pl-2">
                  <a>
                    <fa-icon
                      icon="fa-solid fa-arrow-right-from-bracket"
                      class="text-vgstone mr-2 text-lg"
                    />{{ $t('logout.link') }}</a
                  >
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="user.permissions.includes('admin')"
                to="/profile/password"
                prepend-icon="mdi-lock"
              >
                <v-list-item-title class="font-normal text-black">
                  {{ $t('changePassword.link') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-list v-else>
              <v-list-item>
                <a
                  :href="`${WEBSITE_URL}/find-gurus`"
                  class="nav__page-link new-header-style"
                >
                  <p>{{ $t('findGurus.heading') }}</p>
                  <div class="nav__pointer nav_pointer--modified"></div>
                </a>
              </v-list-item>
              <v-list-item>
                <a
                  :href="`${WEBSITE_URL}/pricing`"
                  class="nav__page-link new-header-style"
                >
                  <p>{{ $t('vgHeader.menu.pricing.label') }}</p>
                  <div class="nav__pointer nav_pointer--modified"></div>
                </a>
              </v-list-item>
              <v-list-item>
                <a
                  :href="`${WEBSITE_URL}/success-stories`"
                  class="nav__page-link new-header-style"
                >
                  <p>{{ $t('vgHeader.menu.successStories.label') }}</p>
                  <div class="nav__pointer nav_pointer--modified"></div>
                </a>
              </v-list-item>
              <v-list-item>
                <a
                  class="nav__page-link new-header-style flex flex-col"
                  href=""
                  @click.stop.prevent="
                    showMobileCompanyItems = !showMobileCompanyItems
                  "
                >
                  <div class="flex items-baseline">
                    <p>{{ $t('company.label') }}</p>
                    <fa-icon
                      :icon="
                        showMobileCompanyItems
                          ? 'fa-regular fa-chevron-up'
                          : 'fa-regular fa-chevron-down'
                      "
                      class="-mt-1 pl-2"
                    />
                  </div>
                  <div v-if="showMobileCompanyItems" class="flex flex-col">
                    <a
                      v-for="(item, index) in companyItems"
                      :key="index"
                      class="nav__page-link new-header-style uppercase"
                      :href="item.value"
                      @click.stop=""
                    >
                      {{ item.title() }}
                    </a>
                  </div>
                </a>
              </v-list-item>
              <v-list-item>
                <a
                  :href="`${WEBSITE_URL}/become-a-guru`"
                  class="nav__page-link new-header-style"
                >
                  <p>{{ $t('becomeGuru.heading') }}</p>
                  <div class="nav__pointer nav_pointer--modified"></div>
                </a>
              </v-list-item>
              <v-list-item>
                <router-link to="/" class="nav__page-link new-header-style">
                  <p>{{ $t('signIn.button') }}</p>
                  <div class="nav__pointer nav_pointer--modified"></div
                ></router-link>
              </v-list-item>
              <div class="p-4">
                <a
                  class="book-consult-button"
                  href="https://thevirtualgurus.com/book-a-consult"
                  ><strong>{{ $t('bookConsult.label') }}</strong>
                </a>
              </div>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div ref="menuAnnouncement" class="sr-only" aria-live="polite"></div>
    </div>
  </header>
</template>

<script>
import { nextTick } from 'vue';
import { WEBSITE_URL, TALENTPLACE_URL } from '@/config';
import { mapStores, storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useNotificationsStore } from '@/stores/notifications';
import { useTimersStore } from '@/stores/timers';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import tailwind from 'tailwind.config';
import { sessionUserHasPermission } from '@/services/clientSessionService';
import { useTranslation } from 'i18next-vue';

export default {
  name: 'VgHeader',
  data() {
    const { user, virtualassistant, homeLink, homeLinkLabel } = storeToRefs(
      useUserStore()
    );
    const { inMaintenanceMode, checkFeatureFlag } = storeToRefs(
      useConfigStore()
    );
    const { notificationCount } = storeToRefs(useNotificationsStore());
    const { activeTimer } = storeToRefs(useTimersStore());
    const { t } = useTranslation();

    return {
      activeTimer,
      user,
      virtualassistant,
      homeLink,
      homeLinkLabel,
      inMaintenanceMode,
      checkFeatureFlag,
      notificationCount,
      showMobileCompanyItems: false,
      WEBSITE_URL,
      vgOrange: tailwind.theme.extend.colors.vgorange[500],
      vgOcean: tailwind.theme.extend.colors.vgocean.DEFAULT,
      vgMedTurq: tailwind.theme.extend.colors.vgmedturq[500],
      TALENTPLACE_URL,
      companyItems: [
        {
          title: () => t('vgHeader.menu.about.label'),
          value: `${WEBSITE_URL}/company/about`,
        },
        {
          title: () => t('vgHeader.menu.ourMission.label'),
          value: `${WEBSITE_URL}/company/our-mission`,
        },
        {
          title: () => t('vgHeader.menu.careers.label'),
          value: `${WEBSITE_URL}/company/careers`,
        },
        {
          title: () => t('vgHeader.menu.contact.label'),
          value: `${WEBSITE_URL}/company/contact`,
        },
        {
          title: () => t('vgHeader.menu.resourceHub.label'),
          value: `${WEBSITE_URL}/company/resource-center`,
        },
      ],
    };
  },
  computed: {
    ...mapStores(useUiStore, useUserStore),
    vastate() {
      return this.virtualassistant?.state;
    },
    hasGeneralSession() {
      return !!this.user;
    },
    showDenseView() {
      return this.$route.meta?.showDenseHeader;
    },
    hasNotificationDrawerPermission() {
      return (
        (this.user?.permissions?.includes('virtualassistant') &&
          ['active', 'probationary', 'inactive', 'ineligible', 'lost'].includes(
            this.vastate
          )) ||
        this.user?.permissions?.includes('admin') ||
        this.user?.permissions?.includes('client')
      );
    },
    displayName: function () {
      return this.user?.full_name ?? '';
    },
  },
  methods: {
    sessionUserHasPermission,
    signIn() {
      if (this.user) return;
      this.$root.loginDialog.open({
        metadata: {
          rids: this.$route.query.rids,
          hours: this.$route.query.hours || '',
          assistant_id: this.$route.params.id || '',
        },
      });
    },
    logout() {
      this.userStore.endSession();
      location.href = '/';
    },
    handleMenuClick() {
      nextTick(() => {
        const announcement = this.$refs.menuAnnouncement;

        // Announce the menu expansion for screen readers
        if (announcement) {
          announcement.textContent = this.$t(
            'vgHeader.accountDropdown.message'
          );
        }

        setTimeout(() => {
          announcement.textContent = '';
        }, 1000);
      });
    },
  },
};
</script>
<style scoped>
.nav__page-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0.3em;
  padding: 0.6em 0.8em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: box-shadow 200ms ease, color 200ms ease,
    background-color 200ms ease;
  transition: box-shadow 200ms ease, color 200ms ease,
    background-color 200ms ease;
  color: #00263e;
  font-weight: 700;
  text-transform: uppercase;
}
.nav__page-link p {
  margin-bottom: 0;
}
.nav__page-link:hover {
  color: #cf3e00;
}
.nav__pointer {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 0%;
  height: 2px;
  border-radius: 2em;
  background-color: #cf3e00;
  transition: width 0.2s ease-in-out;
}
.nav__page-link:hover .nav__pointer {
  width: 100%;
}
.nav_pointer--modified {
  background-color: #045d67;
  transition: width 0.4s ease-in-out;
}
.new-header-style {
  align-items: baseline;
  font-weight: 600;
  font-size: 1em;
  color: #045d67;
}
.new-header-style:hover {
  color: #045d67;
}
.book-consult-button {
  border-radius: 0 1rem 1rem 1rem;
  color: #ffff;
  background: #045d67;
  box-sizing: border-box;
  padding: 1rem 2rem 1rem 2rem;
  box-shadow: 2px 1px 4px 0 rgb(19 23 26 / 15%);
  margin-right: 1rem;
}
.book-consult-button:hover {
  color: #00263d;
  background: #b3ded7;
  transition: color 0.25s, background 0.25s, box-shadow 0.25s;
}
@media screen and (max-width: 1520px) {
  .new-header-style {
    font-size: 0.89em;
  }
}
@media screen and (max-width: 1440px) {
  .new-header-style {
    font-size: 0.83em;
  }
}
@media screen and (max-width: 1360px) {
  .new-header-style {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 1280px) {
  .new-header-style {
    font-size: 0.75em;
  }
}
@media screen and (max-width: 1200px) {
  .new-header-style {
    font-size: 0.7em;
  }
}
@media screen and (max-width: 1120px) {
  .new-header-style {
    font-size: 0.65em;
  }
}
@media screen and (max-width: 1023px) {
  .new-header-style {
    font-size: 1em;
  }
}
* :deep(.v-list-item .v-list-item__spacer) {
  @apply hidden;
}
* :deep(.v-list-item .v-list-item__prepend) {
  @apply mr-2;
}
</style>
