<template>
  <div :class="{ 'rounded-t-sm shadow-sm': mobile }">
    <div class="text-center" :class="{ 'mx-5  mt-5 flex': mobile }">
      <v-avatar size="112" class="my-2 self-center">
        <v-img :src="getAvatar(assistant)" lazy-src="/img/profile.png"> </v-img>
        <template #placeholder>
          <v-row fill-height align-center ma-0>
            <v-progress-circular
              indeterminate
              color="grey-lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-avatar>
      <h3 v-if="mobile" class="ml-3 break-all">
        {{ assistant.display_name }}
      </h3>
    </div>
  </div>
</template>

<script>
import tailwind from 'tailwind.config';
import {
  getUser,
  sessionUserHasPermission,
} from '@/services/clientSessionService';
import { API_URL } from '@/config';
import { storeToRefs } from 'pinia';
import { useClientDashboardStore } from '@/stores/clientDashboard';
import { useUserStore } from '@/stores/user';

export default {
  name: 'CurrentAvailability',
  props: {
    assistant: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    mobile: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    const { user } = storeToRefs(useUserStore());
    const { initClientProjects } = useClientDashboardStore();

    return {
      user,
      initClientProjects,
      API_URL,
      vgOcean: tailwind.theme.extend.colors.vgocean[500],
    };
  },
  computed: {
    currentAvailability() {
      return this.assistant.available_hours_per_month -
        this.assistant.monthly_hours_committed >
        0
        ? this.assistant.available_hours_per_month -
            this.assistant.monthly_hours_committed
        : 0;
    },
  },
  methods: {
    sessionUserHasPermission,
    getUser,
    getAvatar(assistant) {
      return `${API_URL}/file/avatar/${assistant ? assistant.avatar_id : -1}`;
    },
  },
};
</script>
