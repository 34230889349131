export function getAddressText(address) {
  const addressLabel =
    (address.address_line_1 ? address.address_line_1.trim() + '\n' : '') +
    (address.address_line_2 ? address.address_line_2.trim() + '\n' : '') +
    (address.city ? address.city.trim() + ', ' : '') +
    (address.state_prov ? address.state_prov + ', ' : ' ') +
    (address.zipcode ? address.zipcode + '\n' : ' ') +
    (address.country ? address.country : '');

  return addressLabel?.trim().length > 0 ? newlineToBreak(addressLabel) : '-';
}

export function newlineToBreak(value) {
  return value && typeof value === 'string'
    ? value.replace(/\n/g, '<br>')
    : value;
}
