/******************************************************************************
 * Imports
 ******************************************************************************/

import { getJSON } from '@/services/httpService';
import { formatShortDate } from '@/services/formattingService';

/******************************************************************************
 * Definitions
 ******************************************************************************/

const PAYROLL_DAYS = [1, 16];

/******************************************************************************
 * Exports
 ******************************************************************************/

export const getAdjustmentEffectTypes = () => [
  { title: 'Working Hours', value: 'hours', services: ['va.'] },
  {
    title: 'Percentage',
    value: 'percentage',
    services: ['va.', 'product.ai.connect'],
  },
  {
    title: 'Amount',
    value: 'cents_amount',
    services: ['va.', 'product.ai.connect'],
  },
  {
    title: 'No Effect',
    value: 'no_op',
    services: ['va.', 'product.ai.connect'],
  },
];

export const getInvoiceLineItemTypes = () => [
  {
    title: 'Correction',
    value: 'correction',
    services: ['va.', 'product.ai.connect'],
  },
  {
    title: 'Discount',
    value: 'discount',
    services: ['va.', 'product.ai.connect'],
  },
  {
    title: 'Expense',
    value: 'expense',
    description: 'VA Expense',
    services: ['va.'],
  },
  { title: 'Interview Allowance', value: 'interview', services: ['va.'] },
  { title: 'Overage', value: 'overage', services: ['va.'] },
  { title: 'Penalty', value: 'penalty', services: ['va.'] },
  { title: 'Promo', value: 'promo', services: ['va.', 'product.ai.connect'] },
  {
    title: 'Referral',
    value: 'referral',
    services: ['va.', 'product.ai.connect'],
  },
  {
    title: 'Service',
    value: 'service',
    services: ['va.', 'product.ai.connect'],
  },
  { title: 'Tasks', value: 'tasks', services: ['va.'] },
  { title: 'Time', value: 'time', services: ['va.'] },
];

export const getBillLineItemTypes = () => [
  { title: 'Bonus', value: 'bonus' },
  { title: 'Commission', value: 'commission' },
  { title: 'Correction', value: 'correction' },
  { title: 'Expense', value: 'expense' },
  { title: 'Gift Certificate', value: 'gift_certificate' },
  { title: 'Penalty', value: 'penalty' },
  { title: 'Shout Out', value: 'shout' },
  { title: 'Tasks', value: 'tasks' },
  { title: 'Time', value: 'time' },
  { title: 'VA Award', value: 'award' },
];

export const getInvoicePaymentMethods = () => [
  { title: 'Electronic Fund Transfer', value: 'transfer' },
  { title: 'Direct Debit', value: 'debit' },
  { title: 'Manual', value: 'manual' },
  { title: 'PayPal', value: 'paypal' },
  { title: 'Plooto', value: 'plooto' },
  { title: 'Direct Invoice', value: 'invoice' },
  { title: 'Stripe', value: 'stripe' },
  { title: 'Wave', value: 'wave' },
];

export const getRatePlanTypes = () => [
  {
    title: 'Monthly',
    value: 'monthly',
    services: ['va.', 'product.ai.connect'],
  },
  { title: 'Working Pause', value: 'working_pause', services: ['va.'] },
  { title: 'Hard Pause', value: 'hard_pause', services: ['va.'] },
  { title: 'Not Applicable', value: 'not_applicable', services: ['va.'] },
  { title: 'Onboarding', value: 'onboarding', services: ['va.'] },
];

export const getVARatePlanTypes = () => [
  { title: 'Semi Monthly', value: 'semi_monthly' },
  { title: 'Not Applicable', value: 'not_applicable' },
];

export const billingSummaryReport = (dateFrom, dateTo) => {
  return getJSON(
    `/reports/billing/summary?date_from=${dateFrom}&date_to=${dateTo}`
  );
};

/**
 * Checks a given date to see if it's a valid billing date for a client invoice.
 *
 * Takes into account that billing dates for short months will fall on the 1st day of
 * the following month.
 *
 * @param {Number} invoiceDay The day of the month the client is invoiced on
 * @param {Date} checkDate The date we're checking to see if it's an invoice day
 * @returns {Boolean}
 */
export const isClientBillingDate = (invoiceDay, checkDate) => {
  if (!invoiceDay || !checkDate) return false;
  const targetDate =
    typeof checkDate === 'string'
      ? new Date(checkDate + ' 00:00:00')
      : checkDate;
  // Case 1: Target day matches the invoice day
  if (targetDate.getDate() === invoiceDay) return true;
  // Case 2: Today is the 1st of the month and the invoice day is after the end of last month
  const endOfLastMonth = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    0
  );
  return targetDate.getDate() === 1 && invoiceDay > endOfLastMonth.getDate();
};

/**
 * Returns an array of invoicing dates for a client with a specific invoice day and
 * a specific number of billing periods from a start date. Billing dates are adjusted
 * for cases where the invoice day falls after the end of a short month.
 *
 * @param {Array} projectRatePlans An array of project rate plans
 * @param {Object} adjustment
 * @param {String} adjustment.applied_from The date to start checking from in YYYY-MM-DD format
 * @param {String} [adjustment.applied_to] The date to check to in YYYY-MM-DD format
 * @returns {Array} Array of short (YYYY-MM-DD) dates
 */
export const getClientBillingDatesFor = (
  projectRatePlans,
  { applied_from = formatShortDate(new Date()), applied_to } = {}
) => {
  if (!projectRatePlans?.length) return [];
  const targetDate = new Date(applied_from + ' 00:00:00');
  const dates = [];

  const futureRatePlan = projectRatePlans.find(
    (ratePlan) =>
      ratePlan.rate_type !== 'not_applicable' &&
      (!ratePlan.end_date ||
        formatShortDate(ratePlan.end_date) >= formatShortDate(targetDate))
  );

  do {
    let currentRatePlan = projectRatePlans.find(
      (ratePlan) =>
        ratePlan.rate_type !== 'not_applicable' &&
        formatShortDate(ratePlan.start_date) <= formatShortDate(targetDate) &&
        (!ratePlan.end_date ||
          formatShortDate(ratePlan.end_date) >= formatShortDate(targetDate))
    );
    let previousDate = new Date(targetDate);
    previousDate.setDate(previousDate.getDate() - 1);
    let previousRatePlan = projectRatePlans.find(
      (ratePlan) =>
        ratePlan.rate_type !== 'not_applicable' &&
        ratePlan.end_date &&
        formatShortDate(ratePlan.end_date) === formatShortDate(previousDate)
    );

    if (!futureRatePlan) {
      if (previousRatePlan) {
        // There's no current rate plan, but a rate plan ended yesterday - we would generate an invoice (if time was logged)
        dates.push(formatShortDate(targetDate));
      }
      return dates;
    }

    if (
      (currentRatePlan &&
        isClientBillingDate(currentRatePlan.invoice_day, targetDate)) ||
      previousRatePlan
    ) {
      dates.push(formatShortDate(targetDate));
    }
    targetDate.setDate(targetDate.getDate() + 1);
  } while (
    (applied_to && formatShortDate(targetDate) <= applied_to) ||
    (!applied_to && dates.length < 1)
  );
  return dates;
};

/**
 * Checks to see if a specified date is a valid payroll date
 *
 * @param {String} checkDate
 * @returns {Boolean}
 */
export const isVAPayrollDate = (checkDate) => {
  if (!checkDate) return false;
  const targetDate =
    typeof checkDate === 'string'
      ? new Date(checkDate + ' 00:00:00')
      : checkDate;
  // Case 1: Target date is one of the preset billing days
  return PAYROLL_DAYS.includes(targetDate.getDate());
};

/**
 * Returns an array of payroll dates for a specific number of payroll periods from a start date.
 *
 * @param {String} startDate The day to start checking from (doesn't have to be a valid payroll day)
 * @param {Number} periods The number of payroll periods
 * @returns {Array} Array of short (YYYY-MM-DD) dates
 */
export const getVAPayrollDates = (startDate = new Date(), periods = 1) => {
  const targetDate =
    typeof startDate === 'string'
      ? new Date(startDate + ' 00:00:00')
      : startDate;
  const dates = [];
  do {
    if (isVAPayrollDate(targetDate)) {
      dates.push(formatShortDate(targetDate));
      periods--;
    }
    targetDate.setDate(targetDate.getDate() + 1);
  } while (periods > 0);
  return dates;
};

/**
 * Counts the number of payroll days between two dates.
 *
 * @param {String} startDate The start of the date range to check
 * @param {String} endDate The end of the date range to check
 * @returns {Number} The number of payroll periods
 */
export const getVAPayrollPeriodsBetween = (
  startDate = new Date(),
  endDate = new Date()
) => {
  // Case 1: Any parameter null or start date after end date
  if (!startDate || !endDate || startDate > endDate) return null;
  // Case 2: Start date and end date are the same
  if (startDate === endDate) return 1;
  // Case 3: Check dates in the specified range
  const currentTargetDate =
    typeof startDate === 'string'
      ? new Date(startDate + ' 00:00:00')
      : startDate;
  const endTargetDate =
    typeof endDate === 'string' ? new Date(endDate + ' 00:00:00') : endDate;
  let periods = 0;
  do {
    if (isVAPayrollDate(currentTargetDate)) {
      periods++;
    }
    currentTargetDate.setDate(currentTargetDate.getDate() + 1);
  } while (currentTargetDate <= endTargetDate);
  return periods;
};

/**
 * Given a date (today) and a client invoice day figure out the earliest date
 * that can be used to log a new task.
 *
 * This can never be earlier than the last VA payroll date (1st or 16th of the current month).
 *
 *
 * @param {String} today
 * @param {Number} invoiceDay
 * @returns {moment} Earliest available task date
 */
export const getEarliestTaskDate = (
  invoiceDay,
  today = new Date().getDate()
) => {
  const lastVaPayrollDay = Math.max.apply(
    Math,
    PAYROLL_DAYS.filter(function (d) {
      return d <= today;
    })
  );
  const earliestDay =
    invoiceDay <= today
      ? Math.max(invoiceDay, lastVaPayrollDay)
      : lastVaPayrollDay;
  const earliest = new Date();
  earliest.setDate(earliestDay);
  return earliest.toISOString().slice(0, 10);
};
