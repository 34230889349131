<template>
  <div class="flex w-full flex-col">
    <slot name="title">
      <b v-if="label" class="text-vgnavy my-2 pl-2 text-sm uppercase"
        >{{ label }}
      </b>
    </slot>
    <label
      :class="
        inline || uiStore.mobile
          ? `${style} bg-vgstone-50`
          : `${style} bg-vgstone-50 rounded-md place-items-start  ${labelColor}`
      "
      :style="`min-height: ${height}rem;`"
      @mouseover="showEdit"
      @click="showEditData"
      @mouseleave="showEditIcon = false"
    >
      <div v-if="!editData" class="max-w-2xl p-3">
        <span v-dompurify-html="labelText" class="break-words"></span>
      </div>

      <textarea
        v-if="editData & !disabled"
        id="input"
        ref="input"
        v-model="incomingValue"
        :placeholder="placeholder"
        :rows="rows"
        class="w-full p-3 outline-none"
        @keydown.enter="!inline ? handleEnterKey : addNewLine"
        @blur="handleBlur"
        @input="$emit('update', incomingValue)"
        @keydown.esc="resetState"
      />
      <Transition name="ease-in-out">
        <fa-icon
          v-if="
            ((showEditIcon && !loading) || !inline) && !editData && !disabled
          "
          class="p-3"
          icon="fa-light fa-pencil"
          :aria-label="
            label
              ? $t('portal:vg.textInput.edit.label', { label: label })
              : $t('portal:vg.textInput.editText.label')
          "
          @click="showEditData"
        />
      </Transition>
      <v-progress-circular
        v-if="loading"
        class="m-3"
        :size="20"
        :width="3"
        :color="vgTeal"
        indeterminate
      ></v-progress-circular>
      <Transition name="ease-in-out">
        <fa-icon
          v-if="showCheckIcon"
          class="text-vgmedturq m-3"
          icon="fa-duotone fa-check text-2xl"
          :aria-label="$t('portal:vg.textInput.save.success')"
        />
      </Transition>
      <Transition name="ease-in-out">
        <fa-icon
          v-if="showErrorIcon"
          class="m-3 text-red-500"
          icon="fa-regular fa-triangle-exclamation text-2xl"
          :aria-label="$t('portal:vg.textInput.save.error')"
        />
      </Transition>
    </label>
    <p v-if="error" class="m-2 text-base text-red-500" role="alert">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import tailwind from 'tailwind.config';
import { mapStores } from 'pinia';
import { useUiStore } from '@/stores/ui';
import { newlineToBreak } from '@/utils/format.js';

export default {
  name: 'VgTextArea',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    rows: {
      type: Number,
      default: 1,
    },
    maxCharacters: {
      type: Number,
      default: 1000,
    },
    inline: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
      required: true,
    },
    showIcons: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['edit', 'udpate'],
  data() {
    return {
      editData: false,
      enterKeyPressed: false,
      incomingValue: '',
      showEditIcon: false,
      showCheckIcon: false,
      showErrorIcon: false,
      style:
        'relative flex justify-between transition duration-200 ease-in-out rounded-lg flex-inline items-start',
      text: '',
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
    };
  },
  computed: {
    ...mapStores(useUiStore),
    height() {
      return this.rows * 1.75;
    },
    labelText() {
      return this.editData
        ? ''
        : this.incomingValue.length === 0
        ? this.placeholder
        : newlineToBreak(this.incomingValue);
    },
    labelColor() {
      return this.incomingValue.length === 0 ? 'text-vgstone' : 'text-black';
    },
  },
  watch: {
    loading: {
      immediate: true,
      handler(newVal, oldVal) {
        this.incomingValue = JSON.parse(JSON.stringify(this.modelValue));

        if (
          (oldVal === true && newVal === false && this.inline) ||
          this.showIcons
        ) {
          if (this.error) {
            this.showErrorIcon = true;
          } else {
            this.showCheckIcon = true;

            setTimeout(() => {
              this.showCheckIcon = false;
            }, 1000);
          }
        }
      },
    },
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.incomingValue = JSON.parse(JSON.stringify(newVal));
      },
    },
    text() {
      if (this.text.length > this.maxCharacters) {
        this.text = this.text.slice(0, this.maxCharacters);
      }
    },
  },
  methods: {
    newlineToBreak,
    addNewLine(event) {
      event.target.value += '\n';
    },
    showEdit() {
      this.showErrorIcon
        ? (this.showEditIcon = false)
        : (this.showEditIcon = true);
    },
    showEditData() {
      if (this.disabled) {
        return;
      }
      this.editData = true;
      this.showErrorIcon = false;

      nextTick(() => {
        this.$refs.input.focus();
      });
      this.$emit('edit');
    },
    hideEditData() {
      this.editData = false;
      this.$emit('update', this.incomingValue);
    },
    handleEnterKey(event) {
      event.preventDefault();

      this.enterKeyPressed = true;
      this.hideEditData();
    },
    handleBlur() {
      if (!this.enterKeyPressed) {
        this.hideEditData();
      }

      this.enterKeyPressed = false;
    },
    resetState() {
      this.enterKeyPressed = false;
      this.editData = false;
      this.incomingValue = JSON.parse(JSON.stringify(this.modelValue));
    },
  },
};
</script>
