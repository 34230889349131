import { postJSON, getJSON } from '@/services/httpService';
import i18next from '@/plugins/i18next';

let session = {};
let socket = null;

/**
 * Reconnect session socket, if present
 *
 * @returns void
 */
const reconnectSocket = () => {
  if (!socket) {
    return;
  }
  socket.disconnect();
  socket.connect();
};

export const loadSession = async () => {
  try {
    session = await getJSON('/auth/session');

    if (window.apm) {
      window.apm.addLabels({ source: window.location.pathname });
      global.apm.setUserContext({
        id: session.user.uuid,
        username: session.user.full_name,
        email: session.user.email,
      });
      global.apm.setCustomContext({
        client_uuid: session.user.client_uuid,
        phone: session.user.phone_number,
        known_as: session.user.known_as,
        company: session.user.company,
        currency: session.user.currency,
        city: session.user.city,
        state_prov: session.user.state_prov,
        country: session.user.country,
        permissions: session.user.permissions,
        groups: session.user.groups,
      });
    }
  } catch (error) {
    session = {
      error:
        'There was an error loading your session. Please close the page and try again later.',
    };
  }
  return session;
};

export const saveSession = (token, user) => {
  session = { user };
  reconnectSocket();

  if (window.apm) {
    window.apm.addLabels({ source: window.location.pathname });
    global.apm.setUserContext({
      id: session.user.uuid,
      username: session.user.full_name,
      email: session.user.email,
    });
    global.apm.setCustomContext({
      client_uuid: session.user.client_uuid,
      phone: session.user.phone_number,
      known_as: session.user.known_as,
      company: session.user.company,
      currency: session.user.currency,
      city: session.user.city,
      state_prov: session.user.state_prov,
      country: session.user.country,
      permissions: session.user.permissions,
      groups: session.user.groups,
    });
  }

  return session;
};

export const endSession = async () => {
  if (session.ending) {
    return;
  }
  session.ending = true;
  try {
    await getJSON('/auth/logout');
    session = {};
    document.location.href = '/';
  } catch (error) {
    session.ending = false;
  }
};

export const hasSession = () => !!session.user;

export const getUser = () => session.user;

export const getSessionError = () => session.error;

export const sessionUserHasPermission = (permission) =>
  session.user?.permissions?.includes(permission);

export const httpOptions = (method = 'GET', body, headers = {}) => {
  return {
    method,
    body,
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      'x-vg-source': 'talentplace',
      'accept-language': i18next.language,
      ...headers,
    },
    credentials: 'include',
  };
};

export const verify = (verification_key, email) => {
  return postJSON('/auth/verify', { verification_key, email });
};

export const login = (email, password, collect) => {
  let body = {
    email,
    password,
    collect,
  };

  return postJSON('/auth/login', body);
};

export const logLogin = (user_uuid, type) => {
  return postJSON('/auth/log', { user_uuid, type });
};

export const checkUserExistByEmail = (email) => {
  return getJSON(
    `/auth/exists?email=${encodeURIComponent(email)}&source=talentplace`
  );
};

export const checkout = (body) => {
  return postJSON('/client/checkout', body);
};

export const registerClient = (body) => {
  return postJSON('/client/register', body);
};

export const changePassword = (email, oldPassword, newPassword) => {
  let body = {
    email,
    oldPassword,
    newPassword,
  };

  return postJSON('/auth/changepassword', body);
};

export const resetPassword = (email, send_new_token) => {
  return postJSON(
    `/auth/forgotpassword${send_new_token ? '?send_new_token=1' : ''}`,
    { email }
  );
};

export const changePasswordByToken = (email, resetPasswordToken, password) => {
  let body = {
    email,
    resetPasswordToken,
    password,
  };

  return postJSON('/auth/resetpassword', body);
};

export const saveSocket = (newSocket) => {
  socket = newSocket;
  socket.on('deauth', endSession);
};

export const getSocket = () => socket;

export const socketSearch = (filters) =>
  getJSON(`/socket?${filters.join('&')}`);
