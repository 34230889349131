import { getJSON, postJSON } from '@/services/httpService';
const STORAGE_KEY = 'vg.utm';

const getStoredUtm = () => {
  try {
    return JSON.parse(localStorage.getItem(STORAGE_KEY));
  } catch (e) {
    return {};
  }
};

const saveUtmLocalStorage = (utm) => {
  localStorage.setItem(STORAGE_KEY, JSON.stringify(utm));
};

export const utmSearch = (filters) => getJSON(`/utm/?${filters.join('&')}`);

export const utmSearchLogs = (filters) =>
  getJSON(`/utm/log?${filters.join('&')}`);

export const getUtm = (utmUuid) => getJSON(`/utm/${utmUuid}`);

export const generateShortcode = (campaign) =>
  getJSON(`/utm/generate_referral?campaign=${campaign}`);

export const saveUtm = (utm) => postJSON(`/utm`, utm);

export const getUtmUuid = () => getStoredUtm()?.uuid;

export const handleUTMCheck = async () => {
  const utm = window.location.search
    .substring(1)
    .split('&')
    .reduce((accumulator, parameter) => {
      if (parameter.startsWith('utm')) {
        const [key, value] = parameter.split('=');
        if (key === 'utm') {
          if (
            /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/.test(
              value
            )
          ) {
            accumulator.uuid = value;
          } else {
            accumulator.shortcode = value;
          }
        } else {
          accumulator[key] = value;
        }
      }
      return accumulator;
    }, {});
  if (Object.keys(utm).length > 0) {
    const utmResult = await postJSON(
      '/utm/log?event=pageview',
      utm
    ).catch(() => {});
    if (utmResult?.uuid) {
      saveUtmLocalStorage(utmResult);
    }
  }
};

export const logUtmEvent = function (event) {
  const utm = getStoredUtm();
  if (!utm) {
    return;
  }
  postJSON(`/utm/log?event=${event}`, utm);
};
