<template>
  <div class="flex min-h-full flex-col lg:flex-row">
    <div class="flex max-w-xl grow flex-col px-4">
      <h1 class="text-left text-3xl font-semibold">
        {{ $t('changePassword.heading') }}
      </h1>
      <div class="w-full rounded-md bg-white p-4 shadow">
        <p v-if="user.permissions.includes('askbetty_client')">
          {{ $t('changePassword.subheading') }}
        </p>
        <v-form ref="changePasswordForm" @submit.prevent="changePassword">
          <label class="text-sm">
            {{ $t('changePassword.currentPassword.label') }}
            <v-text-field
              v-model="registerForm.oldPassword"
              autocomplete="new-password"
              :rules="requiredRules"
              required
              variant="outlined"
              :color="vgTeal"
              type="password"
            >
              <template #prepend-inner>
                <fa-icon
                  icon="fa-regular fa-lock-keyhole"
                  class="text-vgstone -mt-1 pr-2 text-lg"
                />
              </template>
            </v-text-field>
          </label>

          <label class="text-sm">
            {{ $t('changePassword.newPassword.label') }}
            <v-text-field
              v-model="registerForm.newPassword"
              autocomplete="new-password"
              :rules="passwordRules"
              required
              variant="outlined"
              :color="vgTeal"
              type="password"
            >
              <template #prepend-inner>
                <fa-icon
                  icon="fa-regular fa-lock-keyhole"
                  class="text-vgstone -mt-1 pr-2 text-lg"
                />
              </template>
            </v-text-field>
          </label>
          <label class="text-sm">
            {{ $t('changePassword.confirmPassword.label') }}
            <v-text-field
              v-model="registerForm.confirmNewPassword"
              autocomplete="new-password"
              :rules="confirmPasswordRules"
              required
              variant="outlined"
              :color="vgTeal"
              type="password"
            >
              <template #prepend-inner>
                <fa-icon
                  icon="fa-regular fa-lock-keyhole"
                  class="text-vgstone -mt-1 pr-2 text-lg"
                />
              </template>
            </v-text-field>
          </label>
          <vg-btn block type="submit" class="mt-4 uppercase">{{
            $t('submit.button')
          }}</vg-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import {
  getUser,
  sessionUserHasPermission,
  changePassword,
} from '@/services/clientSessionService';
import { validatePassword } from '@/services/validationService';
import tailwind from 'tailwind.config';

export default {
  name: 'ChangePassword',

  data() {
    return {
      isVA: false,
      registerForm: {},
      requiredRules: [(v) => !!v || this.$t('required.error')],
      passwordRules: [
        (v) => !!v || this.$t('required.error'),
        validatePassword,
      ],
      confirmPasswordRules: [
        (v) => !!v || this.$t('required.error'),
        (v) =>
          v === this.registerForm.newPassword ||
          this.$t('changePassword.confirmPassword.mustMatch.error'),
      ],
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
    };
  },

  created() {
    this.user = getUser();
    this.registerForm.email = this.user.email;
    this.isVA = sessionUserHasPermission('virtualassistant');
  },

  methods: {
    close() {
      this.$router.go(-1);
    },
    async changePassword() {
      if (!(await this.$refs.changePasswordForm.validate()).valid) {
        return;
      }

      try {
        await changePassword(
          this.registerForm.email,
          this.registerForm.oldPassword,
          this.registerForm.newPassword
        );
      } catch (error) {
        return this.$root.$snackbar.error(
          error?.data?.message ?? this.$t('changePassword.error')
        );
      }

      this.$root.$snackbar.message(this.$t('changePassword.success'));

      this.registerForm.oldPassword = '';
      this.registerForm.newPassword = '';
      this.registerForm.confirmNewPassword = '';

      nextTick(() => {
        this.$refs.changePasswordForm.resetValidation();
      });
    },
  },
};
</script>
