<template>
  <div>
    <h4 v-if="!mobile && data.uuid">
      {{ $t('portal:office.hours.title') }}
    </h4>
    <div class="flex flex-col items-center">
      <div
        class="text-vgstone grid w-60 grid-cols-10 gap-0 text-center text-sm"
      >
        <div class="col-span-3 m-0.5"></div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.1.heading') }}
        </div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.2.heading') }}
        </div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.3.heading') }}
        </div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.4.heading') }}
        </div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.5.heading') }}
        </div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.6.heading') }}
        </div>
        <div class="m-0.5">
          {{ $t('portal:weeklySchedule.day.week.7.heading') }}
        </div>
      </div>
      <div class="grid h-16 w-60 grid-cols-10 gap-0">
        <div class="grid-row-3 text-vgstone col-span-3 grid gap-0 text-sm">
          <div class="truncate">
            {{ $t('portal:weeklySchedule.time.availability.first.heading') }}
          </div>
          <div class="truncate">
            {{ $t('portal:weeklySchedule.time.availability.second.heading') }}
          </div>
          <div class="truncate">
            {{ $t('portal:weeklySchedule.time.availability.third.heading') }}
          </div>
        </div>
        <div class="col-span-7 ml-2 grid grid-cols-7 gap-0">
          <div
            v-for="(time, index) in weeklySchedule"
            :key="index"
            :class="{
              'bg-vgteal': time.available,
              'bg-vgorange': !time.available,
            }"
            class="m-0.5"
          >
            <span class="sr-only">
              {{
                `${time.description} ${
                  time.available
                    ? $t('portal:weeklySchedule.available.text')
                    : $t('portal:weeklySchedule.not.available.text')
                } `
              }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isOpportunity" class="flex flex-row justify-center p-3">
      <div class="bg-vgteal m-0.5 h-4 w-4 place-self-end"></div>
      <div class="text-vgstone m-0.5 text-sm">
        {{ $t('portal:weeklySchedule.available.text') }}
      </div>
      <div class="bg-vgorange m-0.5 h-4 w-4 place-self-end"></div>
      <div class="text-vgstone m-0.5 text-sm">
        {{ $t('portal:weeklySchedule.not.available.text') }}
      </div>
    </div>
    <div v-else class="flex flex-row justify-center p-3">
      <div class="bg-vgteal m-0.5 h-4 w-4 place-self-end"></div>
      <div class="text-vgstone m-0.5 text-sm">
        {{ $t('portal:weeklySchedule.requested.text') }}
      </div>
    </div>
    <p v-if="!isOpportunity" class="text-vgocean break-words pt-5 text-sm">
      {{
        $t('portal:weeklySchedule.time.current.text', {
          name: data.display_name,
        })
      }}
    </p>
    <p v-if="!isOpportunity" class="text-vgocean text-sm">
      {{ vaLocalTime }} ({{ data.timezone }})
    </p>
  </div>
</template>

<script>
import { toLocaleTimeString } from '@/services/formattingService';

export default {
  name: 'WeeklySchedule',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    mobile: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isOpportunity: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      weeklySchedule: [],
      now: new Date(),
      dayTime:
        this.$i18next.language === 'en'
          ? { Morning: 1, Afternoon: 2, Evening: 3 }
          : { Matin: 1, Apm: 2, Soir: 3 },
      weekDay:
        this.$i18next.language === 'en'
          ? {
              Sun: 1,
              Mon: 2,
              Tues: 3,
              Weds: 4,
              Thurs: 5,
              Fri: 6,
              Sat: 7,
            }
          : {
              Dim: 1,
              Lun: 2,
              Mar: 3,
              Mer: 4,
              Jeu: 5,
              Ven: 6,
              Sam: 7,
            },
      timer: '',
    };
  },
  computed: {
    vaLocalTime: function () {
      try {
        return toLocaleTimeString(this.now, {
          timeZone: this.data.timezone,
        });
      } catch (error) {
        return '-';
      }
    },
  },
  created() {
    for (let dayTime in this.dayTime) {
      for (let weekDay in this.weekDay) {
        this.weeklySchedule.push({
          available: false,
          description: `${weekDay} ${dayTime}`,
        });
      }
    }

    const availabilities =
      this.$i18next.language === 'fr' &&
      this.data.va_availabilities_fr &&
      typeof this.data.va_availabilities_fr === 'string'
        ? this.data.va_availabilities_fr?.split(',')
        : typeof this.data.va_availabilities === 'string'
        ? this.data.va_availabilities?.split(',')
        : this.data.va_availabilities;

    for (let availability of availabilities) {
      const availabilityParts = availability.split('-');
      const weekDayFromData = availabilityParts[0];
      const day = availabilityParts[1].trim().split(' ')[0];

      this.weeklySchedule[
        (this.dayTime[day.trim()] - 1) * 7 +
          this.weekDay[weekDayFromData.trim()] -
          1
      ].available = true;
    }
    this.timer = setInterval(() => {
      this.now = new Date();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
