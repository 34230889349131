<template>
  <div
    v-if="assistant.vg_academy_courses && assistant.vg_academy_courses.length"
  >
    <h4 v-if="!mobile" class="mb-1">
      {{ $t('portal:certifications.title') }}
    </h4>
    <p class="text-vgorange pb-7 text-sm font-semibold uppercase">
      {{ $t('portal:virtual.gurus.certifications.title') }}
    </p>
    <div class="flex flex-col">
      <v-chip
        v-for="(cert, index) in assistant.vg_academy_courses"
        :key="cert"
        class="bg-vgsilver-50 mb-4 text-ellipsis font-semibold"
        variant="text"
      >
        <img
          :src="`/img/academy-badges/VGA-Course-Badges-${badgeMap[cert]}.png`"
          :title="
            i18next.language === 'fr'
              ? assistant.vg_academy_courses_fr[index] || cert
              : cert
          "
          :alt="
            $t('portal:certifications.alt', {
              cert:
                i18next.language === 'fr'
                  ? assistant.vg_academy_courses_fr[index] || cert
                  : cert,
            })
          "
          class="w-10 p-1"
        />
        <span class="text-vgocean truncate">{{
          i18next.language === 'fr'
            ? assistant.vg_academy_courses_fr[index] || cert
            : cert
        }}</span></v-chip
      >
    </div>
  </div>
</template>

<script>
import { useTranslation } from 'i18next-vue';

export default {
  name: 'Certifications',
  props: {
    assistant: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    mobile: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    const { i18next } = useTranslation();

    return {
      i18next,
      badgeMap: {
        'ConvertKit Fundamentals': '1',
        'Facebook Ads 102': '2',
        'Graphic Design Fundamentals': '3',
        'How to Build a Powerful Client Intake System': '4',
        'How to Build an Effective Editorial Calendar': '5',
        'How to Create Shared Folders & Manage Storage': '6',
        'How to Run Highly Effective Virtual Meetings': '7',
        'Intro to Facebook Ads': '8',
        'Introduction to Facebook Pages': '9',
        'Introduction to Instagram': '10',
        'LinkedIn 101': '11',
        'Social Media Basics': '12',
        'Social Media Manager': '13',
        'Virtual Assistance 101': '14',
        'Virtual Assistance 102': '15',
        'Virtual Assistant Bootcamp 1': '16',
        'Virtual Assistant Bootcamp 2': '17',
        'WordPress Fundamentals': '18',
        'Teamwork Training': '19',
        'How to be an Outstanding CEO': '20',
        'Intro to ASANA': '21',
        'SEO Primer': '22',
        'Kajabi 101': '23',
        'Digital Marketing Ecosystem': '24',
        'Wordpress Theme Management Intermediate': '25',
        'Intro to Instagram Strategy': '26',
        'Real Estate 101': '27',
        'Real Estate 201': '28',
        'Real Estate for VAs': '29',
        'Advanced Canva and Graphic Design': '30',
        'Graphic Design': '31',
        'Virtual Assistant Bootcamp': '32',
        'Building blocks for VA business': '33',
        'Introduction to Microsoft Outlook': '34',
        'Problem solving and decision making': '35',
        'Anger Management': '36',
        'Social Media 101': '37',
        'Emotional Intelligence': '38',
        'How to become a virtual bookkeeper': '39',
        'Shopify for Beginners': '40',
        'Grammar for Business Writing': '41',
        'Social Media for Real Estate': '42',
      },
    };
  },
};
</script>
