import { httpOptions } from '@/services/clientSessionService';
import { API_URL } from '@/config';

/**
 * Check if the reason a reqest failed was an expired session. If it was, the app is reloaded
 *
 * @param {*} res Express response object
 * @param {Object} body Express body
 * @returns void|boolean
 */
const checkSessionExpired = (res, body) => {
  if (res.status === 401 && body.isExpired) {
    document.location = '/';
  }
  return false;
};

export const getJSON = (path) =>
  fetch(`${API_URL}${path}`, httpOptions()).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then(
            (json) =>
              !checkSessionExpired(res, json) &&
              Promise.reject({ status: res.status, data: json })
          )
  );

export const getPDF = (path) => {
  let options = httpOptions();
  options.headers = {
    ...options.headers,
    ...{ Accept: 'application/pdf' },
  };
  options.responseType = 'blob';
  return fetch(`${API_URL}${path}`, options).then((res) =>
    res.ok
      ? res.blob().catch(() => 'Ok')
      : res
          .text()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then((data) => Promise.reject({ status: res.status, data }))
  );
};

export const getCSV = (path) => {
  let options = httpOptions();
  options.headers = {
    ...options.headers,
    ...{ Accept: 'text/csv' },
  };
  options.responseType = 'blob';
  return fetch(`${API_URL}${path}`, options).then((res) =>
    res.ok
      ? res.blob().catch(() => 'Ok')
      : res
          .text()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then((data) => Promise.reject({ status: res.status, data }))
  );
};

export const postJSON = (path, body, headers = {}) =>
  fetch(
    `${API_URL}${path}`,
    httpOptions(
      'POST',
      body ? (headers['Content-Type'] ? body : JSON.stringify(body)) : null,
      headers
    )
  ).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then(
            (json) =>
              !checkSessionExpired(res, json) &&
              Promise.reject({ status: res.status, data: json })
          )
  );

export const putJSON = (path, body) =>
  fetch(
    `${API_URL}${path}`,
    httpOptions('PUT', body ? JSON.stringify(body) : null)
  ).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then(
            (json) =>
              !checkSessionExpired(res, json) &&
              Promise.reject({ status: res.status, data: json })
          )
  );

export const getRaw = (path) => fetch(`${API_URL}${path}`, httpOptions());

export const deleteAction = (path) =>
  fetch(`${API_URL}${path}`, httpOptions('DELETE')).then((res) =>
    res.ok
      ? res.json().catch(() => 'Ok')
      : res
          .json()
          .catch(() => {
            throw 'An Error occurred';
          })
          .then(
            (json) =>
              !checkSessionExpired(res, json) &&
              Promise.reject({ status: res.status, data: json })
          )
  );

export const canGoBack = function () {
  return !!window.history.state?.back;
};
