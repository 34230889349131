<template>
  <div role="alert" :aria-label="text">
    <v-snackbar
      v-model="show"
      :z-index="3200"
      :location="true && 'top'"
      :color="color"
      :timeout="timeout"
    >
      {{ text }}
      <template v-if="timeout === -1" #actions>
        <v-btn variant="text" @click="show = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'Snackbar',
  data() {
    return {
      show: false,
      text: '',
      color: 'info',
      timeout: undefined,
    };
  },
  methods: {
    message(text, options = {}) {
      this.show = true;
      this.text = text;
      this.color = 'info';
      this.timeout = options.persist ? -1 : options.timeout;
    },
    error(text, options = {}) {
      this.show = true;
      this.text = text;
      this.color = 'error';
      this.timeout = options.persist ? -1 : options.timeout;
    },
  },
};
</script>
