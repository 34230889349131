<template>
  <div v-if="task.uuid !== -1">
    <div
      v-if="
        task.created_by === user.uuid ||
        (task.assignment &&
          task.assignment.some((a) => a.user_uuid === user.uuid))
      "
    >
      <h5>{{ $t('portal:task.completion.heading') }}</h5>
      <label class="text-sm">
        {{ $t('portal:tasks.percentComplete.label') }}
        <v-slider
          v-model="percent_complete"
          class="mb-4"
          :color="vgOrange"
          track-color="black"
          track-size="2"
          :min="0"
          :max="canComplete ? 100 : 95"
          :step="5"
          show-ticks="always"
          tick-size="2"
          :hint="completeHint"
          persistent-hint
          @update:modelValue="handlePercentCompleteChanged"
        >
          <template #append> {{ percent_complete }}% </template>
        </v-slider>
      </label>
      <div v-if="percent_complete === 100 && next_due_date">
        <h5>{{ $t('portal:task.repeating.label') }}</h5>
        <label class="text-sm">
          {{ $t('portal:task.createNext.label') }}
          <v-menu
            ref="next_due_date_menu_schedule"
            v-model="nextDueDateVgDatePickerSchedule"
            :close-on-content-click="false"
            transition="scale-transition"
            :offset="[5, -25]"
            min-width="290px"
          >
            <template #activator="{ props }">
              <v-text-field
                v-model="next_due_date"
                variant="outlined"
                :color="vgTeal"
                readonly
                required
                clearable
                v-bind="props"
                @click:clear.prevent
              >
                <template #prepend-inner>
                  <fa-icon
                    icon="fa-regular fa-calendar-day"
                    class="text-vgstone -mt-1 pr-2 text-lg"
                  />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              :model-value="new Date(next_due_date ?? '')"
              :color="vgNavy"
              @update:modelValue="
                (event) => {
                  next_due_date = event.toISOString().split('T')[0];
                  nextDueDateVgDatePickerSchedule = false;
                  $emit('change', {
                    dirty: true,
                    next_due_date: next_due_date,
                  });
                }
              "
            >
            </v-date-picker>
          </v-menu>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { upcoming } from '@/utils/schedule';
import { formatDate } from '@/services/formattingService';
import moment from 'moment';
import tailwind from 'tailwind.config';

export default {
  name: 'ProjectTaskCompletion',
  props: {
    task: {
      type: Object,
      required: true,
    },
    // Allow completion to be set to 100%, assuming time will be logged by the parent context
    allowImmediateComplete: { type: Boolean, default: false },
  },
  emits: ['change'],
  data: () => {
    const { user } = storeToRefs(useUserStore());

    return {
      user,
      percent_complete: 0,
      next_due_date: null,
      completed_date: null,
      completed_by_user_uuid: null,
      nextDueDateVgDatePickerSchedule: false,
      vgOrange: tailwind.theme.extend.colors.vgorange[500],
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
      vgNavy: tailwind.theme.extend.colors.vgnavy[500],
    };
  },
  computed: {
    canComplete() {
      return (
        this.task.created_by === this.user.uuid ||
        this.user.permissions.includes('admin') ||
        this.user.permissions.includes('client') ||
        !!this.task.reminder ||
        this.task.time_entries?.length > 0 ||
        this.allowImmediateComplete
      );
    },
    completeHint() {
      return this.canComplete
        ? this.percent_complete === 100
          ? this.$t('portal:tasks.complete.text', {
              task: { name: this.task.name, date: formatDate(moment()) },
            })
          : this.$t('portal:task.complete.mark.text')
        : this.$t('portal:task.complete.noTime.text');
    },
  },
  watch: {
    task: function (task) {
      // Ensure our editable values are set every time a different task property is set
      this.percent_complete = task.percent_complete || 0;
      this.next_due_date = task.next_due_date;
    },
  },
  created() {
    // Ensure our editable values are set when the component is first created on a form
    this.percent_complete = this.task.percent_complete || 0;
    this.next_due_date = this.task.next_due_date;
  },
  methods: {
    formatDate,
    handlePercentCompleteChanged() {
      const next_due = moment(this.task.next_due_date);
      if (this.percent_complete === 100) {
        // Task has been completed
        this.completed_date = moment().utc().format('YYYY-MM-DD HH:mm:ss');
        this.completed_by_user_uuid = this.user.uuid;

        // Now check for a repeating task to see if we need a new instance cloned
        if (this.task.schedule?.repeat_every && this.task.schedule?.period) {
          if (
            this.task.schedule?.end_date &&
            moment().format('YYYY-MM-DD') >= this.task.schedule.end_date
          ) {
            // Past end date, no new instance
            this.next_due_date = null;
          } else {
            // No end date or not past it yet,
            this.next_due_date = upcoming(
              this.task.schedule,
              moment().isSameOrBefore(next_due, 'day')
                ? next_due.add(1, 'day')
                : moment()
            ).next().value;
          }
        } else {
          // One shot task
          this.next_due_date = null;
        }
      } else {
        // Clear completion fields if set to less than 100%
        this.completed_date = null;
        this.completed_by_user_uuid = null;
      }

      // Emit task changes as an event - let the parent component/view deal with updating the task.
      // This allows for cancellation of these changes if they're being made in the context of other
      // changes, such as logging time or a timer.
      this.$emit('change', {
        dirty: true,
        percent_complete: this.percent_complete,
        next_due_date: this.next_due_date,
        completed_date: this.completed_date,
        completed_by_user_uuid: this.completed_by_user_uuid,
      });
    },
  },
};
</script>
<style scoped>
* :deep(.v-label) {
  @apply !ml-4;
}
</style>
