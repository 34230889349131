import {
  getJSON,
  getPDF,
  getCSV,
  putJSON,
  postJSON,
  deleteAction,
} from '@/services/httpService';
import i18next from '@/plugins/i18next';
import { flattenObj } from '@/utils/flatten';

export const timeSearch = (filters) => getJSON(`/time/?${filters.join('&')}`);

export const timeSearchPDF = (filters) =>
  getPDF(`/time/?${filters.join('&')}`).then((response) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response);
    link.download = i18next.t('portal:time.link');
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  });

export const timeSearchCSV = (filters) =>
  getCSV(`/time/?${filters.join('&')}`).then((response) => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(response);
    link.download = i18next.t('portal:time.link');
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  });

export const getEntry = (uuid) => getJSON(`/time/${uuid}`);

export const createEntry = (entry) => postJSON(`/time`, entry);

export const updateEntry = (entry) => putJSON(`/time/${entry.uuid}`, entry);

export const deleteEntry = (uuid) => deleteAction(`/time/${uuid}`);

export const getAuditLogs = (time_entry_uuid, pagination) => {
  const query = new URLSearchParams(flattenObj({ pagination })).toString();

  return getJSON(`/time/${time_entry_uuid}/audit-logs?${query}`);
};
