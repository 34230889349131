import moment from 'moment';

/**
 * Calculate the duration of a single timer start/stop event
 * @param {Object} timer
 * @returns Duration in decimal hours, rounded to 2 dp
 */
export const calculateTimerDuration = (timerEvent) => {
  const duration = moment.duration(
    moment(timerEvent.stop?.timestamp || moment()).diff(
      moment(timerEvent.start.timestamp)
    )
  );
  return duration.asHours();
};

/**
 * Calculate the total duration (all the starts/stops) of a VA timer
 * @param {Object} timers
 * @returns Duration in decimal hours, rounded to 2 dp
 */
export const calculateTotalDuration = (timerEvents) => {
  return timerEvents?.reduce((total_duration, timer) => {
    timer.duration = calculateTimerDuration(timer);
    return total_duration + timer.duration;
  }, 0);
};

export const calculateDurationHMS = (timerEvents) => {
  const hms = moment.duration(calculateTotalDuration(timerEvents), 'hours');
  return { h: hms.hours(), m: hms.minutes(), s: hms.seconds() };
};
