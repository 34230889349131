<template>
  <div class="flex min-h-full max-w-6xl flex-col lg:flex-row">
    <div v-if="virtualassistant && vaConfig" class="max-w-7xl flex-1 p-4">
      <div class="rounded-md bg-white p-4 shadow">
        <h3>
          {{
            $route.path === '/va/profile'
              ? 'Profile'
              : 'Contact Information Inline Edit'
          }}
        </h3>
        <div class="grid grid-cols-1">
          <div class="row-span-4 pt-8 text-center">
            <v-avatar size="200" class="m-5" style="border: 2px solid #aaa">
              <img
                v-show="!!virtualassistant.avatar_id && isAvatarLoaded"
                :src="avatarLink"
                @load="isAvatarLoaded = true"
              />
            </v-avatar>
          </div>

          <VgTextField
            :label-classes="`flex flex-auto justify-start items-center transition duration-200 ease-in-out hover:cursor-pointer hover:bg-vgsilver-300`"
            :model-value="virtualassistant.preferred_pronouns"
            :error="editStates.preferred_pronouns.error"
            :loading="editStates.preferred_pronouns.loading"
            @update="($event) => update($event, 'preferred_pronouns')"
          >
            <template #title>
              <b class="py-3 pr-3">Preferred Pronouns:</b>
            </template>
          </VgTextField>
          <VgTextArea
            :label-classes="`flex flex-auto justify-start items-center transition duration-200 ease-in-out hover:cursor-pointer hover:bg-vgsilver-300`"
            :data="{
              value: virtualassistant.display_name,
              ...editStates.display_name,
            }"
            :model-value="virtualassistant.display_name"
            :error="editStates.display_name.error"
            :loading="editStates.display_name.loading"
            @update="($event) => update($event, 'display_name')"
          >
            <template #title>
              <b class="py-3 pr-3">Display Name:</b>
            </template>
          </VgTextArea>

          <vgSelect
            :label-classes="'flex flex-auto justify-start items-center'"
            :model-value="virtualassistant.self_identifications"
            :error="editStates.self_identifications.error"
            :loading="editStates.self_identifications.loading"
            :items="vaConfig.self_identifications"
            @update="($event) => update($event, 'self_identifications')"
          >
            <template #title>
              <b class="py-3 pr-3">Self Identifications:</b>
            </template>
          </vgSelect>

          <label>
            <b>Business Email:</b>
            {{ virtualassistant.business_email }}
          </label>
          <div>
            <label
              class="hover:bg-vgsilver-300 flex flex-auto items-center justify-start transition duration-200 ease-in-out hover:cursor-pointer"
              :class="{ 'bg-vgsilver-200': editLocation }"
              @mouseover="showEditLocationIcon = true"
              @mouseleave="showEditLocationIcon = false"
            >
              <b class="py-3 pr-3">Location:</b>
              {{
                editLocation
                  ? ''
                  : Object.keys(address)
                      .map((key) => address[key])
                      .filter((item) => !!item)
                      .join(', ')
              }}
              <Transition name=" ease-in-out">
                <fa-icon
                  v-if="showEditLocationIcon && !editLocation"
                  class="pl-3"
                  icon="fa-regular fa-pencil"
                  @click.stop="showEditLocation"
                />
              </Transition>
            </label>
            <v-expand-transition>
              <div v-show="editLocation">
                <div class="mb-4">
                  <label class="mb-6 block">
                    <span class="text-gray-500">Address line 1</span>
                  </label>
                  <input
                    id="autocomplete"
                    v-model="address.street"
                    class="form-control w-full"
                    name="name"
                  />
                </div>

                <div class="sm:rounded-md">
                  <label class="mb-6 block">
                    <span class="text-gray-500">Address line 2</span>
                    <input
                      v-model="address.address2"
                      name="address2"
                      type="text"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">City</span>
                    <input
                      v-model="address.city"
                      name="city"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">State/Province</span>
                    <input
                      v-model="address.state"
                      name="state"
                      type="text"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">Zip/Postal code</span>
                    <input
                      v-model="address.postalCode"
                      name="zip"
                      type="text"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">Country</span>
                    <input
                      v-model="address.country"
                      name="country"
                      type="text"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                </div>
                <div class="flex justify-end">
                  <button
                    class="hover:bg-vgorange bg-vgorange focus-visible:outline-vgorange rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    @click.stop="hideLocation"
                  >
                    Update
                  </button>
                </div>
              </div>
            </v-expand-transition>
          </div>

          <a
            v-if="virtualassistant.uuid"
            :href="`${TALENTPLACE_URL}/assistants/${virtualassistant.uuid}`"
            target="_blank"
            >Link: Public Profile</a
          >
        </div>
      </div>
    </div>
    <div v-if="virtualassistant && vaConfig" class="max-w-7xl flex-1 p-4">
      <div class="rounded-md bg-white p-4 shadow">
        <h3>
          {{
            $route.path === '/va/profile'
              ? 'Profile'
              : 'Contact Information Form Edit'
          }}
        </h3>
        <div class="grid grid-cols-1">
          <div class="row-span-4 pt-8 text-center">
            <v-avatar size="200" class="m-5" style="border: 2px solid #aaa">
              <img
                v-show="!!virtualassistant.avatar_id && isAvatarLoaded"
                :src="avatarLink"
                @load="isAvatarLoaded = true"
              />
            </v-avatar>
          </div>
          <div v-if="!editForm" class="flex justify-end">
            <button
              class="hover:bg-vgorange bg-vgorange focus-visible:outline-vgorange rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              @click.stop="editForm = true"
            >
              Edit
            </button>
          </div>
          <label
            class="flex flex-auto items-center justify-start transition duration-200 ease-in-out"
          >
            <b class="py-3 pr-3">Display Name:</b>
            {{ editForm ? '' : virtualassistant.display_name }}
            <v-text-field
              v-if="editForm"
              ref="display_name"
              v-model="virtualassistant.display_name"
              density="compact"
            ></v-text-field>
          </label>
          <label
            class="flex flex-auto items-center justify-start transition duration-200 ease-in-out"
          >
            <b class="py-3 pr-3">Preferred Pronouns:</b>
            {{ editForm ? '' : virtualassistant.preferred_pronouns }}
            <v-text-field
              v-if="editForm"
              ref="preferred_pronouns"
              v-model="virtualassistant.preferred_pronouns"
              density="compact"
            ></v-text-field>
          </label>
          <label
            class="flex flex-auto items-center justify-start transition duration-200 ease-in-out"
          >
            <b class="py-3 pr-3">Self Identifications:</b>
            {{
              editForm ? '' : virtualassistant.self_identifications.join(', ')
            }}
            <v-select
              v-if="editForm"
              ref="self_identifications"
              v-model="virtualassistant.self_identifications"
              class="pt-0"
              :items="vaConfig.self_identifications"
              variant="outlined"
              multiple
              chips
              hide-details
            >
            </v-select>
          </label>

          <label>
            <b>Business Email:</b>
            {{ virtualassistant.business_email }}
          </label>
          <div>
            <label
              class="flex flex-auto items-center justify-start transition duration-200 ease-in-out"
            >
              <b class="py-3 pr-3">Location:</b>
              {{
                editForm
                  ? ''
                  : Object.keys(address)
                      .map((key) => address[key])
                      .filter((item) => !!item)
                      .join(', ')
              }}
            </label>
            <v-expand-transition>
              <div v-show="editForm">
                <div class="mb-4">
                  <label class="mb-6 block">
                    <span class="text-gray-500">Address line 1</span>
                  </label>
                  <input
                    id="autocomplete2"
                    v-model="address.street"
                    class="form-control w-full"
                    name="name"
                  />
                </div>

                <div class="sm:rounded-md">
                  <label class="mb-6 block">
                    <span class="text-gray-500">Address line 2</span>
                    <input
                      v-model="address.address2"
                      name="address2"
                      type="text"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">City</span>
                    <input
                      v-model="address.city"
                      name="city"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">State/Province</span>
                    <input
                      v-model="address.state"
                      name="state"
                      type="text"
                      class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200/50"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">Zip/Postal code</span>
                    <input
                      v-model="address.postalCode"
                      name="zip"
                      type="text"
                      class="focus:ring-indigo-20050 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring"
                      placeholder=""
                    />
                  </label>
                  <label class="mb-6 block">
                    <span class="text-gray-500">Country</span>
                    <input
                      v-model="address.country"
                      name="country"
                      type="text"
                      class="focus:ring-indigo-20050 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring"
                      placeholder=""
                    />
                  </label>
                </div>
              </div>
            </v-expand-transition>
          </div>

          <a
            v-if="virtualassistant.uuid"
            :href="`${TALENTPLACE_URL}/assistants/${virtualassistant.uuid}`"
            target="_blank"
            >Link: Public Profile</a
          >
          <div v-if="editForm" class="flex justify-end">
            <button
              class="hover:bg-vgorange bg-vgorange focus-visible:outline-vgorange rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              @click.stop="editForm = false"
            >
              save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { API_URL, TALENTPLACE_URL, NODE_ENV } from '@/config';
import gmapsInit from '@/utils/gmaps';
import vgSelect from '@/components/inlineInputs/VgSelect.vue';
import VgTextField from '@/components/inlineInputs/VgTextField.vue';
import VgTextArea from '@/components/inlineInputs/VgTextArea.vue';

export default {
  name: 'Experiment',
  components: { vgSelect, VgTextField, VgTextArea },

  data() {
    const { virtualassistant, vaConfig } = storeToRefs(useUserStore());

    return {
      virtualassistant,
      vaConfig,
      isAvatarLoaded: true,
      TALENTPLACE_URL,
      editLocation: false,
      showEditLocationIcon: false,
      counter: 0,
      address: {
        addressLine2: '',
        city: 'Calgary',
        country: 'Canada',
        postalCode: 'T2N 1W1',
        state: 'Alberta',
        street: '1110 3 Ave NW',
      },

      autocomplete: null,
      autocomplete2: null,
      editForm: false,
      editStates: {
        self_identifications: { loading: false, error: null },
        display_name: { loading: false, error: null },
        preferred_pronouns: { loading: false, error: null },
      },
    };
  },
  computed: {
    avatarLink: function () {
      return this.virtualassistant.avatar_id
        ? `${API_URL}/file/avatar/${this.virtualassistant.avatar_id}`
        : '';
    },
  },
  async created() {
    if (NODE_ENV === 'production') {
      return this.$router.push('/').catch(() => {});
    }
    await gmapsInit();
    let address1Field = document.querySelector('#autocomplete');
    let address2Field = document.querySelector('#autocomplete2');
    // Create the autocomplete object, restricting the search predictions to
    // addresses in the US and Canada.
    let google = window.google;
    this.autocomplete = new google.maps.places.Autocomplete(address1Field, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });
    address1Field.focus();

    this.autocomplete2 = new google.maps.places.Autocomplete(address2Field, {
      componentRestrictions: { country: ['us', 'ca'] },
      fields: ['address_components', 'geometry'],
      types: ['address'],
    });
    address2Field.focus();
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.

    this.autocomplete.addListener('place_changed', this.fillInAddress);
    this.autocomplete2.addListener('place_changed', this.fillInAddress);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    showEditLocation() {
      this.editLocation = true;
    },
    hideLocation() {
      this.editLocation = false;
    },
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = this.autocomplete.getPlace();
      let address1 = '';
      let postcode = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        // @ts-ignore remove once typings fixed
        const componentType = component.types[0];
        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }

          case 'route': {
            address1 += component.short_name;
            break;
          }

          case 'postal_code': {
            postcode = `${component.long_name}${postcode}`;
            break;
          }

          case 'postal_code_suffix': {
            postcode = `${postcode}-${component.long_name}`;
            break;
          }
          case 'locality': {
            this.address.city = component.long_name;
            break;
          }
          case 'administrative_area_level_1': {
            this.address.state = component.long_name;
            // document.querySelector('#state').value = component.short_name;
            break;
          }
          case 'country': {
            this.address.country = component.long_name;
            break;
          }
        }
      }
      // address1Field.value = address1;
      this.address.street = address1;
      this.address.postalCode = postcode;
      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      //   address2Field.focus();
    },
    update(newData, type) {
      if (
        JSON.stringify(newData) === JSON.stringify(this.virtualassistant[type])
      ) {
        return;
      }
      this.editStates[type].loading = true;

      if (this.counter % 2 === 0) {
        setTimeout(() => {
          // const payload = {
          //   uuid: this.virtualassistant.uuid,
          //   [type]: newData,
          // };

          this.editStates[type].error = null;
          this.editStates[type].loading = false;
          this.virtualassistant[type] = newData;
        }, 2000);
      } else {
        //error occurred
        setTimeout(() => {
          this.editStates[type].error = 'could not update value';
          this.editStates[type].loading = false;
        }, 2000);
      }
      //temporary to switch between success and failing
      this.counter++;
    },
  },
};
</script>
<style scoped></style>
