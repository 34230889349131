<template>
  <label
    class="roll transition duration-200 ease-in-out"
    :class="[labelClasses]"
    @mouseover="showEditIcon = true"
    @mouseleave="showEditIcon = false"
  >
    <slot name="title"></slot>
    {{ editData ? '' : incomingValue.join(', ') }}
    <v-select
      v-if="editData"
      v-bind="$attrs"
      ref="input"
      v-model="incomingValue"
      :items="items"
      multiple
      :disabled="loading"
      :loading="loading"
      v-on="{ ...$listeners }"
      @click.stop=""
      @blur="hideEditData"
    >
    </v-select>
    <Transition name=" ease-in-out">
      <fa-icon
        v-if="showEditIcon && !editData && !loading"
        class="pl-3"
        icon="fa-regular fa-pencil"
        @click="showEditData"
      />
    </Transition>
    <v-progress-circular
      v-if="loading"
      class="ml-3"
      :size="20"
      :width="3"
      :color="vgTeal"
      indeterminate
    ></v-progress-circular>
    <Transition name=" ease-in-out">
      <fa-icon
        v-if="showCheckIcon"
        class="text-vgmedturq ml-3"
        icon="fa-duotone fa-check text-2xl"
      />
    </Transition>
    <Transition name=" ease-in-out">
      <fa-icon
        v-if="showErrorIcon"
        class="ml-3 text-red-500"
        icon="fa-regular fa-triangle-exclamation text-2xl"
      />
    </Transition>
  </label>
</template>

<script>
import { nextTick } from 'vue';
import tailwind from 'tailwind.config';

export default {
  name: 'VgSelect',
  props: {
    labelClasses: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Array, String],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ['update'],
  data() {
    return {
      editData: false,
      showEditIcon: false,
      incomingValue: [],
      showCheckIcon: false,
      showErrorIcon: false,
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
    };
  },

  watch: {
    loading: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal === true && newVal === false) {
          if (this.error) {
            this.showErrorIcon = true;
          } else {
            this.showCheckIcon = true;

            setTimeout(() => {
              this.showCheckIcon = false;
            }, 1000);
          }
        }
      },
    },
  },

  created() {
    this.incomingValue = JSON.parse(JSON.stringify(this.modelValue));
  },

  methods: {
    showEditData() {
      this.editData = true;
      this.showErrorIcon = false;

      nextTick(() => {
        this.$refs.input.focus();
        this.$refs.input.activateMenu();
      });
    },
    hideEditData() {
      this.editData = false;

      this.$emit('update', this.incomingValue);
    },
  },
};
</script>
<style scoped></style>
