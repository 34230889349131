<template>
  <div class="bg-vgsilver-100 p-0">
    <v-expansion-panels v-if="mobile">
      <v-expansion-panel>
        <v-expansion-panel-title class="py-0">
          <p class="m-0 w-full text-left font-bold">
            {{ $t('portal:office.hours.title') }}
          </p>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <WeeklySchedule :data="assistant" :mobile="mobile" class="px-3" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        v-if="assistant.roles || assistant.skills || assistant.tools"
      >
        <v-expansion-panel-title class="py-0">
          <p class="m-0 w-full text-left font-bold">
            {{ $t('portal:expertise.title') }}
          </p>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <Expertise :assistant="assistant" />
        </v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel v-if="assistant.vg_academy_courses.length">
        <v-expansion-panel-title class="py-0">
          <p class="m-0 w-full text-left font-bold">
            {{ $t('portal:certifications.title') }}
          </p>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <Certifications :assistant="assistant" :mobile="mobile" />
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-else class="rounded-r p-5">
      <Expertise :assistant="assistant" />
      <Certifications :assistant="assistant" :mobile="mobile" />
    </div>
  </div>
</template>

<script>
import Expertise from '@/components/vaPublicProfile/Expertise.vue';
import Certifications from '@/components/vaPublicProfile/Certifications.vue';
import WeeklySchedule from '@/components/vaPublicProfile/WeeklySchedule.vue';
export default {
  name: 'ExperienceWrapper',
  components: {
    Expertise,
    Certifications,
    WeeklySchedule,
  },
  props: {
    assistant: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    mobile: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
};
</script>
<style scoped>
* :deep(.v-expansion-panel .v-expansion-panel__shadow),
* :deep(.v-expansion-panels--variant-default > .v-expansion-panel::after) {
  @apply hidden;
}
* :deep(.v-expansion-panel-title__overlay) {
  @apply shadow-none opacity-0 hidden;
}
* :deep(.v-theme--light.v-expansion-panels .v-expansion-panel) {
  background-color: rgba(0, 0, 0, 0);
}
*
  :deep(.v-expansion-panel--active
    > .v-expansion-panel-title--active
    .v-expansion-panel-title__icon:not(.v-expansion-panel-title__icon--disable-rotate)) {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
*
  :deep(.v-expansion-panel
    > .v-expansion-panel-title
    .v-expansion-panel-title__icon:not(.v-expansion-panel-title__icon--disable-rotate)) {
  @apply transition-transform;
}
</style>
