<template>
  <div>
    <div v-if="checkout" class="flex flex-col">
      <label
        v-if="draftProjectPaymentMethod === 'stripe'"
        class="px-8 pt-8 text-lg font-bold"
      >
        {{
          clientCreditCards.length > 1
            ? $t('portal:creditCards.select.label')
            : clientCreditCards.length === 1
            ? $t('portal:creditCards.submit.label')
            : $t('portal:creditCards.add.label')
        }}
      </label>
      <v-radio-group
        v-if="
          clientCreditCards.length > 1 && draftProjectPaymentMethod === 'stripe'
        "
        v-model="selectedClientCreditCard"
        class="flex flex-col gap-10"
      >
        <v-radio
          v-for="creditCard in clientCreditCards"
          :key="creditCard.uuid"
          class="mr-2 flex w-full border-b px-8 py-6"
          :value="creditCard"
          :color="vgDarkTeal"
          :disabled="
            moment().year() > creditCard.expiry_year ||
            (moment().year() === creditCard.expiry_year &&
              moment().format('M') > creditCard.expiry_month)
          "
          @click="useSelectedCreditCard(creditCard)"
        >
          <template #label>
            <div class="flex flex-col">
              <div class="grid grid-cols-3 justify-items-stretch pl-2">
                <div
                  :class="[
                    'col-span-2  m-0  flex font-normal',
                    selectedClientCreditCard.uuid !== creditCard.uuid
                      ? 'text-vgstone'
                      : 'text-black',
                  ]"
                >
                  <fa-icon
                    class="mt-1"
                    :icon="creditCardIcon(creditCard.brand)"
                  />
                  <p class="m-0 pl-2 text-base">
                    **** **** **** {{ creditCard.last_four }}
                  </p>
                </div>
                <p
                  class="place-self-end text-base"
                  :class="[
                    moment().year() > creditCard.expiry_year ||
                    (moment().year() === creditCard.expiry_year &&
                      moment().format('M') > creditCard.expiry_month)
                      ? 'text-vgorange'
                      : 'text-vgocean',
                  ]"
                >
                  {{
                    moment().year() > creditCard.expiry_year ||
                    (moment().year() === creditCard.expiry_year &&
                      moment().format('M') > creditCard.expiry_month)
                      ? $t('portal:creditCards.expired.label')
                      : $t('portal:creditCards.expires.label')
                  }}
                  {{ creditCard.expiry_month }}/{{ creditCard.expiry_year }}
                </p>

                <div
                  v-if="
                    !checkout &&
                    creditCard &&
                    defaultClientCreditCard &&
                    creditCard.default_credit_card ===
                      defaultClientCreditCard.default_credit_card
                  "
                  class="mb-2 flex items-center justify-center"
                >
                  <fa-icon
                    icon="fa-regular fa-check"
                    :title="$t('portal:default.text')"
                  />
                </div>
              </div>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <div
        v-if="
          clientCreditCards.length === 1 &&
          draftProjectPaymentMethod === 'stripe'
        "
        class="grid grid-cols-3 justify-items-stretch border-b px-8 py-6 pl-2"
      >
        <div
          :class="[
            'col-span-2 m-0 flex px-8 font-normal',
            selectedClientCreditCard.uuid !== clientCreditCards[0].uuid
              ? 'text-vgstone'
              : 'text-black',
          ]"
        >
          <fa-icon
            class="mt-1"
            :icon="creditCardIcon(clientCreditCards[0].brand)"
          />
          <p class="m-0 pl-2 text-base">
            **** **** **** {{ clientCreditCards[0].last_four }}
          </p>
        </div>
        <p
          class="place-self-end text-base"
          :class="[
            moment().year() > clientCreditCards[0].expiry_year ||
            (moment().year() === clientCreditCards[0].expiry_year &&
              moment().format('M') > clientCreditCards[0].expiry_month)
              ? 'text-vgorange'
              : 'text-vgocean',
          ]"
        >
          {{
            moment().year() > clientCreditCards[0].expiry_year ||
            (moment().year() === clientCreditCards[0].expiry_year &&
              moment().format('M') > clientCreditCards[0].expiry_month)
              ? $t('portal:creditCards.expired.label')
              : $t('portal:creditCards.expires.label')
          }}
          {{ clientCreditCards[0].expiry_month }}/{{
            clientCreditCards[0].expiry_year
          }}
        </p>
      </div>
      <slot name="error"></slot>
      <div class="mx-8">
        <v-alert
          v-if="cardError"
          closable
          :close-text="$t('portal:closeAlert.text')"
          type="error"
          icon="mdi-alert"
          class="mt-3 w-full px-8"
        >
          {{ cardError }}
        </v-alert>
      </div>
      <v-fade-transition hide-on-leave>
        <div
          v-show="!showAddCreditCard || draftProjectPaymentMethod !== 'stripe'"
          class="grid grid-cols-2 px-8 py-5"
        >
          <vg-btn
            v-show="!showAddCreditCard"
            v-if="draftProjectPaymentMethod === 'stripe'"
            vg-type="tertiary"
            @click="
              showAddCreditCard = true;
              $nextTick(() => {
                $refs.creditCardNumber.focus();
              });
            "
          >
            {{ $t('portal:creditCards.add.label') }}
          </vg-btn>
          <div
            v-show="
              !showAddCreditCard || draftProjectPaymentMethod !== 'stripe'
            "
            :class="[
              'flex items-center',
              {
                'order-first  col-span-2 ': showPromoInput && !appliedPromoCode,
              },
              {
                'justify-end': draftProjectPaymentMethod === 'stripe',
              },
            ]"
          >
            <slot name="promo"></slot>
          </div>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div
          v-if="draftProjectPaymentMethod === 'stripe' && showAddCreditCard"
          class="flex flex-col p-8 pt-3"
        >
          <h3 v-if="clientCreditCards.length !== 0">
            {{ $t('portal:creditCards.add.label') }}
          </h3>
          <div class="flex justify-between">
            <p class="text-xs text-green-700">
              <fa-icon icon="fa-regular fa-check" class="mr-1" />
              {{ $t('portal:creditCards.secureTransmission.text') }}
            </p>
            <p class="text-xs text-green-700">
              <fa-icon icon="fa-regular fa-check" class="mr-1" />
              {{ $t('portal:creditCards.protectPersonal.text') }}
            </p>
          </div>
          <hr class="my-3" />
          <div>
            <img
              class="relative mr-4 inline h-12 w-6"
              src="/img/icons/cards-cc_master_card.svg"
              lazy-src="/img/profile.png"
              :alt="$t('portal:creditCards.icon.masterCard.text')"
            />
            <img
              class="relative mr-4 inline h-12 w-6"
              src="/img/icons/cards-cc_american_express.svg"
              lazy-src="/img/profile.png"
              :alt="$t('portal:creditCards.icon.amex.text')"
            />
            <img
              class="relative mr-4 inline h-12 w-6"
              src="/img/icons/cards-cc_china_union_pay.svg"
              lazy-src="/img/profile.png"
              :alt="$t('portal:creditCards.icon.cup.text')"
            />
            <img
              class="relative inline h-12 w-10"
              src="/img/icons/cards-cc_visa.svg"
              lazy-src="/img/profile.png"
              :alt="$t('portal:creditCards.icon.visa.text')"
            />
          </div>
          <v-form ref="creditCardForm">
            <label class="text-sm">
              {{ $t('portal:creditCards.number.label') }}
              <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
              <v-text-field
                ref="creditCardNumber"
                v-model.trim="card.number"
                data-cy="checkout.new.input.card-number"
                :rules="requiredRules"
                required
                :color="vgTeal"
                variant="outlined"
                maxlength="19"
              >
                <template #append-inner>
                  <fa-icon :icon="validatedCreditCardIcons" />
                </template>
              </v-text-field>
            </label>
            <div class="flex flex-row gap-4">
              <label class="col-start-1 text-sm">
                {{ $t('portal:creditCards.expirationMonth.label') }}
                <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
                <div class="mr-6 w-[7rem] md:w-[12rem]">
                  <v-select
                    v-model="card.exp_month"
                    data-cy="checkout.new.input.expiration-month"
                    :items="allowedExpiryMonths"
                    :color="vgTeal"
                    variant="outlined"
                  ></v-select>
                </div>
              </label>
              <label class="col-start-1 text-sm">
                {{ $t('portal:creditCards.expirationYear.label') }}
                <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
                <div class="md:w-[10rem]">
                  <v-select
                    v-model="card.exp_year"
                    data-cy="checkout.new.input.expiration-year"
                    :items="allowedExpiryYears"
                    :color="vgTeal"
                    variant="outlined"
                  ></v-select>
                </div>
              </label>
            </div>
            <div class="flex flex-row">
              <label class="col-start-1 text-sm">
                {{ $t('portal:creditCards.csc.label') }}
                <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
                <div class="mr-6 w-[7rem]">
                  <v-text-field
                    v-model="card.cvc"
                    data-cy="checkout.new.input.cvc"
                    :rules="requiredRules"
                    required
                    :color="vgTeal"
                    variant="outlined"
                    maxlength="4"
                  ></v-text-field>
                </div>
              </label>
              <label class="col-start-1 text-sm">
                {{ $t('portal:creditCards.zip.label') }}
                <fa-icon class="text-vgorange" icon="fa-regular fa-asterisk" />
                <div class="flex flex-row">
                  <div class="w-[7rem] md:w-[10rem]">
                    <v-text-field
                      v-model="card.address_zip"
                      data-cy="checkout.new.input.zip"
                      :rules="requiredRules"
                      required
                      :color="vgTeal"
                      variant="outlined"
                      maxlength="10"
                    ></v-text-field>
                  </div>
                </div>
              </label>
            </div>
          </v-form>
          <div class="flex flex-row-reverse justify-between">
            <vg-btn
              data-cy="checkout.new.btn.add-card"
              :loading="creditCardLoading"
              :disabled="shouldDisableAddCard"
              @click="handleSaveClientCreditCard"
            >
              {{ $t('portal:add.button') }}
            </vg-btn>

            <vg-btn
              v-if="clientCreditCards.length !== 0"
              vg-type="tertiary"
              @click="handleCloseClientCreditCard"
            >
              {{ $t('portal:cancel.button') }}
            </vg-btn>
          </div>
        </div>
      </v-fade-transition>
    </div>
    <div v-else class="flex min-h-full flex-col lg:flex-row">
      <div class="max-w-6xl flex-1 p-4">
        <h1 v-if="!clientUuid" class="text-left text-3xl font-semibold">
          {{ $t('portal:creditCards.paymentMethod.heading') }}
        </h1>
        <div class="rounded-md bg-white p-2">
          <p
            v-if="selectedClientCreditCard && !clientUuid"
            class="m-4 text-base font-bold"
          >
            <fa-icon v-if="!clientUuid" icon="fa-light fa-credit-card" />
            {{ $t('portal:creditCards.yourCards.heading') }}
          </p>
          <div
            v-for="creditCard in clientCreditCards"
            :key="creditCard.uuid"
            class="flex flex-col border-b py-8"
          >
            <div
              class="grid grid-cols-6 place-items-center justify-between justify-items-center gap-2"
            >
              <div
                class="text-vgnavy col-span-3 m-0 mr-8 flex font-semibold sm:col-span-2 sm:mr-0"
              >
                <fa-icon
                  class="mt-2"
                  :icon="creditCardIcon(creditCard.brand)"
                />
                <p class="m-0 mt-1 pl-3 text-base">
                  **** **** **** {{ creditCard.last_four }}
                </p>
              </div>

              <div
                class="col-span-3 grid grid-flow-row justify-items-center sm:col-span-1"
              >
                <label class="text-vgstone text-xs font-normal uppercase">{{
                  $t('portal:creditCards.expires.label')
                }}</label>
                <p
                  v-if="
                    moment().year() > creditCard.expiry_year ||
                    (moment().year() === creditCard.expiry_year &&
                      moment().format('M') > creditCard.expiry_month)
                  "
                  class="text-vgorange m-0 text-center text-xs font-normal"
                >
                  <fa-icon
                    icon="fa-light fa-triangle-exclamation"
                    class="pr-2"
                  />{{ $t('portal:creditCards.expiredPleaseAdd.label') }}
                </p>
                <p v-else class="text-vgnavy upercase m-0 self-end text-base">
                  {{ creditCard.expiry_month }}/{{ creditCard.expiry_year }}
                </p>
              </div>
              <div
                v-if="
                  !(
                    moment().year() > creditCard.expiry_year ||
                    (moment().year() === creditCard.expiry_year &&
                      moment().format('M') > creditCard.expiry_month)
                  )
                "
                class="col-span-3 grid grid-flow-row justify-items-center sm:col-span-2"
              >
                <template
                  v-if="
                    creditCard &&
                    defaultClientCreditCard &&
                    creditCard.default_credit_card ===
                      defaultClientCreditCard.default_credit_card
                  "
                >
                  <label class="text-vgstone text-xs font-normal uppercase">{{
                    $t('portal:default.text')
                  }}</label>
                  <fa-icon icon="fa-square-check fa-regular" />
                </template>
                <template v-else>
                  <a
                    v-if="clientUuid && uiStore.mobile"
                    class="border-vgocean-480 text-vgocean border-2 border-solid bg-white text-center text-xs uppercase shadow-none"
                    href=""
                    @click.stop.prevent="
                      handleUpdateDefaultClientCreditCard(creditCard)
                    "
                    ><strong>{{
                      $t('portal:creditCards.makeDefault.button')
                    }}</strong>
                  </a>
                  <v-btn
                    v-else
                    class="border-vgocean-480 text-vgocean border-2 border-solid bg-white font-bold shadow-none"
                    variant="outlined"
                    size="x-small"
                    :color="vgOcean"
                    @click="handleUpdateDefaultClientCreditCard(creditCard)"
                  >
                    {{ $t('portal:creditCards.makeDefault.button') }}
                  </v-btn>
                </template>
              </div>
              <div v-else class="col-span-3 sm:col-span-2"></div>
              <button
                v-if="
                  defaultClientCreditCard &&
                  creditCard.default_credit_card !==
                    defaultClientCreditCard.default_credit_card
                "
                class="col-span-3 sm:col-span-1"
                @click.stop.prevent="handleDeleteClientCreditCard(creditCard)"
              >
                <fa-icon icon="fa-trash-can fa-regular" />
              </button>
            </div>
          </div>
          <div
            v-if="cardError"
            class="my-3 flex items-center justify-between rounded-2xl p-2 px-4"
            style="background-color: #ff7b0020; color: #ff8400"
          >
            <p class="m-0 text-base font-semibold">{{ cardError }}</p>
            <fa-icon icon="fa fa-lg  fa-times-circle" @click="cardError = ''" />
          </div>
          <!-- New Card Form -->
          <div class="flex flex-col p-4">
            <label class="mb-4 text-base font-bold">
              {{ $t('portal:creditCards.newCard.label') }}
            </label>
            <div class="max-w-xl">
              <div class="flex flex-col">
                <div class="flex justify-between">
                  <p class="text-xs text-green-700">
                    <fa-icon icon="fa-regular fa-check" class="mr-1" />
                    {{ $t('portal:creditCards.secureTransmission.text') }}
                  </p>
                  <p class="text-xs text-green-700">
                    <fa-icon icon="fa-regular fa-check" class="mr-1" />
                    {{ $t('portal:creditCards.protectPersonal.text') }}
                  </p>
                </div>
                <hr class="my-3" />
                <div>
                  <img
                    class="relative mr-4 inline h-12 w-6"
                    src="/img/icons/cards-cc_master_card.svg"
                    lazy-src="/img/profile.png"
                    :alt="$t('portal:creditCards.icon.masterCard.text')"
                  />
                  <img
                    class="relative mr-4 inline h-12 w-6"
                    src="/img/icons/cards-cc_american_express.svg"
                    lazy-src="/img/profile.png"
                    :alt="$t('portal:creditCards.icon.amex.text')"
                  />
                  <img
                    class="relative mr-4 inline h-12 w-6"
                    src="/img/icons/cards-cc_china_union_pay.svg"
                    lazy-src="/img/profile.png"
                    :alt="$t('portal:creditCards.icon.cup.text')"
                  />
                  <img
                    class="relative inline h-12 w-10"
                    src="/img/icons/cards-cc_visa.svg"
                    lazy-src="/img/profile.png"
                    :alt="$t('portal:creditCards.icon.visa.text')"
                  />
                </div>
                <v-form ref="creditCardForm">
                  <label class="text-sm">
                    {{ $t('portal:creditCards.number.label') }}
                    <fa-icon
                      class="text-vgorange"
                      icon="fa-regular fa-asterisk"
                    />
                    <v-text-field
                      v-model.trim="card.number"
                      data-cy="checkout.new.input.card-number"
                      :rules="requiredRules"
                      required
                      :color="vgTeal"
                      variant="outlined"
                      maxlength="19"
                    >
                      <template #append-inner>
                        <fa-icon
                          :icon="validatedCreditCardIcons"
                          class="text-vgstone"
                        />
                      </template>
                    </v-text-field>
                  </label>
                  <div class="flex flex-row items-end gap-4">
                    <label class="col-start-1 text-sm">
                      {{ $t('portal:creditCards.expirationMonth.label') }}
                      <fa-icon
                        class="text-vgorange"
                        icon="fa-regular fa-asterisk"
                      />
                      <div class="mr-6 w-[7rem] sm:w-[12rem]">
                        <v-select
                          v-model="card.exp_month"
                          data-cy="checkout.new.input.expiration-month"
                          :items="allowedExpiryMonths"
                          :color="vgTeal"
                          variant="outlined"
                        ></v-select>
                      </div>
                    </label>
                    <label class="col-start-1 text-sm">
                      {{ $t('portal:creditCards.expirationYear.label') }}
                      <fa-icon
                        class="text-vgorange"
                        icon="fa-regular fa-asterisk"
                      />
                      <div class="w-[7rem] sm:w-[10rem]">
                        <v-select
                          v-model="card.exp_year"
                          data-cy="checkout.new.input.expiration-year"
                          :items="allowedExpiryYears"
                          :color="vgTeal"
                          variant="outlined"
                        ></v-select>
                      </div>
                    </label>
                  </div>
                  <div class="flex flex-row gap-4">
                    <label class="col-start-1 text-sm">
                      {{ $t('portal:creditCards.csc.label') }}
                      <fa-icon
                        class="text-vgorange"
                        icon="fa-regular fa-asterisk"
                      />
                      <div class="mr-6 w-[7rem]">
                        <v-text-field
                          v-model="card.cvc"
                          data-cy="checkout.new.input.cvc"
                          :rules="requiredRules"
                          required
                          :color="vgTeal"
                          variant="outlined"
                          maxlength="4"
                        ></v-text-field>
                      </div>
                    </label>
                    <label class="col-start-1 text-sm">
                      {{ $t('portal:creditCards.zip.label') }}
                      <fa-icon
                        class="text-vgorange"
                        icon="fa-regular fa-asterisk"
                      />
                      <div class="flex flex-row">
                        <div class="w-[7rem] sm:w-[10rem]">
                          <v-text-field
                            v-model="card.address_zip"
                            data-cy="checkout.new.input.zip"
                            :rules="requiredRules"
                            required
                            :color="vgTeal"
                            variant="outlined"
                            maxlength="10"
                          ></v-text-field>
                        </div>
                      </div>
                    </label>
                  </div>
                </v-form>
              </div>
              <p
                class="place-self-center text-xs font-bold"
                style="color: #373f41"
              >
                <fa-icon
                  v-if="!isNewCardDefault"
                  icon="fa-square fa-regular"
                  @click="isNewCardDefault = true"
                />
                <fa-icon
                  v-if="isNewCardDefault"
                  icon="fa-square-check fa-regular"
                  @click="isNewCardDefault = false"
                />
                <span
                  class="ml-1 uppercase"
                  @click="isNewCardDefault = !isNewCardDefault"
                >
                  {{ $t('portal:creditCards.makeDefault.button') }}</span
                >
              </p>
              <div class="flex flex-col gap-4">
                <vg-btn
                  data-cy="checkout.new.btn.add-card"
                  :loading="creditCardLoading"
                  :disabled="shouldDisableAddCard"
                  @click="handleSaveClientCreditCard"
                >
                  {{ $t('portal:add.button') }}
                </vg-btn>
                <vg-btn
                  vg-type="tertiary"
                  @click.stop.prevent="clearCreditCard"
                >
                  {{ $t('portal:clear.button') }}
                </vg-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import { STRIPE_API_KEY } from '@/config';
import {
  getClientCreditCards,
  saveClientCreditCard,
  updateDefaultClientCreditCard,
  deleteClientCreditCard,
  checkClientRatePlans,
} from '@/services/clientService';
import { sessionUserHasPermission } from '@/services/clientSessionService';
import {
  createToken,
  lookupCreditCardBIN,
} from '@/services/externalPaymentService';
import { mapStores, storeToRefs } from 'pinia';
import { useConfigStore } from '@/stores/config';
import { useUiStore } from '@/stores/ui';
import { useUserStore } from '@/stores/user';
import tailwind from 'tailwind.config';
import moment from 'moment';

export default {
  name: 'ClientCreditCards',
  props: {
    draftProjectPaymentMethod: {
      type: String,
      default: 'stripe',
    },
    checkout: {
      type: Boolean,
      default: false,
    },
    showPromoInput: {
      type: Boolean,
      default: false,
    },
    appliedPromoCode: {
      type: String,
      default: '',
    },
    clientUuid: {
      type: String,
      default: '',
    },
  },
  emits: ['selectedCreditCard'],
  data() {
    const { user } = storeToRefs(useUserStore());
    const { checkFeatureFlag } = storeToRefs(useConfigStore());

    return {
      user,
      checkFeatureFlag,
      STRIPE_API_KEY,
      cardError: '',
      selectedClientCreditCard: {},
      payment: 'stripe',
      discountedMonthlyPriceCents: null,
      stripeElementHasFocus: false,
      showAddCreditCard: false,
      creditCardLoading: false,
      clientCreditCards: [],
      clientHasRatePlans: 0,
      newClientCreditCard: {},
      cardOptions: {
        style: {
          base: {
            iconColor: tailwind.theme.extend.colors.vgnavy[500],
            color: tailwind.theme.extend.colors.vgnavy[500],
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '17px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: tailwind.theme.extend.colors.vgnavy[400],
            },
            ':focus': {},
            ':hover': {},
          },
        },
      },
      card: {
        number: '',
        exp_month: null,
        exp_year: null,
        cvc: '',
        address_zip: '',
      },
      requiredRules: [(v) => !!v || this.$t('required.error')],
      vgDarkTeal: tailwind.theme.extend.colors.vgteal[800],
      vgOcean: tailwind.theme.extend.colors.vgocean[500],
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
      creditCardIcons: {
        Visa: 'fa-2xl fab fa-cc-visa',
        MasterCard: 'fa-2xl fab fa-brands fa-cc-mastercard',
        default: 'fa-2xl fa fa-credit-card',
        PayPal: 'fa-2xl fa-brands fa-cc-paypal',
        Discover: 'fa-2xl fa-brands fa-cc-discover',
        'American Express': 'fa-2xl fa-brands fa-cc-amex',
        'Diners Club': 'fa-2xl fa-brands fa-cc-diners-club',
      },
      isNewCardDefault: false,
    };
  },
  computed: {
    ...mapStores(useUiStore),
    creditCardIcon() {
      return (type) => {
        return this.creditCardIcons[type] || this.creditCardIcons.default;
      };
    },
    validatedCreditCardIcons() {
      switch (this.card.number[0]) {
        case '4':
          return 'text-2xl fab fa-cc-visa';
        case '5':
          return 'text-2xl fab fa-brands fa-cc-mastercard';
        case '6':
          return 'text-2xl fab fa-cc-discover';
        default:
          return 'text-2xl fa fa-credit-card';
      }
    },
    complete() {
      return !!(
        this.replaceSpacesInString(this.card.number)?.length > 14 &&
        this.card.exp_month &&
        this.card.exp_year &&
        this.replaceSpacesInString(this.card.cvc).length >= 3 &&
        this.replaceSpacesInString(this.card.address_zip)
      );
    },
    shouldDisableAddCard() {
      return (
        (this.draftProjectPaymentMethod === 'stripe' && !this.complete) ||
        (!sessionUserHasPermission('client') &&
          !sessionUserHasPermission('admin_finance.edit'))
      );
    },
    existingClientCreditCards() {
      return this.clientCreditCards?.length > 0;
    },
    defaultClientCreditCard() {
      return this.clientCreditCards?.find(
        (creditCard) => creditCard.default_credit_card
      );
    },
    allowedExpiryMonths() {
      return [
        { title: this.$t('portal:creditCards.months.01.title'), value: 1 },
        { title: this.$t('portal:creditCards.months.02.title'), value: 2 },
        { title: this.$t('portal:creditCards.months.03.title'), value: 3 },
        { title: this.$t('portal:creditCards.months.04.title'), value: 4 },
        { title: this.$t('portal:creditCards.months.05.title'), value: 5 },
        { title: this.$t('portal:creditCards.months.06.title'), value: 6 },
        { title: this.$t('portal:creditCards.months.07.title'), value: 7 },
        { title: this.$t('portal:creditCards.months.08.title'), value: 8 },
        { title: this.$t('portal:creditCards.months.09.title'), value: 9 },
        { title: this.$t('portal:creditCards.months.10.title'), value: 10 },
        { title: this.$t('portal:creditCards.months.11.title'), value: 11 },
        { title: this.$t('portal:creditCards.months.12.title'), value: 12 },
      ];
    },
    allowedExpiryYears() {
      return Array.from(
        { length: 20 },
        (_, year) => new Date().getFullYear() + year
      );
    },
  },
  created() {
    this.init();
    this.cardError = '';
  },
  methods: {
    moment,
    sessionUserHasPermission,
    async init() {
      try {
        const creditCardsResult = await getClientCreditCards(
          this.clientUuid ? this.clientUuid : this.user?.client_uuid,
          'talentplace'
        );
        const clientRatePlansResult = await checkClientRatePlans(
          this.clientUuid ? this.clientUuid : this.user?.client_uuid,
          'talentplace'
        );

        this.clientCreditCards = creditCardsResult;
        this.clientHasRatePlans = clientRatePlansResult;
      } catch {
        this.cardError = this.$t('generic.error');
      }

      if (this.draftProjectPaymentMethod === 'stripe') {
        if (this.clientCreditCards.length) {
          this.selectedClientCreditCard =
            this.defaultClientCreditCard || this.clientCreditCards[0];
          this.$emit('selectedCreditCard', this.selectedClientCreditCard);
        } else {
          this.showAddCreditCard = true;
        }
      }
    },
    validateCreditCard() {
      return createToken({ card: this.card }).then((response) => {
        this.newClientCreditCard = {
          token: response.token.id,
          stripe_card_id: response.token.card.id,
          brand: response.token.card.brand,
          last_four: response.token.card.last4,
          expiry_month: response.token.card.exp_month,
          expiry_year: response.token.card.exp_year,
          zip_code: response.token.card.address_zip,
        };
      });
    },
    async handleSaveClientCreditCard() {
      this.creditCardLoading = true;
      this.cardError = '';

      try {
        await this.validateCreditCard();

        if (
          this.checkFeatureFlag('bin-lookup') &&
          !this.clientHasRatePlans &&
          this.card.number.startsWith('5')
        ) {
          this.newClientCreditCard.extra = {
            ...this.newClientCreditCard.extra,
            ...(await lookupCreditCardBIN({
              card: this.replaceSpacesInString(this.card.number).slice(0, 11),
            }).catch(() => {
              return { bin: [] };
            })),
          };
        }

        const creditCardResponse = await saveClientCreditCard(
          this.clientUuid ? this.clientUuid : this.user?.client_uuid,
          this.newClientCreditCard
        );

        await this.init();

        this.selectedClientCreditCard = this.clientCreditCards.find(
          (creditCard) => creditCard.uuid === creditCardResponse.card.uuid
        );
        this.$emit('selectedCreditCard', this.selectedClientCreditCard);
        this.newClientCreditCard = {};
        if (this.isNewCardDefault) {
          await this.handleUpdateDefaultClientCreditCard(
            this.selectedClientCreditCard
          );
        }

        this.handleCloseClientCreditCard();
        this.clearCreditCard();
      } catch (error) {
        this.cardError =
          error?.data?.message ?? this.$t('portal:creditCards.save.error');
      } finally {
        this.creditCardLoading = false;
      }
    },
    async handleUpdateDefaultClientCreditCard(newCreditCard) {
      this.creditCardLoading = true;

      try {
        await updateDefaultClientCreditCard(
          this.clientUuid ? this.clientUuid : this.user?.client_uuid,
          {
            uuid: newCreditCard.uuid,
            source: 'talentplace',
          }
        );

        this.clientCreditCards.forEach((creditCard) => {
          if (creditCard.uuid === newCreditCard.uuid) {
            creditCard.default_credit_card = newCreditCard.uuid;
            this.$root.$snackbar.message(
              this.$t('portal:creditCards.defaultUpdate.success')
            );
          } else {
            creditCard.default_credit_card = null;
          }
        });
      } catch (error) {
        this.cardError =
          error?.data?.message ??
          this.$t('portal:creditCards.defaultUpdate.error');
      } finally {
        this.creditCardLoading = false;
      }
    },
    async handleDeleteClientCreditCard(creditCard) {
      if (this.defaultClientCreditCard.uuid === creditCard.uuid) {
        this.cardError = this.$t('portal:creditCards.defaultDelete.error');

        return;
      }

      const dialog = await this.$root.$confirm.open(
        this.$t('portal:creditCards.confirmDelete.heading'),
        this.$t('portal:creditCards.confirmDelete.text', {
          requiredEntry: `${creditCard.expiry_month}/${creditCard.expiry_year}`,
        }),
        {
          okText: this.$t('portal:delete.button'),
          cancelText: this.$t('portal:cancel.button'),
          requiredEntry: `${creditCard.expiry_month}/${creditCard.expiry_year}`,
        }
      );

      if (dialog) {
        try {
          await deleteClientCreditCard(
            this.clientUuid ? this.clientUuid : this.user?.client_uuid,
            creditCard.uuid
          );
          this.clientCreditCards = this.clientCreditCards.filter(
            (card) => card.uuid !== creditCard.uuid
          );
          this.selectedClientCreditCard = this.defaultClientCreditCard;
        } catch (error) {
          this.cardError =
            error?.data?.message ?? this.$t('portal:creditCards.delete.error');
        }
      }
    },
    handleCloseClientCreditCard() {
      this.showAddCreditCard = false;
    },
    useSelectedCreditCard(creditCard) {
      this.selectedCreditCard = creditCard;
      this.$emit('selectedCreditCard', creditCard);
    },
    clearCreditCard() {
      this.card = {
        number: '',
        exp_month: null,
        exp_year: null,
        cvc: '',
        address_zip: '',
      };
      this.cardError = '';
      nextTick(() => {
        this.$refs.creditCardForm?.resetValidation();
      });
    },
    replaceSpacesInString(string) {
      return string.replace(/\s+/g, '');
    },
  },
};
</script>
