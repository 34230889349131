<template>
  <div class="flex w-full flex-col">
    <div class="flex w-full flex-row items-center">
      <slot name="title">
        <span v-if="label" class="whitespace-nowrap font-bold">
          {{ label }}
        </span>
      </slot>
      <label
        :class="`${style} ${showSaveIcon || editData ? '' : ' bg-vgstone-50'}`"
        @click="showEditData"
      >
        {{ labelText }}
        <input
          v-if="editData"
          id="input"
          ref="input"
          v-model="incomingValue"
          type="text"
          :placeholder="placeholder"
          :disabled="disabled"
          class="focus:ring-vgteal w-full rounded-sm px-2 outline-none focus:outline-none focus:ring-2"
          :class="{ 'hover:cursor-pointer': !disabled }"
          @input="handleInput"
          @keydown.enter.prevent="handleEnterKey"
          @blur="handleBlur"
          @keydown.esc="resetState"
        />
        <Transition name="ease-in-out">
          <fa-icon
            v-if="showSaveIcon && !loading && inline"
            class="text-vgmedturq pointer-events-auto absolute right-5 cursor-pointer pl-3 text-sm"
            icon="fa-regular fa-arrow-turn-down-left"
            :aria-label="$t('portal:vg.textInput.hide.label')"
            @click="hideEditData"
          />
        </Transition>
        <div class="flex items-center justify-end">
          <Transition name="ease-in-out">
            <fa-icon
              v-if="!editData && !loading && !disabled && inline"
              icon="fa-light fa-pencil"
              :aria-label="
                label
                  ? $t('portal:vg.textInput.edit.label', { label: label })
                  : $t('portal:vg.textInput.editText.label')
              "
              @click="showEditData"
            />
          </Transition>
          <v-progress-circular
            v-if="loading"
            class="ml-3"
            :size="20"
            :width="3"
            :color="vgTeal"
            indeterminate
            :aria-label="$t('portal:save.inprogress.label')"
          ></v-progress-circular>
        </div>
      </label>
      <Transition name="ease-in-out">
        <fa-icon
          v-if="showCheckIcon && showIcons"
          class="text-vgmedturq ml-3"
          icon="fa-duotone fa-check text-2xl"
          :aria-label="$t('portal:vg.textInput.save.success')"
        />
      </Transition>
      <Transition name="ease-in-out">
        <fa-icon
          v-if="showErrorIcon && showIcons"
          class="ml-3 text-red-500"
          icon="fa-regular fa-triangle-exclamation text-2xl"
          :aria-label="$t('portal:vg.textInput.save.error')"
        />
      </Transition>
      <Transition name="ease-in-out">
        <fa-icon
          v-if="showSaveIcon && inline"
          class="text-vgstone ml-1 cursor-pointer"
          icon="fa-solid fa-xmark text-lg"
          :aria-label="$t('portal:vg.textInput.edit.cancel.label')"
          @mousedown="resetState"
        />
      </Transition>
    </div>
    <span role="alert" class="mt-2 text-sm text-red-500">
      <p v-if="isInputEmpty">{{ $t('required.error') }}</p>
      <p v-else-if="showIcons && error">
        {{ error }}
      </p></span
    >
  </div>
</template>

<script>
import { nextTick } from 'vue';
import tailwind from 'tailwind.config';

export default {
  name: 'VgTextField',
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
    error: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    showIcons: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['edit', 'update'],
  data() {
    return {
      editData: false,
      incomingValue: '',
      requiredRules: [(v) => !!v || this.$t('required.error')],
      showCheckIcon: false,
      showErrorIcon: false,
      showSaveIcon: false,
      enterKeyPressed: false,
      vgTeal: tailwind.theme.extend.colors.vgteal[500],
      style:
        'relative flex w-full items-center justify-between px-2 py-1 transition duration-200 ease-in-out rounded-lg flex-inline',
    };
  },
  computed: {
    isInputEmpty() {
      return this.incomingValue.toString().trim().length === 0 && this.required;
    },
    labelText() {
      return this.editData
        ? ''
        : this.incomingValue.length === 0
        ? this.placeholder
        : this.incomingValue;
    },
  },
  watch: {
    error: {
      handler(newVal) {
        if (newVal?.length > 0) {
          !this.showIcons
            ? this.$root.$snackbar.error(this.error)
            : (this.showErrorIcon = true);
        }
      },
    },
    loading: {
      immediate: true,
      handler(newVal, oldVal) {
        if (oldVal === true && newVal === false) {
          if (this.error === null && this.showIcons) {
            this.showCheckIcon = true;

            setTimeout(() => {
              this.showCheckIcon = false;
            }, 1000);
          }
        }
      },
    },
    modelValue: {
      immediate: true,
      handler(newVal) {
        this.incomingValue = JSON.parse(JSON.stringify(newVal));
      },
    },
  },
  methods: {
    handleInput() {
      if (typeof this.modelValue === 'number') {
        const numRegExp = /^\d+$/;
        if (!numRegExp.test(this.incomingValue)) {
          this.incomingValue = this.incomingValue.replace(/\D/g, '');
        }
      }
    },
    showEditData() {
      if (this.disabled) return;

      this.editData = true;
      this.showErrorIcon = false;

      nextTick(() => {
        this.$refs.input.focus();
        this.showSaveIcon = true;
      });
      this.$emit('edit');
    },
    hideEditData() {
      if (this.isInputEmpty) {
        this.resetState();
        return;
      }
      this.editData = false;
      this.showSaveIcon = false;
      this.$emit(
        'update',
        typeof this.modelValue === 'number'
          ? Number(this.incomingValue)
          : this.incomingValue
      );
    },
    handleEnterKey() {
      if (this.disabled) return;

      this.enterKeyPressed = true;
      this.hideEditData();
    },
    handleBlur() {
      if (!this.enterKeyPressed) {
        this.hideEditData();
      }
      this.enterKeyPressed = false;
    },
    resetState() {
      this.enterKeyPressed = false;
      this.editData = false;
      this.showSaveIcon = false;
      this.incomingValue = this.modelValue;
    },
  },
};
</script>
<style scoped></style>
