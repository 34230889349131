import { calculateDurationHMS } from '@/utils/timer';
import importedMoment from 'moment';
import 'moment/dist/locale/fr';

const HOURS_DECIMAL_ACCURACY = 4;

export const moment = importedMoment;

export const formatDaysAgo = (dateString) => {
  const now = moment(new Date());
  const date = moment(dateString);

  return Math.round(moment.duration(now.diff(date)).asDays());
};

export const formatDateTimeFromNow = (dateString) => {
  if (!dateString) return '';
  return moment(dateString).fromNow();
};

export const formatDateTime = (dateString) => {
  if (!dateString) return '';

  return moment(dateString).format(
    moment.locale() === 'fr'
      ? '[le] Do MMM YYYY HH:mm:ss'
      : 'MMM Do YYYY h:mm:ss A'
  );
};

export const formatDate = (dateString) => {
  if (!dateString) return '';

  if (dateString?.endsWith?.('Z')) {
    dateString = dateString.substring(0, dateString.length - 1);
  }

  return moment(dateString).format('ll');
};

export const formatDateLong = (dateString) => {
  if (!dateString) return '';

  return moment(dateString).format('LLLL');
};

export const formatShortDate = (dateString, preserveTimezone) => {
  if (!dateString) return '';

  if (!preserveTimezone && dateString?.endsWith?.('Z')) {
    dateString = dateString.substring(0, dateString.length - 1);
  }

  return moment(dateString).format('YYYY-MM-DD');
};

export const formatShortDateTime = (dateString) => {
  if (!dateString) return '';

  return moment(dateString).format(
    moment.locale() === 'fr' ? 'DD/MM/YYYY - HH:mm' : 'DD/MM/YYYY - h:mm A'
  );
};

export const formatCurrency = (amount, currency) => {
  if (isNaN(amount)) {
    return '';
  }

  if (currency) {
    return Intl.NumberFormat(moment.locale() === 'fr' ? 'fr-CA' : 'en-CA', {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
    }).format(amount);
  }

  return `${moment.locale() === 'fr' ? '' : '$'}${Intl.NumberFormat(
    moment.locale() === 'fr' ? 'fr-CA' : 'en-CA',
    {
      style: 'decimal',
      minimumFractionDigits: 2,
    }
  ).format(amount)}${moment.locale() === 'fr' ? ' $' : ''}`;
};

/**
 * Format an integer value, e.g. 1000 => 1,000
 *
 * @param {any} amount The value to be formatted, must castable as a number
 *
 * @returns {string} The formatted value
 */
export const formatIntegerValue = (amount) => {
  if (isNaN(amount)) {
    return;
  }

  return Intl.NumberFormat(moment.locale() === 'fr' ? 'fr-CA' : 'en-CA', {
    style: 'decimal',
    minimumFractionDigits: 0,
  }).format(amount);
};

export const formatSafeUpperCase = (text) => {
  return text && typeof text === 'string' ? text.toUpperCase() : text;
};

export const capitalizeFirstLetter = (string) => {
  return string && typeof string === 'string'
    ? string.charAt(0).toUpperCase() + string.slice(1)
    : string;
};

export const addSuffixToDay = (day, language) => {
  if (language === 'fr') {
    if (day === 1) {
      return 'er';
    }
    return 'e';
  } else {
    if (day === 1 || day === 21 || day === 31) {
      return 'st';
    } else if (day === 2 || day === 22) {
      return 'nd';
    } else if (day === 3 || day === 23) {
      return 'rd';
    }
    return 'th';
  }
};

export const formatHoursDecimal = (hoursDecimal) => {
  if (!hoursDecimal) return 0;
  return parseFloat(hoursDecimal).toFixed(HOURS_DECIMAL_ACCURACY);
};

export const formatDuration = (hoursDecimal) => {
  if (isNaN(parseFloat(hoursDecimal))) return '0h 0m 0s (0.0h)';
  const duration = moment.duration(hoursDecimal, 'hours');
  const roundFunction = hoursDecimal > 0 ? Math.floor : Math.ceil;
  return `${roundFunction(
    duration.asHours()
  )}h ${duration.minutes()}m ${duration.seconds()}s (${formatHoursDecimal(
    duration.asHours()
  )}h)`;
};

export const formatDurationShort = (hoursDecimal) => {
  if (isNaN(parseFloat(hoursDecimal))) return '0h 0m';
  const duration = moment.duration(hoursDecimal, 'hours');
  const roundFunction = hoursDecimal > 0 ? Math.floor : Math.ceil;
  return `${roundFunction(duration.asHours())}h ${duration.minutes()}m`;
};

export const formatTimerEventsToHMS = (timerEvents) => {
  const hms = calculateDurationHMS([timerEvents].flat());
  return `${hms.h}h ${hms.m}m ${hms.s}s`;
};

export const formatDurationToHMS = (hoursDecimal) => {
  const duration = moment.duration(hoursDecimal, 'hours');
  const roundFunction = hoursDecimal > 0 ? Math.floor : Math.ceil;
  return `${roundFunction(
    duration.asHours()
  )}h ${duration.minutes()}m ${duration.seconds()}s`;
};

export const formatTimestampTo12 = (timestamp) => {
  return moment.utc(timestamp).local().format('LT');
};

export const formatHoursMinutes = (dateString) => {
  return moment(dateString).format('HH:mm');
};

export const formatTimestamp = (dateString) => {
  let now = moment(new Date());
  let ds = moment(dateString);
  let days = moment.duration(now.diff(ds)).asDays();

  if (days >= 1) {
    return moment(dateString).format('h:mm A, MMM Do');
  }

  return moment(dateString).format('h:mm A');
};

/**
 * Convert a date to a localized string
 *
 * @param {Date} date
 * @param {Object} options
 *
 * @returns {string}
 */
export const toLocaleString = (date, options) => {
  return date.toLocaleString(moment.locale(), options);
};

/**
 * Convert a date to a localized time string
 *
 * @param {Date} date
 * @param {Object} options
 *
 * @returns {string}
 */
export const toLocaleTimeString = (date, options) => {
  return date.toLocaleTimeString(moment.locale(), options);
};

export const truncateText = (text, size = 128) => {
  return text?.length > size ? `${text.substring(0, size)}...` : text;
};
