import { defineStore } from 'pinia';
import { getSocket } from '@/services/clientSessionService';
import {
  calculateTimerDuration,
  calculateTotalDuration,
  calculateDurationHMS,
} from '../utils/timer';
import { formatShortDate } from '@/services/formattingService';
import moment from 'moment';

let timerUpdateDebounce;
const TIMER_UPDATE_DEBOUNCE_MS = 1000;
const VA_ACTIVE_TIMER_CLOCK_TICK_MS = 1000;
const tab_id = Math.random().toString(36).slice(-8);

export const useTimersStore = defineStore('timers', {
  state: () => ({
    timers: {},
    activeTimerClock: null,
  }),
  getters: {
    allTimers() {
      return this.timers;
    },
    activeTimer() {
      return Object.entries(this.timers)
        .map(([id, timer]) => ({
          id,
          ...timer,
        }))
        .find((timer) => timer.state === 'STARTED');
    },
    inactiveTimers() {
      return Object.entries(this.timers)
        .map(([id, timer]) => ({
          id,
          ...timer,
        }))
        .filter((timer) => timer.state !== 'STARTED');
    },
  },
  actions: {
    initTimers() {
      // If the closure isn't already attached to the socket as a listener, set it up.
      // This ensures only one listener is attached here at any time.
      if (
        !getSocket()
          .listeners('talentplace.timer_update')
          ?.some((f) => f === this.vaDashboardTimerUpdateListener)
      ) {
        getSocket().on(
          'talentplace.timer_update',
          this.vaDashboardTimerUpdateListener
        );
      }
      // Once the listener is attached & the socket connected, request a timer update
      getSocket().on('authed', () => {
        getSocket().emit('talentplace.timer_update');
      });
    },
    timerStart(timer_id) {
      // Check for another timer running
      const running_id = Object.keys(this.timers).find(
        (k) => this.timers[k].state === 'STARTED'
      );

      if (running_id) {
        const running = this.timers[running_id];
        // Update the last timer with the stop time
        const active = running.timers[running.timers.length - 1];
        active.stop = {
          time: moment().format('HH:mm:ss'),
          timestamp: moment().millisecond(0),
        };
        active.duration = calculateTimerDuration(active);

        this.updateTimers({
          timer_id: running_id,
          debounce: false,
          values: { timers: running.timers, state: 'PAUSED' },
        });
      }

      const paused = this.timers[timer_id];

      // Add a new timer
      paused.timers.push({
        start: {
          time: moment().format('HH:mm:ss'),
          timestamp: moment().millisecond(0),
        },
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timezone_offset: moment().utcOffset(),
      });

      this.updateTimers({
        timer_id,
        debounce: false,
        values: {
          timers: paused.timers,
          state: 'STARTED',
        },
      });
    },
    timerPause(timer_id) {
      const running = this.timers[timer_id];

      // Update the last timer with the stop time
      const active = running.timers[running.timers.length - 1];
      active.stop = {
        time: moment().format('HH:mm:ss'),
        timestamp: moment().millisecond(0),
      };
      active.duration = calculateTimerDuration(active);

      // Calculate an overall total duration
      running.total_duration =
        running.timers?.reduce(
          (total_duration, start_stop) => total_duration + start_stop.duration,
          0
        ) || 0;

      this.updateTimers({
        timer_id,
        debounce: false,
        values: {
          ...running,
          state: 'PAUSED',
        },
      });

      clearInterval(this.activeTimerClock);
    },
    updateTimers(payload) {
      // Have to change local state so that the current window/tab can pick up changes
      if (payload.values) {
        // If new values are provided, it's an update
        this.timers = {
          ...this.timers,
          ...{
            [payload.timer_id]: Object.assign(
              {},
              this.timers[payload.timer_id] || {},
              {
                ...payload.values,
              }
            ),
          },
        };
      } else {
        // If there are no new values, delete the project timer
        this.timers = Object.keys(this.timers).reduce((timers, key) => {
          if (key !== payload.timer_id) timers[key] = this.timers[key];
          return timers;
        }, {});
      }

      // Clear any outstanding debounce timer
      clearTimeout(timerUpdateDebounce);
      if (payload.debounce) {
        // If the description has changed, debounce an update to avoid sending updates server side on every keypress
        timerUpdateDebounce = setTimeout(() => {
          getSocket().emit('talentplace.timer_update', {
            ...this.timers,
            tab_id,
          });
        }, TIMER_UPDATE_DEBOUNCE_MS);
      } else {
        // Otherwise the server update is sent immediately
        getSocket().emit('talentplace.timer_update', {
          ...this.timers,
          tab_id,
        });
      }
    },
    vaDashboardTimerUpdateListener(update) {
      // In case there is no timer data server-side
      update = update || {};

      // Check for timer date overruns and halt any timers that have
      const todaysDate = formatShortDate(new Date());
      Object.values(update).forEach((timer) => {
        if (timer.state !== 'HALTED' && todaysDate > timer.task_date) {
          if (timer.timers.length) {
            // Stop last time at 11:59:59 on task date
            const lastTime = timer.timers[timer.timers.length - 1];
            const midnight = moment(`${timer.task_date}`)
              .local()
              .endOf('day')
              .millisecond(0);
            if (
              !lastTime.stop ||
              (lastTime.stop &&
                moment(lastTime.stop.timestamp).isAfter(midnight))
            ) {
              lastTime.stop = {
                time: '23:59:59',
                timestamp: midnight,
              };
            }
            lastTime.duration = calculateTimerDuration(lastTime);
            lastTime.note = 'Timer stopped automatically due to date overrun';
            // Don't allow the timer to be restarted
            timer.state = 'HALTED';
          } else {
            timer.task_date = todaysDate;
          }
          delete update.tab_id;
          timer.total_duration = calculateTotalDuration(timer.timers);
        }
      });

      // Update our state
      // Note that we do this directly and NOT visa ther updateTimers function, which would cause an
      // infinite feedback loop with the server backend.
      if (!update.tab_id || update.tab_id !== tab_id) {
        delete update.tab_id;
        this.timers = JSON.parse(JSON.stringify(update));
      }

      // Start a clock for the active timer, if there is one
      clearInterval(this.activeTimerClock);
      const [timer_id, timer] = Array.from(Object.entries(update)).find(
        ([, timer]) => timer.state === 'STARTED'
      ) || [null, null];
      if (timer) {
        // Calculate an initial total_duration
        this.timers[timer_id].total_duration = calculateTotalDuration(
          timer.timers
        );
        // ... and recalculate it every clock tick
        this.activeTimerClock = setInterval(() => {
          this.timers[timer_id] = {
            ...this.timers[timer_id],
            running_hms: calculateDurationHMS(this.timers[timer_id].timers),
            total_duration: calculateTotalDuration(
              this.timers[timer_id].timers
            ),
          };
        }, VA_ACTIVE_TIMER_CLOCK_TICK_MS);
      }
    },
  },
});
