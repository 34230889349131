<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :z-index="options.zIndex"
    @keydown.esc="cancel"
  >
    <v-form @submit.prevent="ok">
      <v-card>
        <v-card-title
          :max-width="options.width"
          class="hyphens-none whitespace-pre-wrap"
          ><h3>
            {{ title }}
          </h3></v-card-title
        >
        <v-card-text
          v-show="!!message"
          v-dompurify-html="message"
          class="pa-4"
        ></v-card-text>
        <div v-show="!!options.requiredEntry" class="px-4 pb-0 pt-4">
          <v-text-field
            v-model="entry"
            variant="outlined"
            :color="vgTeal"
          ></v-text-field>
        </div>
        <div class="flex justify-end p-4">
          <vg-btn vg-type="secondary" class="mr-4" @click="cancel">{{
            options.cancelText
          }}</vg-btn>
          <vg-btn :disabled="entry !== options.requiredEntry" type="submit">{{
            options.okText
          }}</vg-btn>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import tailwind from 'tailwind.config';

const defaultOptions = {
  color: 'red',
  width: 500,
  zIndex: 3000,
  requiredEntry: null,
  showButtons: true,
  okText: 'OK',
  cancelText: 'Cancel',
};

export default {
  name: 'ConfirmDialog',
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    title: null,
    message: null,
    entry: null,
    options: defaultOptions,
    vgTeal: tailwind.theme.extend.colors.vgteal[500],
  }),
  methods: {
    open(title, message, options) {
      this.title = title;
      this.message = message;
      this.entry = null;
      this.options = Object.assign({ ...defaultOptions }, options);
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    ok() {
      if (this.entry !== this.options.requiredEntry) {
        return;
      }
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
